import React, { useContext, useEffect, useState } from 'react'
import { GetDataTrems } from '../../api/actions';
import Privacypolicydata from '../../components/buyer/privacypolicy/Privacypolicydata';
import { Authcontext } from '../../store/context';
import Loading from '../loading/loading';

function Trems() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataTrems(setData, setLoading)
  }, [loading]);


  return (
    <>
      {loading === false ? 
      <Loading/>
      :
    <section className='privacypolicy'>
      <div className="container">
        <Privacypolicydata
          Title={language === "ar" ? "الأحكام والشروط" : "Terms And Conditions"}
          Data={data}
        />
      </div>

    </section>
}
</>
  )
}

export default Trems;