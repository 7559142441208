import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { Authcontext } from '../../../../store/context';

function MyOrdersNavlist() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div className='myorders__navlist'>
            <NavLink to={`/currentrequests/${1}`} className={"btn"}>
              {language === "ar" ? "الطلبات الحالية" : "Current Requests"}
              </NavLink>
            <NavLink to={`/completedrequests/${1}`} className="btn">
              {language === "ar" ? "الطلبات المكتملة" : "Completed Requests"}
              </NavLink>
            <NavLink to={`/canceledrequests/${1}`}  className="btn">
              {language === "ar" ? "الطلبات الملغاة" : "Canceled Requests"}
            </NavLink>
    </div>
  )
}

export default MyOrdersNavlist;