import React, { useEffect, useState } from 'react'
import ProfileListSeller from '../../../layout/profile/list/seller';
import NavListProfile from '../../../components/buyer/profile/navlist';
import { NavLink } from 'react-router-dom';
import Loading from '../../../layout/loading/loading';
import { GetDataProfile } from '../../../api/actions.js';
import EditeStoreDataForm from '../../../components/seller/editestoredata/editestoredataform';

function EditeStoreData() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stutes, setStutes] = useState(2);

    
    useEffect(() => {
        GetDataProfile(setData, setLoading)
    }, [loading]);

    return (
        <>
            {loading === false ?
                <Loading />
                :
                <section className='profile'>
                    <div className="container">
                        <div className="profile__data">
                            <>
                                <ProfileListSeller Id={data.id} />
                                <div className='profile__data__rowcoumn'>
                                    <NavListProfile stutes={stutes} setStutes={setStutes} />
                                    <EditeStoreDataForm stutes={stutes} setStutes={setStutes} 
                                    Data={data}/>

                                </div>
                            </>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}
export default EditeStoreData;