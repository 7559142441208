import React, { useContext } from 'react'
import CardProudect from '../../../shared/cardproudect/index.jsx';
import ProudectsEmpty from '../empty/index.jsx';


function ProudectsAll(props) {
    const { Products } = props;
    return (<>
        {Products.length === 0 ?
            <ProudectsEmpty />
            :
            <div className="row">
                {Products.map(item =>
                    <div className="col-md-6 col-lg-4" key={item.id}>
                        <CardProudect Image={item.media.length === 0 ? 
                        "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg": item.media[0].image}
                        Price={`SR ${item.price}`} Data={item.title} Favoritecard={false} Id={item.id} Vailable={item.in_stock}
                        Item={item} />
                    </div>
                )}
            </div>
        }
    </>
    )
}

export default ProudectsAll;