import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';

function NotFound() {
  let location = useLocation(),
    textlocation = location.pathname,
    pageerror = textlocation.includes("pageerror");

  return (
    <main className='notfound'>
      <section className="notfound full-height">
        <img src="/assets/icons/icon-notfound.png" alt="Iconnotfound" />
        {pageerror === true ? <h6>500</h6> : <h6>404 Error</h6>}
        {pageerror === true ? <p>Internal Server Error</p> : <p>Sorry , page not found</p>}
        <NavLink to={"/"}>Back To Home </NavLink>
      </section>
    </main>
  )
}

export default NotFound;