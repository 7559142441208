import React from 'react'
import { CChart } from '@coreui/react-chartjs'
import { Chart } from "react-google-charts";

function ChartData(props) {
  const { Labels, Newarray } = props;
 const data = [
    ["Year", ""],
    [Labels[0], Newarray[1]],
    [Labels[1], Newarray[2]],
    [Labels[2], Newarray[3]],
    [Labels[3], Newarray[4]],
    [Labels[4], Newarray[5]],
    [Labels[5], Newarray[6]],
    [Labels[6], Newarray[7]],
    [Labels[7], Newarray[8]],
    [Labels[8], Newarray[9]],
    [Labels[9], Newarray[10]],
    [Labels[10], Newarray[11]],
    [Labels[11], Newarray[12]],
  ];

  const options = {
    curveType: "function",
    chartArea: { width: "85%", height: "60%" },
  };
  return (
    <>
    <Chart
      chartType="AreaChart"
      width="95%"
      height="300px"
      data={data}
      options={options}
    />
    </>
  )
}

export default ChartData


/**
 

import React from 'react'
import { CChart } from '@coreui/react-chartjs'

function Chart(props) {
    const {Labels ,Newarray}=props;
  return (
    <>
    
    <CChart
        type="line"
        data={{
          labels: Labels,
          datasets: [
            {
              backgroundColor: "rgba(151, 187, 205, 0.2)",
              borderColor: "rgba(151, 187, 205, 25)",
              pointBackgroundColor: "rgba(151, 187, 205, 1)",
              pointBorderColor: "#fff",
              data: [Newarray]
            },
          ],
        }}
      />
    </>
  )
}

export default Chart
 */