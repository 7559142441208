import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { GetDataProfile } from '../../../../api/actions';

function IconProfile() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        GetDataProfile(setData, setLoading)
    }, [loading]);
    return (<>
        {loading === false ?
            ""
            :

            <NavLink to={"/profile"} className="icon_profile">
                <img src={data.image} alt={data.name}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                            "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg";
                    }} />
            </NavLink>
        }
    </>
    )
}

export default IconProfile;