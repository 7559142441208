import React, { useContext, useState } from 'react'
import { InputAccountNumber, InputBranchName, InputNameBanck, InputNameUserBanck, InputSwiftcode } from './inputes';
import { Authcontext } from '../../../../store/context';

function FormFour(props) {
    const { handleChange, State, setStutesform ,Title} = props;

    const [validation, setvalidation] = useState("hide");
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const handleNextform = (e) => {
        e.preventDefault();

        if (State.namebanck === "" ||
            State.nameuserbanck === "" ||
            State.branchname === "" ||
            State.accountnumber === "" ||
            State.swiftcode === "") {
            setvalidation(false);
        } else {
            setvalidation(true);
            setStutesform(5)
        }

    }



    const handleBackform = (e) => {
        e.preventDefault();
        setStutesform(3)
    }

    return (
        <>
            <div className="title">
                <h6>{Title}</h6>
            </div>
            <InputNameUserBanck validation={validation} value={State.nameuserbanck} handleChange={handleChange} />
            <InputNameBanck validation={validation} value={State.namebanck} handleChange={handleChange} />
            <InputBranchName validation={validation} value={State.branchname} handleChange={handleChange} />
            <InputAccountNumber validation={validation} value={State.accountnumber} handleChange={handleChange} />
            <InputSwiftcode validation={validation} value={State.swiftcode} handleChange={handleChange} />



            <div className="form-end">

            <button type='button' className='btn btn-back' onClick={handleBackform}>
                    {language === "en" ? "Back" : "الرجوع"}
                </button>
                
                <button type='button' className='btn btn-next' onClick={handleNextform}>
                    {language === "en" ? "Next" : "التالي"}
                </button>

            </div>
        </>
    )
}

export default FormFour;