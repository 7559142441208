import React, { useContext, useState } from 'react'
import { Authcontext } from '../../../store/context';
import { useNavigate, useParams } from 'react-router';

function Categories(props) {
  const { getCategories, setLoading } = props;

  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  let { filtersort, filtercategory, filtersubcategory, filterminvalue, filtermaxvalue } = useParams(),
      navigate = useNavigate();

  const minValue = +filterminvalue.slice(filterminvalue.indexOf(":") + 1, filterminvalue.length);
  const maxValue = +filtermaxvalue.slice(filtermaxvalue.indexOf(":") + 1, filtermaxvalue.length);
  const categoryid = +filtercategory.slice(filtercategory.indexOf(":") + 1, filtercategory.length);
  const subcategoryid = +filtersubcategory.slice(filtersubcategory.indexOf(":") + 1, filtersubcategory.length);
  const sortdata = filtersort.slice(filtersort.indexOf(":") + 1, filtersort.length);




  const handleChange = (valueone, valuetwo) => {
    navigate(`/products/page:${1}/filtersort:${sortdata}/filtercategory:${valueone}/filtersubcategory:${valuetwo}/minValue:${minValue}/maxValue:${maxValue}`);
    setLoading(false)
  };

  const handleClick = (valueone, valuetwo) => {
    navigate(`/products/page:${1}/filtersort:${sortdata}/filtercategory:${valueone}/filtersubcategory:${valuetwo}/minValue:${minValue}/maxValue:${maxValue}`);
    setLoading(false)
  };


  return (
    <div className='categories'>

      <h2 className='fillter_title'>{language === "ar" ? "الفئات" : "Categories"}</h2>

      <div className="accordion accordion-flush" id="accordionFlushExample">

        {getCategories.map(item =>
          <div className="accordion-item" key={item.id}>
            <h2 className="accordion-header" id="flush-headingTwo">
              <button className={item.children.length === 0 ?
                "accordion-button accordion-button-noafter" :
                item.id === categoryid ? 'accordion-button' : `accordion-button collapsed`} type="button"
                data-bs-toggle="collapse" data-bs-target={`#tage${item.id}`} onClick={() => handleClick(item.id, item.id)}
                aria-expanded={item.id === categoryid ? "true" : "false"} aria-controls={item.name}>


                <input type="radio" value={item.name} name="filter_category" id={item.id}
                  checked={item.id === categoryid} onChange={() => handleChange(item.id, item.id)} />

                <label htmlFor={item.name} className={item.id === categoryid ? "active" : ""}>{item.name}</label>


              </button>
            </h2>
            {item.children.map(elment =>
              <div id={`tage${item.id}`}
                className={item.id === categoryid ? 'accordion-collapse collapse show' : "accordion-collapse collapse"}
                aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample" key={elment.id}>
                <div className="accordion-body">
                  <ul>
                    <li>
                      <input type="radio" value={elment.name} name="filter_sub_category" id={elment.id}
                        onChange={() => handleChange(item.id, elment.id)} checked={elment.id === subcategoryid} />

                      <label htmlFor={elment.name} className={elment.id === subcategoryid ? "active" : ""} >{elment.name}</label>
                    </li>
                  </ul>
                </div>
              </div>
            )
            }
          </div>)}


      </div>
    </div>
  )
}

export default Categories;