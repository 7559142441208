import React from 'react';
import Slider from 'react-slick';

function ListImage(props) {
    const { Images, setindex } = props;
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        vertical: true,
        verticalSwiping: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
            {
                breakpoint: 350,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    vertical: false,
                    verticalSwiping: false,
                }
            },
        ]
    };

    return (
        <ul className='listimage'>
            <Slider {...settings}>
                {Images.map((item, index) =>
                    <li key={index}>
                        <button className='btn' type='button' onClick={() => setindex(index)}>
                            <img src={item.image} alt={`${index}`} />
                        </button>
                    </li>
                )}
            </Slider>

        </ul>
    )
}

export default ListImage;