import React from 'react';

function Titlepage(props) {
    const {Title}=props;
  return (
    <h6 className='titlepage'>
        <span className="text">{Title}</span>
        <span className='line'></span>
    </h6>
  )
}

export default Titlepage;