import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { Authcontext } from '../../../store/context';
import Iconcart from '../../../images/icon/icon_shopping-color.png';
import Iconhart from '../../../images/icon/icon_heart.png';
import IconhartFavorite from '../../../images/icon/icon_heart_color_favorit.png';
import IconFavourit from '../../../images/icon/icon_favorite-nn.png';
import Icondelete from '../../../images/icon/icon-delete.png';
import { RemoveToFavourite, AddToFavourite, GetDataHomeBuyer } from '../../../api/actions';
import swal from 'sweetalert';
import { TokenLate } from '../../../api';
import { useState } from 'react';

function CardProudect(props) {
    const { Image, Data, Price, Favoritecard, Id, Vailable, Item, setData } = props;
    const authcontext = useContext(Authcontext);
    const wishlist = authcontext.wishlist;
    const setWishlist = authcontext.setWishlist;
    const language = authcontext.language;
    const [loading, setLoading] = useState(false);
    const [loadingfavourite, setLoadingfavourite] = useState(false);

    const RemoveItem = () => {
        RemoveToFavourite(Id)
        {
            language === "en" ?
                swal({ text: "The product has been removed from your favourites", icon: "warning", buttons: false, timer: 3000 })
                :
                swal({ text: "تمت إزالة المنتج من المفضلة", icon: "warning", buttons: false, timer: 3000 })
        }
    }
    const AddItemFavourite = () => {
        setWishlist([...wishlist, Item])
        AddToFavourite(Id)
        GetDataHomeBuyer(setData, setLoading)
        setLoadingfavourite(!loadingfavourite);
        if (Item.is_favorite === true) {
            {
                language === "en" ?
                    swal({
                        text: "The product has been removed from your favourites",
                        icon: IconFavourit,
                        buttons: false,
                        timer: 3000
                    })
                    :
                    swal({
                        text: "تمت إزاله المنتج من المفضله",
                        icon: IconFavourit,
                        buttons: false,
                        timer: 3000
                    })
            }
        } else {

            {
                language === "en" ?
                    swal({
                        text: "The product has been added to favourites !",
                        icon: IconFavourit,
                        buttons: false,
                        timer: 3000
                    })
                    :
                    swal({
                        text: "تمت إضافة المنتج للمفضله",
                        icon: IconFavourit,
                        buttons: false,
                        timer: 3000
                    })
            }
        }

    }


    return (
        <div className='cardproudect'>
            {Vailable === false ?

                <div className="cover-card">
                    <img src={Image} alt="itemone" className='cover' />
                    <div className="overload">
                        <span>{language === "ar" ? "غير متوفر" : "Unavailable"}</span>
                    </div>
                    {Favoritecard === true ?
                        <button className='btn btn-remove' type='button' onClick={RemoveItem}>
                            <img src={Icondelete} alt="" />
                        </button>
                        : <></>}
                </div>

                :

                <div className="cover-card">
                    {Favoritecard === true ?
                        <button className='btn btn-remove' type='button' onClick={RemoveItem}>
                            <img src={Icondelete} alt="" />
                        </button>
                        : <></>}
                    <NavLink to={`/proudect/${Id}`}>
                        <img src={Image} alt="itemone" className='cover' />
                    </NavLink>
                </div>


            }


            <NavLink to={`/proudect/${Id}`} className='title'>

                {Data.length < 50 ? Data :
                    <>
                        {Data.slice(0, 50)} {"..."}
                    </>
                }
            </NavLink>
            <span className='price'>{Price}</span>


            <ul>
                <li className='btn-cart'>
                    <NavLink to={`/proudect/${Id}`}
                        className={'btn'}>
                        {language === "ar" ? "أضف إلى السلة" : "Add To Cart"}
                        <img src={Iconcart} alt="itemone" />
                    </NavLink>
                </li>
                <li className='btn-heart'>
                    {TokenLate === null ?


                        <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#modailnotlogin">
                            <img src={Iconhart} alt="itemone" />
                        </button>
                        :
                        <button
                            className={'btn'}
                            type='button' onClick={AddItemFavourite}>
                            {Item.is_favorite === true ?
                                <img src={IconhartFavorite} alt="itemone" />
                                : loadingfavourite === true ?
                                    <img src={IconhartFavorite} alt="itemone" />
                                    : loadingfavourite === false ?
                                        <img src={Iconhart} alt="itemone" />
                                        :
                                        <img src={Iconhart} alt="itemone" />
                            }
                        </button>
                    }
                </li>
            </ul>
        </div>
    )
}

export default CardProudect;
