import React from 'react'
import FooterBottom from '../../components/footer/footerbottom';
import FooterTop from '../../components/footer/footertop';

function Footer() {
  return (
    <footer className='footer'>
        
      <div className="container">
        <FooterTop/>
        <FooterBottom/>
      </div>
    </footer>
  )
}

export default Footer;