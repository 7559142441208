import React, { useContext, useState } from 'react'
import { InputFile, InputRegistrationNumber } from './inputes';
import { Authcontext } from '../../../../store/context';

function FormFive(props) {
    const { handleChange, State, setStutesform, setFilethree, filethree, handleSubmit, message, setLoading, 
        Title ,DataFilethree ,loadingbutton} = props;

    const [validation, setvalidation] = useState("hide");
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;


    const handleNextform = (e) => {
        e.preventDefault();
            setvalidation(true);
            handleSubmit();
            setLoading(false);
    }

    const handleBackform = (e) => {
        e.preventDefault();
        setStutesform(4)
    }

    return (
        <>
            <div className="title">
                <h6>{Title}</h6>
            </div>
            <InputRegistrationNumber validation={validation} value={State.registrationnumber} handleChange={handleChange} />
            <InputFile language={language} setFile={setFilethree} file={filethree} validation={validation}
                Title={language === "ar" ? "تحميل بطاقة الهوية" : "Download the identity card"} DataFile={DataFilethree} />
            <br />
            {message === "" ? "" : <span className="errorfiled">{message}</span>}
            <br />
            <br />
            <div className="form-end">



                <button type='button' className='btn btn-back' onClick={handleBackform}>
                    {language === "en" ? "Back" : "الرجوع"}
                </button>

                {loadingbutton === false ?
                    <button type='button' className='btn btn-next' onClick={handleNextform}>
                        {language === "en" ? "Modification" : "التعديل"}
                    </button>
                    :
                    <button type='button' className='btn btn-next button-disabled' >
                        {language === "en" ? "Loading" : "جار التحميل"}
                        <span className="spinner"></span>
                    </button>}
            </div>
        </>
    )
}

export default FormFive;