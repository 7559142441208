import React from 'react'
import MyOrdersDetailsData from '../data';
import MyOrdersDetailsTop from '../top';


function MyOrdersDetailsContant(props) {
  const {Data}=props;
    return (
      <div className='myordersdetails__contant'>
        <MyOrdersDetailsTop Status={Data.status_en}/>
        <MyOrdersDetailsData Data={Data}/>
      </div>
    )
  }
  
  export default MyOrdersDetailsContant;