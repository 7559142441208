import React, { useContext } from 'react'
import IconMore from '../../../../images/icon/icon_more.png';
import { NavLink } from 'react-router-dom';
import { Authcontext } from '../../../../store/context';

function ShipingeDetiles(props) {
  const { DeliveryNumber, Item } = props;


  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (
    <div className='shipingedetiles'>
      <div className="top">
        <div className="right">
          <span className="title">
            {language === "ar" ? "رقم الشحنة" : "Delivery Number"}
          </span>
          <span className="data">{DeliveryNumber}</span>
        </div>

        <div className="left">
          <NavLink to={`/myordersdetails/${Item.id}`} className="btn btn-back">
            <img src={IconMore} alt="IconMore" />
          </NavLink>
        </div>

      </div>

      <div className="bottom">
        <ul className={Item.status_en === "Canceled" ? 'bulltes canceled' : 'bulltes'}>
          <li className='line active'>
            <span className="circal left"></span>
            <span className='data left'>{language === "ar" ? "تم الطلب" : "Request Is Done"}</span>
            <span className="lineprogrise">  </span>

            <span className="circal right"></span>
            {Item.status_en === "Canceled" ?
            <span className='data right'>{Item.status}</span>
             : 
            <span className='data right'>{language === "ar" ? "قيد التنفيذ" : "Underway"}</span>
             }
          </li>

          <li className={Item.status_en === `Shipped` ? 'line active' : Item.status_en === `Delivered` ? 'line active' : 'line'}>

            <span className="lineprogrise"> </span>

            <span className="circal right"></span>

            <span className='data right'>{language === "ar" ? "تم الشحن" : "Been Shipped"}</span>
          </li>
          <li className={Item.status_en === `Delivered` ? 'line active' : 'line'}>

            <span className="lineprogrise"> </span>

            <span className="circal right"></span>

            <span className='data right'>{language === "ar" ? "تم التسليم" : "Product Delivered"}</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ShipingeDetiles;