import React from 'react'
import { useContext } from 'react';
import { Authcontext } from '../../../../../../store/context';
import ModalHeader from '../../outer/modalheader';
import FormForgetPasswordOne from './form/form';

function ModalForgetPasswordOne(props) {
  const { setModalOpen} = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div
      className="modal fade modal-late"
      id="modalforgetpasswordone"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <ModalHeader Title={language === "en" ? "Forgot your password" : "نسيت كلمة المرور"}
          Subtitle={""}
            Id={""} ButtonBack={"singinModal"} ButtonClose={"modalforgetpasswordone"} Modailstart={false}
           />

           <FormForgetPasswordOne setModalOpen={setModalOpen}/>
        </div>
      </div>
    </div>
  )
}

export default ModalForgetPasswordOne;