import React, { useContext } from 'react';
import CardProudect from '../../../shared/cardproudect/index';
import { Authcontext } from '../../../../store/context';

function SimilarProducts(props) {
    const { Data } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    const Related_products = [...Data.related_products];

    return (
        <div className='proudect__similar'>
            <div className="row">


                {Related_products.map(item =>
                    <div className="col-6 col-lg-4 col-xl-3" key={item.id}>
                        <CardProudect 
                        Image={item.media.length === 0 ? "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg"
                        :item.media[0].image} 
                        Price={`SR ${item.price}`}
                            Data={item.title} Favoritecard={false} Id={item.id} Vailable={item.in_stock}  Item={item}/>
                    </div>
                )}


            </div>
        </div>
    )
}

export default SimilarProducts;