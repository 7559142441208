import React from 'react';
import Imagelogo from '../../../../../images/logo/logo.png';
import { Authcontext } from '../../../../../store/context';
import { useContext } from 'react';

function SinginHeader() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (
    <div className='sellersingin__header'>
      <div className="image">
        <img src={Imagelogo} alt="Imagelogo" />
      </div>
      <h3>{language === "ar" ? "تسجيل الدخول" : "Sign In"}</h3>

    </div>
  )
}

export default SinginHeader;