import React, { useContext } from 'react';
import ImageEmpty from '../../../../images/icon/icon_address_empty.png';
import { Authcontext } from '../../../../store/context';

function MyaddressesEmpty() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div className='myaddresses__empty'>
        <img src={ImageEmpty} alt="ImageEmpty" />
        <p>
          {language === "ar" ? "لم تقم بإضافة أي عنوان حتى الآن" : "You haven't added any address yet"}
        </p>
    </div>
  )
}

export default MyaddressesEmpty