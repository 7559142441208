import React, { useEffect, useState } from "react";
import ProudectsAll from "../../../components/buyer/proudects/allproudect/index.jsx";
import ProudectsFilter from "../../../components/shared/filter/index.jsx";
import ProudectsTop from "../../../components/buyer/proudects/proudectstop/index.jsx";
import Paginate from "../../../components/shared/paginate/index.jsx";
import { useNavigate, useParams } from "react-router";
import { GetProductsAll } from "../../../api/actions.js";
import Loading from "../../../layout/loading/loading.jsx";
import { TokenLate, UserIdlate } from "../../../api/index.js";
import ButtonFillter from "../../../components/shared/buttonfillter/index.jsx";
import ProudectsEmpty from "../../../components/buyer/proudects/empty/index.jsx";

function Proudects() {
  const { page, filtersort ,filtercategory,filtersubcategory,filterminvalue , filtermaxvalue} = useParams();


  let navigate = useNavigate();
  const pageCount =  +page.slice(page.indexOf(":") + 1, page.length);
  const [perpage, setPerpage] = useState(0);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const minValue = +filterminvalue.slice(filterminvalue.indexOf(":") + 1, filterminvalue.length);
  const maxValue =  +filtermaxvalue.slice(filtermaxvalue.indexOf(":") + 1, filtermaxvalue.length);
  const categoryid = +filtercategory.slice(filtercategory.indexOf(":") + 1, filtercategory.length);
  const subcategoryid = +filtersubcategory.slice(filtersubcategory.indexOf(":") + 1, filtersubcategory.length);
  const sortdata = filtersort.slice(filtersort.indexOf(":") + 1, filtersort.length);


  const [togglefilter, setTogglefilter] = useState("hidefillter");

  useEffect(() => {
    if (UserIdlate === 3 && TokenLate !== null) {
      window.location.pathname = `/`;
    }
  }, []);

  useEffect(() => {
    GetProductsAll(pageCount, minValue, maxValue, categoryid,subcategoryid, setPerpage, setProducts, sortdata, setLoading)
  }, [loading]);

  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    navigate(`/products/page:${number}/sortorder:${sortdata}/filtercategory:${categoryid}/filtersubcategory:${subcategoryid}/minValue:${minValue}/maxValue:${maxValue}`);
    setLoading(false)
  };

  const ToggleFilterclass = () => {
    if (togglefilter === "hidefillter") {
      setTogglefilter("showfillter")
    } else {
      setTogglefilter("hidefillter")
    }
  }

  return (
    <>
      {loading === false ? (
        <Loading />
      ) : (
        <section className="proudects">

            <div className="container">
              <ProudectsTop setLoading={setLoading}/>

              <ButtonFillter ToggleFilterclass={ToggleFilterclass} />

              <div className="proudects__all">
                <div className="row">
                  <div className="col-12 col-lg-9">
                    <ProudectsAll Products={products} />
                  </div>
                  <ProudectsFilter togglefilter={togglefilter} setTogglefilter={setTogglefilter} setLoading={setLoading} />
                </div>

                <>
                {products.length === 0 ?"":
                <div className="row">
                  <div className="col-9">
                    <Paginate perpage={perpage} handlePageClick={handlePageClick} pagenumber={pageCount} />
                  </div>
                  <div className="col-3"></div>
                </div>
                }
                </>
              </div>
            </div>
        </section>
      )}
    </>
  );
}

export default Proudects;
