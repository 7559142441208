import React from 'react'
import { Authcontext } from '../../../../store/context';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

function NavListRequest() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    return (
        <div className='requestsseller__navlist'>

            <NavLink to={`/currentrequestsseller/${1}`} className="navblist-item">
                {language === "ar" ? "الطلبات الحالية" : "Current Requests"}
            </NavLink>

            <NavLink to={`/previousrequestsseller/${1}`} className="navblist-item">
                {language === "ar" ? "الطلبات السابقة" : "Previous Requests"}
            </NavLink>

        </div>
    )
}

export default NavListRequest;