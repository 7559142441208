import React, { useContext } from 'react'
import HomeChart from './chart';
import HomeRowCards from './rowcards';
import { Authcontext } from '../../../../store/context';

function HomeSellerSlider(props) {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const { Data } = props;
  const labelsen = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    labelsar = ["يناير", "فبراير", "مارس", "أبريل	", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"];

  return (
    <div className='homeseller__slider'>
      <div className="row">
        <div className="col-lg-7 col-md-12">
          <HomeChart Datachart={Data.profit_chart}
            Labels={language === "ar" ? labelsar : labelsen} Year={"2023"} 
            Titleone={language === "ar" ? "الأرباح" : "Profits"}
            Titletwo={language === "ar" ? "أكثر من 100 ريال زيادة يومية" : "More than 100 riyals daily increase"} />
        </div>
        <div className="col-lg-5 col-md-12">
          <HomeRowCards Data={Data} />
        </div>
      </div>
    </div>
  )
}

export default HomeSellerSlider;