import React, { useContext } from 'react'
import { useNavigate } from 'react-router';
import { Formik } from 'formik';
import * as Yup from "yup";
import { Authcontext } from '../../../../../store/context';
import { InputText, InputPassword, InputPhonecountry, InputEmail } from './input';


function Form_One(props) {
    const { setStutesform, setStateArrayone, StateArrayone } = props;

    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    let navigate = useNavigate();

    const state = {
        name: StateArrayone.name === undefined ? "" : StateArrayone.name,
        email: StateArrayone.email === undefined ? "" : StateArrayone.email,
        phone: StateArrayone.phone === undefined ? "" : StateArrayone.phone,
        password: StateArrayone.password === undefined ? "" : StateArrayone.password,
        confirmpassword: StateArrayone.confirmpassword === undefined ? "" : StateArrayone.confirmpassword,
    };

    const onSubmit = (values) => {
        setStateArrayone(values)
        setStutesform(2)
    }

    const OpenSingIn = () => {
        document.getElementById("singupModal_close").click();
    }
    const Openvisitor = () => {
        navigate(`/`);
    }

    const form = (props) => {
        return <form onSubmit={props.handleSubmit}>
            {/*=== Input Name User  ===*/}
            <InputText Error={props.errors.name} valueInput={props.values.name} Type={"text"}
                Label={language === "en" ? "Name" : "الاسم"} Name={"name"} />
            {/*=== Input Mobile Number User  ===*/}
            <InputPhonecountry Error={props.errors.phone} valueInput={props.values.phone} Type={"tel"}
                Label={language === "en" ? "Mobile Number" : "رقم الجوال"} Name={"phone"} />
            {/*=== Input Email  ===*/}
            <InputEmail Error={props.errors.email} valueInput={props.values.email}
                Label={language === "en" ? "E-mail" : "البريد الإلكتروني"} Name={"email"} />
            {/*=== Input Password  ===*/}

            <InputPassword Error={props.errors.password} valueInput={props.values.password} Type={"password"}
                Label={language === "en" ? "Password" : "كلمة المرور"} Name={"password"} />
                
            <InputPassword Error={props.errors.confirmpassword} valueInput={props.values.password} Type={"password"}
                Label={language === "en" ? "Confirm Password" : "تأكيد كلمة المرور"} Name={"confirmpassword"} />

            <div className='end'>
                <div className='first'>
                    {language === "en" ? "Do You Have An Account" : "تملك حساب 2 ؟"}
                    {" "}
                    <button className="btn btnlogin"
                        data-bs-toggle="modal"
                        href="#singinModal"
                        role="button" onClick={OpenSingIn}
                    >{language === "en" ? "Sign In" : "تسجيل الدخول "}</button>
                </div>

                <div className="sacend">
                    <button type='button' className={"btn open_sellersingin"}
                        onClick={Openvisitor}>
                        {language === "en" ? "View as a visitor" : "تصفح كزائِر"}
                    </button>
                </div>
            </div>

            <div className="form-end">

                <button type="submit" className='btn btn-next'>
                    {language === "en" ? "Next" : "التالي"}
                </button>
            </div>
        </form>
    }


    const schema = () => {
        const schema = Yup.object().shape({
            name: Yup.string().required("Name Is Required"),
            email: Yup.string().required("Email Is Required"),
            phone: Yup.string()
                .min(9, 'The Contact Number must be at least 9 Digits !')
                .max(13, 'Contact Number Must Be No More Than 9 !')
                .required("Contact Number Is Required"),
            password: Yup.string().required('Password is required').min(5, 'Your password is too short.').matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
            confirmpassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match')

        });
        return schema;
    }

    return (
        <div className='sellersingup__form'>
            <Formik
                initialValues={state}
                onSubmit={onSubmit}
                render={form}
                validationSchema={schema()}
                validateOnChange={false}
                enableReinitialize={true}
                validateOnBlur={false}
            />


        </div>
    )
}



export default Form_One;