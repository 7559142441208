import React from 'react'
import RatingsTop from './RatingsTop';
import RatingsBottom from './RatingsBottom';
import Paginate from '../../shared/paginate';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

function RatingsContent(props) {
  const { Data, perpage, setpageCount, pagenumber,Id } = props;

  let navigate = useNavigate();


  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    setpageCount(number)
    navigate(`/ratings/${Id}/${number}`)
  };

  return (
    <div className='ratingscontent'>
      <RatingsTop Data={Data} />

      <RatingsBottom Data={Data} />

      <div className="row">
        <div className="col-9">
          <Paginate perpage={perpage} handlePageClick={handlePageClick} pagenumber={pagenumber} />
        </div>
        <div className="col-3">
        </div>
      </div>
    </div>
  )
}

export default RatingsContent;