import React, { useState } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import { InputReenterPassword, InputName, InputPassword, InputEmail } from '../../outer/inputs.jsx';
import { useContext } from 'react';
import axios from 'axios';
import { Authcontext } from '../../../../../../store/context.js';
import { Api, languagelate } from "../../../../../../api/index.js";
import { InputPhonecountry } from '../../outer/allinput.jsx';

function FormSingup(props) {
  const { setModalOpen } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [message, setMessage] = useState("");
  const [loadingbutton, setLoadingbutton] = useState(false);

  const state = {
    name: "",
    email: "",
    phone: "",
    password: "",
    password_confirmation:""
  };
  const Actionmodail = () => {
    setModalOpen(true)
    setMessage("")
    document.getElementById("singupModal_close").click();
    document.getElementById('verificationcode').classList.add('show')
    document.getElementById(`verificationcode`).style.display = "block";
  }
  const Signup = async (values, device_id, device_type, user_type, setMessage) => {

    sessionStorage.setItem("emaillate", JSON.stringify(values.phone));

    var data = new FormData();
    data.append('name', values.name);
    data.append('user_type', user_type);
    data.append('phone', values.phone);
    data.append('email', values.email);
    data.append('device_id', device_id);
    data.append('device_type', device_type);
    data.append('password', values.password);
    data.append('password_confirmation', values.password);

    const options = {
      method: "POST",
      url: `${Api}register`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        'Access-Control-Allow-Origin': '*',
        "Accept-Language": `${languagelate === null ? 'ar' : languagelate}`,
      },
      data: data
    };
    axios(options)
      .then(function (response) {
        setLoadingbutton(false)
        sessionStorage.setItem("verfiy_token", JSON.stringify(response.data.data.verfiy_token));
        Actionmodail();
      })
      .catch(function (error) {
        setLoadingbutton(false)
        setMessage(error.response.data.message)
      });
  };

  const onSubmit = (values) => {
    setLoadingbutton(true)
    Signup(values, "granted", "web", 2, setMessage);
  }
  const OpenSingIn = () => {
    document.getElementById("singupModal_close").click();
  }

  const OpenSellersingin = () => {
    window.location.pathname = `/sellersingup`;
  }

  const form = (props) => {
    return <form onSubmit={props.handleSubmit}>
      <InputName errors={props.errors} values={props.values} />

      {/*=== Input Mobile Number User  ===*/}
      <InputPhonecountry Error={props.errors.phone} valueInput={props.values.phone} Type={"tel"}
        Label={language === "en" ? "Mobile Number" : "رقم الجوال"} Name={"phone"} />


      <InputEmail errors={props.errors} values={props.values} />
      <InputPassword errors={props.errors} />
      <InputReenterPassword errors={props.errors.password_confirmation}/>
      <span className="errorfiled">{message}</span>

      {loadingbutton === false ?
        <button type="submit"
          className={"btn button-login button-active"} >
          {language === "en" ? "Create An Account" : "إنشاء الحساب"}
        </button>
        :
        <button className="btn button-login button-disabled" type="button">
          <span className="text">
            {language === "en" ? "Loading" : "جار التحميل"}
          </span>
          <span className="spinner"></span>
        </button>
      }

    </form>
  }


  const schema = () => {
    const schema = Yup.object().shape({

      name: Yup.string().required("Name Is Required"),
      email: Yup.string().required("Email Is Required"),
      phone: Yup.string()
        .min(9, 'The Contact Number must be at least 9 Digits !')
        .max(13, 'Contact Number Must Be No More Than 9 !')
        .required("Contact Number Is Required"),

      password: Yup.string()
        .min(6, 'Password Must Not Be Less Than 6 Characters')
        .max(10, 'Password Must Not Be More Than 10 Characters')
        .required('Password Is Required'),
        
      password_confirmation: Yup.string()
         .oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),

    });
    return schema;
  }

  return (
    <div className="modal-body">
      <Formik
        initialValues={state}
        onSubmit={onSubmit}
        render={form}
        validationSchema={schema()}
        validateOnChange={false}
        enableReinitialize={true}
        validateOnBlur={false}
      />



      <div className='end'>
        <div className='first'>
          {language === "en" ? "Do You Have An Account" : "تملك حساب ؟"}
          {" "}

          <button className="btn btnlogin" data-bs-toggle="modal" href="#singinModal"
            role="button" onClick={OpenSingIn}>
            {language === "en" ? "Sign In" : "تسجيل الدخول "}
          </button>


        </div>


        <div className="sacend">
          <button type='button' className={"btn open_sellersingin"} onClick={OpenSellersingin}>
            {language === "en" ? "Join as a merchant" : "الإنضمام كتاجر"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default FormSingup;
