import React, { useContext } from 'react'
import NavList from '../../../shared/navlist/index.jsx'
import SortIcon from '../../../shared/sort/index.jsx'

function ProudectsTop(props) {
  const {setLoading} = props;

  return (
    <div className='proudects__top'>
      <SortIcon setLoading={setLoading}/>
      <NavList Titleproudect={""} Id={""} />
    </div>
  )
}

export default ProudectsTop