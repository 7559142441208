import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import HomeCategories from '../../../components/buyer/home/categories.jsx'
import LatestProducts from '../../../components/buyer/home/latestproducts.jsx'
import HomeOvers from '../../../components/buyer/home/overs.jsx'
import { GetDataHomeBuyer } from '../../../api/actions.js'
import Loading from '../../../layout/loading/loading.jsx'
import HomeSlider from '../../../components/buyer/home/slider.jsx'

function HomeBuyer() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataHomeBuyer(setData, setLoading)
  }, [loading]);


  return (
    <>
      {loading === false ?
        <Loading />
        :
        <section className='homebuyer'>
          <div className="container">

            <HomeSlider Banners={data.banners} />
            <HomeCategories Categories={data.categories} />
            <LatestProducts LatestProducts={data.latest_products} setData={setData}/>
            <HomeOvers />
          </div>
        </section>
      }
    </>
  )
}

export default HomeBuyer;