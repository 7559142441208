import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import ProfileForm from '../../../components/buyer/profile/form'
import Loading from '../../../layout/loading/loading';
import { GetDataProfile } from '../../../api/actions';
import ProfileListbuyer from '../../../layout/profile/list/buyer';
import { TokenLate, UserIdlate } from '../../../api';
import NavListProfile from '../../../components/buyer/profile/navlist';
import ProfileListSeller from '../../../layout/profile/list/seller';

function EditeProfile() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stutes, setStutes] = useState(1);

  useEffect(() => {
    GetDataProfile(setData, setLoading)
  }, [loading]);

  return (
    <>
      {loading === false ?
        <Loading />
        :
        <section className='profile'>
          <div className="container">
            <div className="profile__data">

              {UserIdlate === 3 && TokenLate !== null ?
                <>
                  <ProfileListSeller Id={data.id} />
                  <div className='profile__data__rowcoumn'>
                    <NavListProfile stutes={stutes} setStutes={setStutes} />
                    <br/>
                    <br/>
                    <ProfileForm Data={data} />
                  </div>
                </>
                :
                <>
                  <ProfileListbuyer Id={data.id} />
                  <ProfileForm Data={data} />
                </>
              }



            </div>
          </div>
        </section>
      }
    </>
  )
}

export default EditeProfile