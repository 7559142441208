import { ErrorMessage, Field } from 'formik';
import React, { useContext } from 'react';
import Invisible from "../../../../../images/icon/invisible.svg";
import Visible from "../../../../../images/icon/eye-regular.svg";
import { useState } from 'react';
import { Authcontext } from '../../../../../store/context';


export function InputName(props) {
  const { errors, values } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (
    <div className={errors.name ? "input_form position-relative" : "input_form"}>
      <label>{language === "en" ? "The Name" : "الاسم"}</label>
      <Field type="text" component="input"
        className={props.errors.name ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "The Name" : "الاسم"}
        valie={values.name}
        name="name" />
      <ErrorMessage name="name" component="div" className='errorfiled' />
    </div>
  )
};
export function InputPhone(props) {
  const { errors, values } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (
    <div className={errors.phone ? "input_form position-relative" : "input_form"}>
      <label>{language === "en" ? "Mobile Number" : "رقم الجوال"}</label>
      <Field type="number" component="input"
        className={errors.phone ? "form-control is-invalid" : "form-control"}
        placeholder={"545561870"}
        valie={values.phone}
        name="phone" />
      <ErrorMessage name="phone" component="div" className='errorfiled' />
    </div>
  )
};
export function InputEmail(props) {
  const { errors, values } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (

    <div className={"input_form"}>
      <label className="form-label">
        {language === "en" ? "E-mail" : "البريد الإلكتروني"}</label>
      <Field type="email"
        className={errors.email ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "E-mail" : "البريد الإلكتروني"} name="email"
        valie={values.email} />
      <ErrorMessage name="email" component="div" className='errorfiled' />
    </div>

  )
};
export function InputSinginEmail(props) {
  const { errors, values } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (

    <div className={"input_form"}>
      <label className="form-label">
        {language === "en" ? "Mobile number / email" : "رقم الجوال / البريد الإلكتروني"}</label>
      <Field type="text"
        className={errors.email ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "Mobile number / email" : "رقم الجوال / البريد الإلكتروني"} name="email"
        valie={values.email} />
      <ErrorMessage name="email" component="div" className='errorfiled' />
    </div>

  )
};
export function InputPassword(props) {
  const { errors, singin } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [toggle, setToggle] = useState(false);


  const Openmodal=()=>{
    
    document.getElementById(`singinModal`).classList.remove('show');
    document.getElementById(`singinModal`).style.display ="none";
    document.getElementById(`modalforgetpasswordone`).classList.add('show');
    document.getElementById(`modalforgetpasswordone`).style.display ="block";
 }
  return (
    <div className={"input_form"}>
      <label className="form-label">
        {language === "en" ? "Password" : "كلمة المرور"}</label>

      <div className="position-relative">
        <Field type={toggle === false ? "password" : "text"}
          name="password"  placeholder={language === "en" ? "Password" : "كلمة المرور"}
          className={props.errors.password ? "form-control is-invalid" : "form-control"} />
        <span className='toggoleimg' onClick={() => setToggle(!toggle)}>
          {toggle === false ?
            <img src={Invisible} alt="Invisible"
              className={props.errors.password ? "hide invisible_img" : "invisible_img"}
            />
            :
            <img src={Visible} alt="Visible"
              className={props.errors.password ? "hide invisible_img" : "invisible_img"}
            />
          }
        </span>

      </div>

      <div className="password-bottmdata">
        {singin === true ?

          <button className="btn btnlogin"
          type='button'
            role="button" onClick={Openmodal}
          >{language === "en" ? "Forgot your password ?" : "نسيت كلمة المرور ؟"}
          </button>
          : ""}
      </div>

      <ErrorMessage name="password" component="div" className='errorfiled' />
    </div>

  )
};
export function InputReenterPassword(props) {
  const {errors}=props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [toggle, setToggle] = useState(false);
  return (
    <div className={"input_form"}>
      <label className="form-label">
        {language === "en" ? "Reenter your password" : "أعد إدخال كلمة المرور"}</label>

      <div className="position-relative">
        <Field type={toggle === false ? "password" : "text"}
          name="password_confirmation"  placeholder={language === "en" ? "Password" : "كلمة المرور"}
          className={errors ? "form-control is-invalid" : "form-control"} />
        <span className='toggoleimg' onClick={() => setToggle(!toggle)}>
          {toggle === false ?
            <img src={Invisible} alt="Invisible"
              className={errors ? "hide invisible_img" : "invisible_img"}
            />
            :
            <img src={Visible} alt="Visible"
              className={errors ? "hide invisible_img" : "invisible_img"}
            />
          }
        </span>

      </div>

      <ErrorMessage name="password_confirmation" component="div" className='errorfiled' />
    </div>

  )
};
export function InputCode(props) {
  const { errors, values, disabledinput } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (
    <div className={errors.code ? "input_form position-relative" : "input_form"}>
      <label>{language === "en" ? "Validation code" : "كود التحقق"}</label>
      <Field type="text" component="input" disabled={disabledinput === 0 ? true : false}
        className={props.errors.code ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "Validation code" : "كود التحقق"}
        valie={values.code}
        name="code" />
      <ErrorMessage name="code" component="div" className='errorfiled' />
    </div>
  )
};
export function Inputchangepassword(props) {
  const { errors } = props;
  const authcontext = useContext(Authcontext);
  const [togglechangepassword, setTogglechangepassword] = useState(false);
  const language = authcontext.language;
  return (
    <div className="input_form">
    <label className="form-label">
    {language === "en" ? "Re-enter the new password" : "إعد ادخال كلمة المرور الجديدة"}
    </label>

      <div className="position-relative">

        <Field type={togglechangepassword === false ? "password" : "text"}
          name="password_confirmation"
          placeholder={language === "en" ? "Re-enter the new password" : "إعد ادخال كلمة المرور الجديدة"}
          className={props.errors.password ? "form-control is-invalid" : "form-control"}/>
        <span className='toggoleimg' onClick={() => setTogglechangepassword(!togglechangepassword)}>
          {togglechangepassword === false ?
            <img src={Invisible} alt="Invisible"
              className={"invisible_img"}
            />
            :
            <img src={Visible} alt="Visible"
              className={"invisible_img"}
            />
          }
        </span>

      </div>

      <ErrorMessage name="password_confirmation" component="div" className='errorfiled' />
    </div>

  )
};



