import React, { useContext } from 'react'
import IconClose from "../../../../../images/icon/icon_close.png";
import IconBack from "../../../../../images/icon/iconback.png";
import { Authcontext } from '../../../../../store/context';
import { useState } from 'react';

function ModalHeader(props) {
    const {Title,Id,ButtonBack,ButtonClose ,Modailstart,Subtitle , ModalOpen,setModalOpen}=props;
    const authcontext = useContext(Authcontext);
    const [number, setNumber] = useState(sessionStorage.getItem("emaillate") === null ?"":sessionStorage.getItem("emaillate"));
    const language = authcontext.language;

    let result = number.substring(0, 5);


    const Closemodal=()=>{
        //document.getElementById('verificationcode').classList.add('show')
        document.getElementById(`${ButtonClose}`).classList.remove('show');
        document.getElementById(`${ButtonClose}`).style.display ="none";
     }

    const Backmodal=()=>{
       //document.getElementById('verificationcode').classList.add('show')
       Closemodal();
       document.getElementById(`${ButtonBack}`).classList.add('show');
       document.getElementById(`${ButtonBack}`).style.display ="block";
       setModalOpen(!ModalOpen)
    }
 

    return (
        <div className="modal-header">
            <div className={Modailstart === true ? "top m-start":"top m-end"}>
                {Id === ''?
                ""
                :
                <button id={Id} type="button" className="btn-close"
                    data-bs-dismiss="modal" aria-label="Close" onClick={Closemodal} >
                    <img src={IconClose} alt="IconClose" />
                </button>
                }

                {ButtonBack === ''?"":
                <button type="button" className="btn btn_back" id='btn_back'onClick={Backmodal}>
                <img src={IconBack} alt="" />
              </button> }

            </div>

            <h6>{Title}</h6>
            {Subtitle === ""?
            ""
            :
            <p>
            <span className='left'>{Subtitle} </span>
            <span className='right'>{result}{" "}***</span>
            </p>
            }
        </div>
    )
}

export default ModalHeader;