import React, { useContext } from 'react'
import { Authcontext } from '../../../store/context';
import { InputControl, InputFile } from './inputes';

function FormFive(props) {
    const { Data, setStutes } = props;

    const authcontext = useContext(Authcontext);
    const language = authcontext.language;


    const handleBackform = (e) => {
        e.preventDefault();
        setStutes(4)
    }

    return (
        <div className='sellersingup__form'>
            <div className="title">
                <h6>{language === "en" ? "VAT details" : "تفاصيل ضريبة القيمة المضافة"}</h6>
            </div>

            <InputControl value={Data.provider.tax_registration_num}
                Title={language === "en" ? "Tax registration number" : "رقم التسجيل الضريبي"} />

            <br />
            <br />
            <InputFile file={Data.provider.tax_registration_num_image} 
            Title={language === "ar" ? "شهادة التسجيل الضريبي" : "Tax registration certificate"}
                language={language} Id={3}/>

            <br />
            <br />
            <div className="form-end">
                <button type='button' className='btn btn-back' onClick={handleBackform}>
                    {language === "en" ? "Back" : "الرجوع"}
                </button>
            </div>
        </div>
    )
}

export default FormFive;