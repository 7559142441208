import React, { useState } from 'react'
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import ModalMap from '../../myaddresses/modal/modalmap';
import ModalAddress from '../../myaddresses/modal/modaladdres';
import { Authcontext } from '../../../../store/context';

function CheckoutAddressEnd(props) {
    const {  orderid, loadingbutton } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    let navigate = useNavigate();
    const [address, setAddress] = useState("");
    const [clickedLatLng, setClickedLatLng] = useState({ lat: "", lng: "" });
    const [placeid, setPlaceid] = useState("");

    const Checkoutgo = () => {
        navigate(`/checkoutdone/${orderid}`);
    }
    return (
        <div className='checkout__address__end'>
            <button type='button' className='btn btn-add-loction'
                data-bs-toggle="modal" data-bs-target="#modalmap">
                {language === "ar" ? "إضافة عنوان جديد" : "Add A New Address"}
            </button>


            {loadingbutton === false ?

                <button type='button'
                    className={orderid === "" ? 'btn btn-confirmation button-disabled' :
                        'btn btn-confirmation button-active'}
                    onClick={Checkoutgo}>
                    {language === "ar" ? "تأكيد" : "Confirmation"}
                </button>
                :
                <button className="btn btn-confirmation button-disabled" type="button">
                    <span className="text">
                        {language === "en" ? "Loading" : "جار التحميل"}
                    </span>
                    <span className="spinner"></span>
                </button>}




            <ModalMap clickedLatLng={clickedLatLng} setClickedLatLng={setClickedLatLng}
                setAddress={setAddress} setPlaceid={setPlaceid} />
            <ModalAddress placeid={placeid} Address={address} clickedLatLng={clickedLatLng} />
        </div>
    )
}

export default CheckoutAddressEnd;