import React from 'react';
import IconPhone from "../../../images/icon/icon_phone.png";
import IconLanguagebuyer from "../../../images/icon/icon_language.png";
import IconLanguageseller from "../../../images/icon/icon_language-seller.png";
import { Authcontext } from '../../../store/context';
import { useContext } from 'react';
import { TokenLate } from '../../../api';
import { ChangeLang } from '../../../api/actions';

function NavbarTop() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language,
    setLanguage = authcontext.setLanguage;

  const Toggolelanguge = () => {

    if (language === "ar") {

      setLanguage("en")

      if (TokenLate !== null) {
        ChangeLang("en")
      } else {
        localStorage.setItem("languagelate", JSON.stringify("en"));
        window.location.reload();
      }

    } else {

      setLanguage("ar")

      if (TokenLate !== null) {
        ChangeLang("ar")
      } else {
        localStorage.setItem("languagelate", JSON.stringify("ar"));
        window.location.reload();
      }

    }
  }
  return (
    <section
      className={"navbar__top"}>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <div className="phone">
              <img src={IconPhone} alt="IconPhone" />
              <span className="text">(+966) 55 204 5588</span>
            </div>
          </div>
          <div className="col-6">
            <div className="language" onClick={Toggolelanguge}>
              <span className="text">
                {language === "en" ? "العربية" : "English"}
              </span>

              <img
                src={IconLanguagebuyer}
                alt="IconPhone" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NavbarTop;