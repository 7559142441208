import React, { useState } from 'react';
import ReactPaginate from "react-paginate";
import { useNavigate } from 'react-router';

function Paginate(props) {
    const {handlePageClick,perpage,pagenumber}=props;
   
    return (
        <>
            <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={perpage}
                pageSize={1}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={pagenumber-1}
            />
        </>
    )
}

export default Paginate;