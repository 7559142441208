import React from 'react';
import { useContext } from 'react';
import IconClose from "../../../../images/icon/icon_close.png";
import Loction from './loction';
import { Authcontext } from '../../../../store/context';

function ModalMap(props) {
  const { clickedLatLng, setClickedLatLng, setAddress ,setPlaceid} = props;

  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const removeData = () => {
    setClickedLatLng(null);
  }
  const Clicksupmet = () => {
    document.getElementById('btn-close=mpdalmap').click();
  }

  return (
    <div className="modal fade modalmapaddress" id="modalmap" tabIndex="-1" aria-labelledby="modalmapLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalmapLabel">
              {language === "ar" ? "إضافة عنوان جديد" : "Add a new address"}
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='btn-close=mpdalmap'
              onClick={removeData}>
              <img src={IconClose} alt="IconClose" />
            </button>
          </div>
          <div className="modal-body">
            <Loction clickedLatLng={clickedLatLng} setClickedLatLng={setClickedLatLng} setAddress={setAddress} 
            setPlaceid={setPlaceid}/>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-save" onClick={Clicksupmet}
            data-bs-toggle="modal" data-bs-target="#modalmaptwo">
              {language === "ar" ? "تأكيد الموقع" : "Confirmation"}
            </button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default ModalMap;