import React from 'react';

function CardSellerHome(props) {
    const {Image,Number,Data}=props;
  return (
    <div className='homeseller__cardseller'>
        <span className="image">
            <img src={Image} alt="Image" />
        </span>
        <span className="text">
            <span className="nummber">
              {Number < 1000 ? Number :
              <>
              {(Number / 1000).toFixed(2)} k
              </>
              }
            </span>
            <span className="dec">{Data}</span>
        </span>
    </div>
  )
}

export default CardSellerHome;