import React, { useContext } from 'react'
import { Authcontext } from '../../../../store/context';

function CardList(props) {
  const {Name,Phone,Address}=props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (
    <>
    <li className='list'>
      <span className="title">{language === "ar" ? " الاسم " : " Name "}</span>
      <span className="document">{Name}</span>
    </li>
    <li className='list'>
      <span className="title">{language === "ar" ? " الهاتف " : " Phone "}</span>
      <span className="document">{Phone}</span>
    </li>
    <li className='list'>
      <span className="title">{language === "ar" ? " العنوان " : " Address "}</span>
      <span className="document">{Address}</span>
    </li>
    
    </>
  )
}

export default CardList;