import React, { useContext } from 'react'
import { Authcontext } from '../../../../store/context';
import IconArrowDown from '../../../../images/icon/icon_arrow-down.png';
import MyOrderCard from '../../myorders/card';

function OrderData(props) {
    const { Proudectlenght, Price
        , Priceshipping
        , Totleprice
        , paymentmethod, OrderItems ,Data} = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    return (
        <div className='orderdata'>
            <div className="orderdata__topdata">
                <h6 className="title">{language === "ar" ? "ملخص الطلبية" : "Order Summary"}</h6>

                <ul>
                    <li className='list'>
                        <span className="left">
                            {language === "ar" ? "المجموع الفرعي" : "Subtotal"}
                            <span>{language === "ar" ? `(${Proudectlenght} منتجات )` : `(${Proudectlenght} products)`}</span>
                        </span>
                        <span className="right">{Price}</span>
                    </li>

                    {Data.discount === 0 ?
                    "":
                    <li className='list'>
                        <span className="left">
                        {language === "ar" ? "قيمه الخصم" : "Discount Value"}
                        </span>
                        <span className="right">`SR {Data.discount}`</span>
                    </li>
                    }


                    <li className='list'>
                        <span className="left">
                            {language === "ar" ? "الشحن" : "Shipping"}
                        </span>
                        <span className="right">{Priceshipping}</span>
                    </li>

                    <li className='line'></li>

                    <li className="totle">
                        <span className="left">
                            {language === "ar" ? "المجموع " : "Total"}
                            <span className='outer'>
                                {language === "ar" ? `(يشمل ضريبة القيمة المضافة)` : `(includes value-added tax)`}
                            </span>
                        </span>
                        <span className="right">{Totleprice}</span>

                    </li>
                    <li className='line'></li>

                    <li className="payment">
                        <span className="left">
                            {language === "ar" ? "طريقة الدفع" : "Payment Method"}
                        </span>
                        <span className="right">{paymentmethod}</span>

                    </li>
                </ul>

            </div>


            <div className="accordion" id="accordionExample">
                <div className="accordion-item">

                    <div className="orderdata__enddata collapsed" id="headingTwo"
                        data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                        aria-expanded="false" aria-controls="collapseTwo">
                        <span className="left">
                            <span className="top">{language === "ar" ? "المنتجات" : "Products"}</span>
                            <span className="bottom">
                                {language === "ar" ? `(${Proudectlenght} منتجات )` : `(${Proudectlenght} products)`}
                            </span>
                        </span>
                        <span className="right">
                            <img src={IconArrowDown} alt="IconArrowDown" />
                            <span className="bottom">{Totleprice}</span>
                        </span>

                    </div>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                           
                            {OrderItems.map(item =>
                                <MyOrderCard  Title={item.product.title} Color={item.color.name} Item={item}
                                    Imageproudect={item.product.media.length === 0 ?
                                         "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg"
                                        : item.product.media[0].image}
                                    Size={item.size.name} Quantity={item.quantity}
                                    Price={`SR ${item.price.toFixed()}`}
                                    SellerName={""} key={item.id}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OrderData;