import React from 'react';
import { RemoveProduct } from '../../../../api/actions';
import { useNavigate } from 'react-router';
import ListImage from './listimage';

function ProudectCard(props) {
    const { Item } = props;
    let navigate = useNavigate();

    const EditeProudect=(id)=>{
        navigate(`/editeproudectseller/${id}`)
    }
    return (
        <div className='proudectseller__card'>
            <ListImage Images={Item.media}/>

            <div className="content">
                <ul>
                    <li>
                        <span className="text">{Item.title}</span>
                    </li>
                    <li>
                        <span className="text">{Item.category.name}</span>
                    </li>
                    <li className='price'>
                        <span className="text">{Item.price}</span>
                        <span className="data">ر.س</span>
                    </li>
                    <li className='end'>
                        <button className="btn btn-edite" type='button' 
                        onClick={()=>EditeProudect(Item.id)}>
                            تعديل
                        </button>
                        <button className="btn btn-delete" type='button' 
                        onClick={()=>RemoveProduct(Item.id)}>حذف</button>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ProudectCard;