import React from 'react'
import { useContext } from 'react';
import { Authcontext } from '../../../../../store/context';
import ModalHeader from '../outer/modalheader';
import FormSingIn from './form/form';

function ModalSingin(props) {
  const { setModalOpen} = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div
      className="modal fade modal-late"
      id="singinModal"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
        <ModalHeader Title={language === "en" ? "Sign In" : "تسجيل الدخول"}
        Id={"singinModal_close"} ButtonBack={""} ButtonClose={"singinModal"} Modailstart={true}
        Subtitle={""}/>

        <FormSingIn setModalOpen={setModalOpen}/>
        </div>
      </div>
    </div>
  )
}

export default ModalSingin;