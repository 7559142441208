import React, { useState } from 'react'
import CartCard from './card/index.jsx'
import CartData from './data/index.jsx'
import CartRowList from './list/index.jsx'

function CartContant(props) {
    const {Data,setData,setItemsdata}=props;
    const [loadingChinge, setLoadingChinge] = useState(false);

    const [CartObject,setCartObject]=useState({...Data});

    return (
        <div className="cart__contant">
            <CartRowList CartItem={Data.items} setData={setData}
            setItemsdata={setItemsdata} setLoadingChinge={setLoadingChinge}/>
            <CartData Proudectlentgh={Data.items.length} 
            Price={Data.products_price} 
            Shipping={`SR ${Data.shipping}`}
            Totle={`SR ${Data.total}`} Orderid={Data.id} setData={setData}
            setItemsdata={setItemsdata} Item={Data}/>
      </div>

    )
}

export default CartContant