import React, { useContext } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { Authcontext } from '../../../store/context';
import IconImage from '../../../images/icon/icon_arrow_left_small.png';

function NavList(props) {
  const {Titleproudect,Id}=props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    
    let navigate = useNavigate();


    const GoAllProudect = () => {
      navigate(`/products/page:${1}/filtersort:${"all"}/filtercategory:${0}/filtersubcategory:${0}/minValue:${0}/maxValue:${0}`);
      window.location.reload();
    };
  return (
    <div className='navlist'>
        <NavLink to={"/"} >{language === "ar" ? "الصفحه الرئيسيه" : "Home Page"}</NavLink>
        <img src={IconImage} alt="IconImage" />

        
        <button className="btn" type="button" onClick={GoAllProudect}>
             {language === "ar" ? "أحدث المنتجات" : "Latest Products"}
          </button>
        {Titleproudect === ""?<></>
        :
        <>
        <img src={IconImage} alt="IconImage" />
        <NavLink to={`/proudect/${Id}`} >{
        Titleproudect.length <= 50 ? Titleproudect :
        `${Titleproudect.slice(0,50)}...`
        }</NavLink>
        </>
        }
    </div>
  )
}

export default NavList;