import React, { useContext, useEffect, useState } from 'react'
import { GetDataPrivacypolicy } from '../../api/actions';
import Privacypolicydata from '../../components/buyer/privacypolicy/Privacypolicydata.jsx';
import Loading from '../loading/loading';
import { Authcontext } from '../../store/context';

function Privacypolicy() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataPrivacypolicy(setData, setLoading)
  }, [loading]);

  return (
    <>
      {loading === false ? 
      <Loading/>
      :
    <section className='privacypolicy'>
      <div className="container">
        <Privacypolicydata
          Title={language === "ar" ? "سياسة الاستخدام والخصوصيه" : "Usage and Privacy Policy"}
          Data={data}
        />
      </div>

    </section>
}
</>
  )
}

export default Privacypolicy;