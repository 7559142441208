import React, { useContext } from 'react'
import { Authcontext } from '../../../../store/context';
import Iconsuess from "../../../../images/icon/icon_tick-circle.png";

function DataUser(props) {
    const {Name,Phone,Address,Paymentdata , Image}=props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div className='datauser'>
        <div className="row">
            <div className="col-lg-4 col-sm-12">
                <div className="item">
                    <div className="topdata">{language === "en" ? "Client" :"العميل"}</div>
                    <div className="bottomdata">
                        <span className="image">
                            <img src={Image} alt="Image" />
                        </span>
                        <span className="content">
                            <span className="name">{Name}</span>
                            <span className="phone">{Phone}</span>
                        </span>
                    </div>
                </div>
                </div>

                <div className="col-lg-4 col-sm-12">
                <div className="item">
                    <div className="topdata">{language === "en" ? "Shipping Address" :"عنوان الشحن"}</div>
                    <div className="bottomdata">
                        <span className="content">
                            <span className="name">{Address=== null ? "null":Address.address}</span>
                            <span className="phone">{Phone}</span>
                        </span>
                    </div>
                </div>
                </div>

                <div className="col-lg-4 col-sm-12">

                <div className="item">
                    <div className="topdata">{language === "en" ? "Paying Off" :"الدفع"}</div>
                    <div className="bottomdata">
                        <span className="icon"><img src={Iconsuess} alt="Iconsuess" /></span>
                        <span className="content">
                            <span className="name">{Paymentdata}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default DataUser;