import React, { useContext } from 'react'
import FormContactus from '../../components/buyer/connectus/index';
import { Authcontext } from '../../store/context';

function ConnectUs() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <section className='connectus'>
    <div className="container">
        <div className="connectus__form">
            <h2>{language === "ar" ? "تواصل معنا" : "Connect Us"} </h2>
            <FormContactus/>
        </div>
        </div>
    </section>
  )
}

export default ConnectUs;