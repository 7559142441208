import React, { useContext, useEffect, useState } from 'react'
import Privacypolicydata from '../../components/buyer/privacypolicy/Privacypolicydata.jsx';
import { GetDataAbout } from '../../api/actions';
import { Authcontext } from '../../store/context';
import Loading from '../loading/loading';

function AboutUs() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataAbout(setData, setLoading)
  }, [loading]);


  return (
    <>
      {loading === false ? 
      <Loading/>
      :
    <section className='privacypolicy'>
      <div className="container">
        <Privacypolicydata
          Title={language === "ar" ? "نبذة عنا" : "About us"}
          Data={data}
        />
      </div>

    </section>
}
</>
  )
}

export default AboutUs;