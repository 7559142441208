import React, { useState } from "react";
import { useContext } from "react";
import { Authcontext } from "../../../../../store/context";
import FormSingup from "./form/form";
import ModalHeader from "../outer/modalheader.jsx";


function ModelSingup(props) {
  const { setModalOpen} = props;

  const authcontext = useContext(Authcontext);
  const language = authcontext.language;



  return (
    <div
      className="modal fade modal-late"
      id="singupModal"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
        <ModalHeader Title={language === "en" ? "Create A New Account" : "إنشاء حساب جديد"}
        Id={"singupModal_close"} ButtonBack={""} ButtonClose={"singupModal"} Modailstart={true}
        Subtitle={""}/>

         <FormSingup setModalOpen={setModalOpen}/>

        </div>
      </div>
    </div>
  )
}

export default ModelSingup;