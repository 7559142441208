import React, { useContext, useState } from 'react'
import OrderData from './OrderData';
import ShippingDetails from './shippingdetiles';
import { CanselOrderItem } from '../../../../api/actions';
import { Authcontext } from '../../../../store/context';

function MyOrdersDetailsData(props) {
    const { Data } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    const [message, setMessage] = useState("");

    const Cancelorder = (id) => {
        CanselOrderItem(id, setMessage);
    }
    return (
        <div className='myordersdetails__data'>
            {Data.shipping_address === null ?
                "" :
                <ShippingDetails Name={Data.shipping_address.user_name}
                    Phone={Data.shipping_address.phone}
                    Address={Data.shipping_address.address} />
            }

            <OrderData Proudectlenght={Data.orderItemProducts.length} Price={`SR ${Data.products_price}`}
                Priceshipping={`SR ${Data.shipping}`} Totleprice={`SR ${Data.total}`}
                paymentmethod={Data.payment_gateway} OrderItems={Data.orderItemProducts} Data={Data} />



            <div className="mb-1 mt-4">
                {message === "" ? "" :
                    <span className='errorfiled'>{message}</span>
                }
            </div>

            {Data.status_en === "Canceled" || Data.status_en === `Delivered` ?
                ""
                : 
                <div className="myordersdetails__buttons">
                    <button type='button' className='btn btn-cansel' onClick={() => Cancelorder(Data.id)}>
                        {language === "ar" ? "إلغاء الطلب" : "Cancelling order"}
                    </button>
                </div>
                }
        </div>
    )
}

export default MyOrdersDetailsData;