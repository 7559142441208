import React from 'react'
import { NavLink } from 'react-router-dom';
import IconUser from "../../../../images/icon/icon_image_user.png";
import IconLogo from "../../../../images/logo/logo.png";

import SearchBar from './searchbar';
import Iconlogin from './Iconlogin';
import { TokenLate } from '../../../../api/index.js';
import IconCart from './iconcart';
import IconHart from './iconhart';
import IconProfile from './iconprofile';
import IconCartImage from "../../../../images/icon/icon_shopping-cart.png";
import IconHartImage from "../../../../images/icon/icon_heart.png";

function NavbarBottomBuyer() {
  return (
    <section className='navbar__bottom'>
      <div className="container">
        <div className="navbottom">
          <div className="left">
            {TokenLate === null ?
              <div className="dropdown">
                <button
                  className="dropdown-toggle icon_profile"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={IconUser} alt="user" />

                </button>
                <Iconlogin />
              </div>
              :
              <IconProfile />

            }
            <>
              {TokenLate === null ?
                <button type="button" className="btn icon_hart" data-bs-toggle="modal" data-bs-target="#modailnotlogin">
                  <img src={IconHartImage} alt="" />
                </button>
                :
                <IconHart />
              }
            </>
            <>
              {TokenLate === null ?
                <button type="button" className="btn icon_cart" data-bs-toggle="modal" data-bs-target="#modailnotlogin">
                  <img src={IconCartImage} alt="" />
                </button>
                :
                <IconCart />
              }
            </>
          </div>
          <div className="medima">
            <SearchBar />
          </div>
          <div className="right">
            <NavLink to={"/"} className="icon_logo">
              <img src={IconLogo} alt="" />
            </NavLink>
          </div>

        </div>
      </div>
    </section>
  )
}

export default NavbarBottomBuyer;

