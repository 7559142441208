import React, { useEffect, useState } from 'react'
import NavlistSeller from '../../../components/seller/proudectseller/navlist'
import ProudectCard from '../../../components/seller/proudectseller/cards'
import { GetMyProductsAll } from '../../../api/actions';
import Paginate from '../../../components/shared/paginate';
import { useNavigate, useParams } from 'react-router';
import Loading from '../../../layout/loading/loading';
import MyOrdersEmpty from '../../../components/seller/home/empty';
import ImageEmpty from '../../../images/icon/icon_shoppingcart-empty.png';
import { Authcontext } from '../../../store/context';
import { useContext } from 'react';

function ProudectSeller() {
  const { page } = useParams();
  let navigate = useNavigate();
  const pagenumber = +page;
  const [pageCount, setpageCount] = useState(pagenumber);
  const [perpage, setPerpage] = useState(0);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortdata, setSortdata] = useState("");
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;


  useEffect(() => {
    GetMyProductsAll(pageCount, setLoading, setProducts, setPerpage, sortdata);
  }, [loading, pageCount, sortdata]);



  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    setpageCount(number)
    navigate(`/products/${number}`)
  };

  return (

    <>
      {loading === false ? (
        <Loading />
      ) : (

        <section className='proudectseller'>
          <div className="container">
            <NavlistSeller setSortdata={setSortdata} products={products} />

            {products.length === 0 ?
              <MyOrdersEmpty ImageEmpty={ImageEmpty}
                Title={language === "ar" ? "لا توجد منتجات حتى الآن" : "There are no products yet"} />

              :
              <>
                <div className="proudectseller__row">
                  <div className="row">
                    {products.map(item =>
                      <div className="col-lg-4 col-md-6" key={item.id}>
                        <ProudectCard Item={item} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-9">
                    <Paginate setpageCount={setpageCount} perpage={perpage}
                      handlePageClick={handlePageClick} pagenumber={pagenumber} />
                  </div>
                  <div className="col-3">
                  </div>
                </div>
              </>
            }
          </div>
        </section>
      )}
    </>
  )
}

export default ProudectSeller