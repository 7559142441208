import React, { useContext, useState } from 'react'
import { InputFile, InputRegistrationNumber } from './input.jsx';
import { Authcontext } from '../../../../../store/context';

function Form_Five(props) {
    const { setStutesform, setFilethree, filethree, handleSubmit, message, loadingbutton, StateArrayfive, setStateArrayFive } = props;

    const [validation, setvalidation] = useState("hide");
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const handleChange = (e) => {
        const value = e.target.value;
        setStateArrayFive({
            ...StateArrayfive,
            [e.target.name]: value,
        });
    };
    const handleNextform = (e) => {
        e.preventDefault();
        setvalidation(true);
        handleSubmit();

    }
    const handleBackform = (e) => {
        e.preventDefault();
        setStutesform(4)
    }

    return (
        <div className='sellersingup__form'>
            <div className="title">
                <h6>
                    {language === "en" ? "Tax Registration" : "التسجيل الضريبي"}</h6>
            </div>
            <InputRegistrationNumber language={language} validation={validation}
                value={StateArrayfive.registrationnumber} handleChange={handleChange} />

            <InputFile language={language} setFile={setFilethree} file={filethree} validation={validation}
                Title={language === "ar" ? "تحميل ملف التسجيل الضريبي" : "Download the tax registration file"} Id={3} />


            <br />
            {message === "" ? "" : <span className="errorfiled">{message}</span>}
            <br />
            <br />
            <div className="form-end">
                {loadingbutton === false ?
                    <button type='button' className='btn btn-next' onClick={handleNextform}>
                        {language === "en" ? "Create account" : "إنشاء الحساب"}
                    </button>
                    :
                    <button type='button' className='btn btn-next button-disabled' >
                        {language === "en" ? "Loading" : "جار التحميل"}
                        <span className="spinner"></span>
                    </button>}


                <button type='button' className='btn btn-back' onClick={handleBackform}>
                    {language === "en" ? "Back" : "الرجوع"}
                </button>
            </div>
        </div>
    )
}

export default Form_Five;