import React, { useContext, useState } from 'react';
import Imagupload from '../../../../images/icon/icon_chinge_phote.png';
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Authcontext } from '../../../../store/context';
import { UpdateProfile } from '../../../../api/actions';

function ProfileForm(props) {
    const { Data } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    const [toggle, setToggle] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [messageerror, setMessageerror] = useState("");


    const [loadingbutton, setLoadingbutton] = useState(false);

    const [file, setFile] = useState(Data.image);
    const ImageError = "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg";

    const state = {
        name: Data.name === null ? "": Data.name,
        phone: Data.phone === null ? "": Data.phone,
        email: Data.email === null ? "": Data.email,
    };
    

    const onSubmit = (values) => {
            setLoadingbutton(true)
        UpdateProfile(values, selectedFile,setMessageerror ,setLoadingbutton)
    }

    const handleFileSelect = (e) => {
        let file = e.target.files[0];
        setFile(URL.createObjectURL(file));
        setSelectedFile(file);
    }
    const form = (props) => {
        return (
            <div className='profile__form'>
                <div className="image">
                    <img src={file === null ? ImageError : file}
                    
                    alt="ProfileImage" onError={(e) => {
                        e.target.onerror = null;
                        e.target.src =
                            "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg";
                    }} />

                    <span className="imginput">
                        <img src={Imagupload} alt="" />
                        <input type="file" accept="image/*" className="input-file"
                            onChange={e => handleFileSelect(e)} />
                    </span>
                </div>

                <form onSubmit={props.handleSubmit}>
                    <div className="form">
                        <div className="inputdata">
                            <label>{language === "ar" ? "الاسم" : "Name"}</label>
                            <div className="inputitem">
                                <Field type="text" component="input"
                                    className={props.errors.name ? "form-control is-invalid" : "form-control"}
                                    placeholder={language === "ar" ? "الاسم" : "name"}
                                    name="name" />
                                <ErrorMessage name="name" component="span" className='errorfiled' />
                            </div>
                        </div>
                        <div className="inputdata">
                            <label>{language === "ar" ? "رقم الجوال" : "Mobile Number"}</label>
                            <div className="inputitem">
                                <Field type="number" component="input"
                                    className={props.errors.phone ? "form-control is-invalid" : "form-control"}
                                    placeholder={"54 556 1870"}
                                    name="phone" />
                                <ErrorMessage name="phone" component="span" className='errorfiled' />
                            </div>
                        </div>
                        <div className="inputdata">
                            <label>{language === "ar" ? "البريد إلكتروني" : "Email"}</label>
                            <div className="inputitem">
                                <Field type="email" component="input"
                                    className={props.errors.email ? "form-control is-invalid" : "form-control"}
                                    placeholder={language === "en" ? "E-mail" : "البريد الإلكتروني"}
                                    pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"

                                    name="email" />
                                <ErrorMessage name="email" component="span" className='errorfiled' />
                            </div>
                        </div>


                        <div className="mb-1">
                            {messageerror === "" ? "" :
                                <span className='errorfiled'>{messageerror}</span>
                            }
                        </div>
                        <div className='btn-senddata'>
                            
                        {loadingbutton === false ?
                            <button className='btn btn-send' type="submit">
                                {language === "en" ? "Save" : "حفظ"}
                            </button>
                            :
                            <button className='btn  button-disabled btn-send' type="submit">
                                {language === "en" ? "Loading" : "جار التحميل"}
                                <span className="spinner"></span>
                            </button>}
                        </div>
                    </div>
                </form>
            </div>
        )
    }
    const schema = () => {
        const schema = Yup.object().shape({
            name: Yup.string().required("Name Is Required"),
            email: Yup.string().required("Email Is Required"),
            phone: Yup.string().required("The phone number Is Required")
                .min(9, 'The phone number must be at least 9 Digits!')
                .max(13, 'The phone number must be no more than 13 digits long!'),
        });

        return schema;
    }
    return (
        <>
            <Formik
                initialValues={state}
                onSubmit={onSubmit}
                render={form}
                validationSchema={schema()}
                validateOnChange={false}
                validateOnBlur={false}
            />
        </>
    )
}

export default ProfileForm;