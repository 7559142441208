export const Api = "https://admin.al-lati.com/api/";
export const TokenLate = JSON.parse(localStorage.getItem("tokenlate"));
export const languagelate = JSON.parse(localStorage.getItem("languagelate"));
export const UserTypelate = JSON.parse(localStorage.getItem("usertypelate"));
export const UserIdlate = JSON.parse(localStorage.getItem("useridlate"));

//localStorage.setItem("tokenclicklized", JSON.stringify(response.data.data.token));
// window.location.pathname = "/";
// Authorization: `Bearer ${JSON.parse(localStorage.getItem("tokenclicklized"))}`,

/*
//export const Api = "https://admin.late.hwzn.sa/api/";
//export const Api = "https://admin.lawaty.com.sa/api/";
*/
