import React, { useContext } from 'react';
import IconNotificationCard from '../../../../images/icon/icon_notification_card.png';
import IconNotificationClose from '../../../../images/icon/icon_notification_close.png';
import { DeleteNotification } from '../../../../api/actions';


function NotificationCard(props) {
  const {Item}=props;
  return (
    <div className='notification__card'>
        <span className='data'>
         <img src={IconNotificationCard} alt="IconNotificationCard" className='iconnotification'/>
        <span className="text">{Item.body}</span>
        </span>
        <button className='btn btn-closenotification' type='button'
        onClick={()=>DeleteNotification(Item.id)}>
            <img src={IconNotificationClose} alt="IconNotificationClose" />
        </button>
    </div>
  )
}

export default NotificationCard;