import React, { useContext, useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { Authcontext } from '../../../../store/context';
import { AddRevwe } from '../../../../api/actions';

function PoupeRevewe(props) {
    const { Id } = props;
    const [rating, setRating] = useState(0);
    const [message, setMessage] = useState("");
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const handleRating = (e) => {
        setRating(e)
    }



    const handleChange = (e) => {
        const value = e.target.value;
        setMessage(value)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        AddRevwe(Id, rating, message);
        setRating(0)
        document.getElementById(`modalpouperevewe-btn-${Id}`).click();
    };


    return (
        <div className="modal modalpouperevewe fade"
            id={"exampleModal-" + Id}
            tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close"
                            data-bs-dismiss="modal" aria-label="Close"
                            id={"modalpouperevewe-btn-" + Id}>

                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="content">
                            <h4>
                                {language === "en" ? "Product Rating" : "تقييم المنتج"}
                            </h4>
                            <div className='rating'>
                                <Rating onClick={handleRating} ratingValue={rating} size={30} />
                            </div>

                            <div className="massege">
                                <span>
                                    {language === "en" ? "Write your review here" : "اكتب رأيك هنا"}

                                </span>


                                <input className="form-control"
                                    name="message" placeholder={language === "en" ? "You can write your opinion here" : "يمكنك كتابة رأيك هنا"}


                                    value={message || ''}
                                    onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn" type="button" onClick={handleSubmit}>
                            {language === "en" ? "Submit Evaluation" : "إرسال التقييم"}

                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PoupeRevewe;



