import React, { useContext } from 'react';
import ImageProfile from "../../../../images/icon/icon_profile-shape.png";
import IconStore from "../../../../images/icon/icon_group_store.png";
import IconStorewhite from "../../../../images/icon/icon_group_storewhite.png";
import Icondec from "../../../../images/icon/icon_group_dec.png";
import Icondecwhite from "../../../../images/icon/icon_group_decwhite.png";
import Iconbanck from "../../../../images/icon/icon_group_banck.png";
import Iconbanckwhite from "../../../../images/icon/icon_group_banckwhite.png";
import Iconshape from "../../../../images/icon/icon_discount-shape.png";
import Iconshapewhite from "../../../../images/icon/icon_discount-shapewhite.png";
import { Authcontext } from '../../../../store/context';
import { useNavigate } from 'react-router';



function NavListProfile(props) {
    const { stutes ,setStutes } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    let navigate = useNavigate();
    
    const goToProfilepage = (e) => {
        e.preventDefault();
        setStutes(1)
        navigate(`/profile`);
    }

    const goToStorepage = (e) => {
        e.preventDefault();
        setStutes(2)
        navigate(`/storedata`);
    }

    return (
        <ul className='navlistsingup'>
            <li className={stutes > 1 ? "activethree active" : 'active'}>
                <span className='btn btn-add' onClick={goToProfilepage}>
                    <img src={ImageProfile} alt="ImageProfile" />
                    <span className="text">{language === "ar" ? "البيانات الشخصية" : "Personal data"}</span>
                </span>
            </li>

            <li className={stutes === 2 ? "activetwo active" :
                stutes > 2 ? "activetfour active" : ""}>
                <span className={'btn btn-add'} onClick={goToStorepage}>
                    <img
                        src={stutes >= 2 ? IconStorewhite : IconStore}
                        alt="ImageProfile" />
                    <span className="text">{language === "ar" ? "بيانات المتجر" : "Store data"}</span>
                </span>

            </li>

            <li className={stutes === 3 ? "activetwo active" :
                stutes > 3 ? "activetfour active" : ""}>
                <span className={stutes === 1 ? 'btn-add':'btn btn-add'} onClick={()=>setStutes(3)}>
                    <img
                        src={stutes >= 3 ? Icondecwhite : Icondec}
                        alt="ImageProfile" />
                    <span className="text">{language === "ar" ? "المستندات" : "Documents"}</span>
                </span>
            </li>
            <li className={stutes === 4 ? "activetwo active" :
                stutes > 4 ? "activetfour active" : ""}>
                <span className={stutes === 1 ? 'btn-add':'btn btn-add'} onClick={()=>setStutes(4)}>
                    <img
                        src={stutes >= 4 ? Iconbanckwhite : Iconbanck}
                        alt="ImageProfile" />
                    <span className="text">{language === "ar" ? "البنك" : "The Bank"}</span>
                </span>
            </li>

            <li className={stutes === 5 ? "activetwo active" : ""}>
                <span className={stutes === 1 ? 'btn-add':'btn btn-add'} onClick={()=>setStutes(5)}>
                    <img
                        src={stutes === 5 ? Iconshapewhite : Iconshape}
                        alt="ImageProfile" />
                    <span className="text">{language === "ar" ? "ضريبة القيمة المضافة" : "Value Added Tax"}</span>
                </span>
            </li>
        </ul>
    )
}

export default NavListProfile;