import React, { useContext } from 'react'
import Backgroundone from '../../../images/home/latestproducts_cover.png';

import Iconarrow from '../../../images/icon/icon_arrow-right.png';

import { NavLink } from 'react-router-dom';
import CardProudect from '../../shared/cardproudect/index.jsx';
import Titlepage from '../../shared/title';
import { Authcontext } from '../../../store/context';

function LatestProducts(props) {
    const { LatestProducts ,setData } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    return (
        <div className='homebuyer__latestproducts'>

            <Titlepage Title={language === "ar" ? "أحدث المنتجات" : "Latest Products"}/>
            <div className="row">
                <div className="col-md-12 col-lg-6">
                    <div className="latestproducts-cardcover">
                        <img src={Backgroundone} alt="Backgroundone" />
                        <span>{language === "ar" ? "أفضل المنتجات بأفضل الأسعار والعروض" :
                            "The best products at the best prices and performances"}</span>
                    </div>
                </div>

                {LatestProducts.map(item =>
                    <div className="col-6 col-lg-3" key={item.id}>
                        <CardProudect 
                        Image={item.media.length === 0 ? "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg"
                        :item.media[0].image}  Price={`SR ${item.price}`}
                            Data={item.title} Favoritecard={false} Id={item.id} Vailable={item.in_stock}
                            Item={item} setData={setData}/>
                </div>
                )}
                

                <div className="col-12 see_all">
                    <NavLink 
                    to={`/products/page:${1}/filtersort:${"all"}/filtercategory:${0}/filtersubcategory:${0}/minValue:${0}/maxValue:${0}`}>
                        <img src={Iconarrow} alt="Iconarrow" />
                        <span>{language === "ar" ? "رؤية الكل" : "See All"}</span>
                    </NavLink>
                </div>
            </div>

        </div>
    )
}

export default LatestProducts;
