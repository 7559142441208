import React, { useEffect, useState } from 'react';
import IconBack from "../../../images/icon/icon_arrow-right.png";
import { Authcontext } from '../../../store/context';
import { useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import ProudectOnlaySellerRow from '../../../components/seller/proudectonlayseller';
import Loading from '../../../layout/loading/loading';
import { GetOnlayOrdersSeller } from '../../../api/actions';

function ProudectOnlaySeller() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  useEffect(() => {
    GetOnlayOrdersSeller(id, setData, setLoading);
  }, [loading]);


  return (
    <>
      {loading === false ?
        <Loading />
        :
        <section className='proudectonlayseller'>
          <div className="container">

            <div className="top">
              <NavLink to={ data.status_en === "Shipped" || data.status_en === "In progress" ?
               `/currentrequestsseller/${1}`:`/previousrequestsseller/${1}`} 
              
              
              className='btn btn-back'>
                <img src={IconBack} alt="IconBack" />
                <span className="text"> {language === "ar" ? "رجوع" : "Back"}</span>
              </NavLink>
            </div>
            
            <ProudectOnlaySellerRow Data={data} />

          </div>
        </section>
      }
    </>
  )
}

export default ProudectOnlaySeller;