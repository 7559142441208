import React from 'react'
import LatestRequests from '../../../components/seller/home/latestrequests';
import HomeSellerSlider from '../../../components/seller/home/slider';
import { GetDataHomeSeller } from '../../../api/actions';
import { useState } from 'react';
import { useEffect } from 'react';
import Loading from '../../../layout/loading/loading';
import MyOrdersEmpty from '../../../components/seller/home/empty';
import ImageEmpty from '../../../images/icon/icon_shoppingcart-empty.png';
import { Authcontext } from '../../../store/context';
import { useContext } from 'react';

function HomeSeller() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataHomeSeller(setData, setLoading)
  }, [loading]);

  return (
    <>
      {loading === false ?
        <Loading />
        :
        <section className='homeseller'>
          <div className="container">
            <HomeSellerSlider Data={data} />
            {data.latest_orders.length === 0 ?
              <MyOrdersEmpty ImageEmpty={ImageEmpty}
                Title={language === "ar" ? "لا توجد طلبات حتى الآن" : "There are no requests yet"} />
              :
              <LatestRequests Data={data.latest_orders} />
            }
          </div>
        </section>
      }
    </>
  )
}

export default HomeSeller;