import React, { useContext } from 'react'
import CardSellerHome from './card';
import Imageone from "../../../../../images/icon/image_home_card_one.png";
import Imagetwo from "../../../../../images/icon/image_home_card_two.png";
import Imagethree from "../../../../../images/icon/image_home_card_three.png";
import Imagefour from "../../../../../images/icon/image_home_card_four.png";
import { Authcontext } from '../../../../../store/context';

function HomeRowCards(props) {
    const {Data}=props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    return (
        <div className='homeseller__rowcards'>
            <div className="row">
                <div className="col-lg-6 col-6">
                    <CardSellerHome Number={Data.profit} 
                    Image={Imageone} Data={language === "ar" ? "الأرباح" : "profits"}/>
                </div>
                <div className="col-lg-6 col-6">
                    <CardSellerHome Number={Data.order_count} 
                    Image={Imagetwo} Data={language === "ar" ? "الطلبات" : "Requests"}/>
                </div>
                <div className="col-lg-6 col-6">
                    <CardSellerHome Number={Data.product_count} 
                    Image={Imagethree} Data={language === "ar" ? "المنتجات" : "Products"}/>
                </div>
                <div className="col-lg-6 col-6">
                    <CardSellerHome Number={Data.rate_count}
                    Image={Imagefour}  Data={language === "ar" ? "التقييمات" : "Ratings"}/>
                </div>
            </div>
        </div>
    )
}

export default HomeRowCards;