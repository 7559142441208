/* eslint-disable react/jsx-no-comment-textnodes */
import { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/buyer/helpers/index.jsx";
import AboutUs from "./layout/aboutus/index.jsx";
import ConnectUs from "./layout/connectus/index.jsx";
import Footer from "./layout/footer/index.jsx";
import Navbar from "./layout/navbar/index.jsx";
import Privacypolicy from "./layout/privacypolicy/index.jsx";
import EditeProfile from "./pages/buyer/editeprofile/index.jsx";
import Favorite from "./pages/buyer/favorite/index.jsx";
import HomeBuyer from "./pages/buyer/home/index.jsx";
import MyAddresses from "./pages/buyer/myaddresses/index.jsx";
import CurrentRequests from "./pages/buyer/currentrequests/index.jsx";
import MyOrdersDetails from "./pages/buyer/myordersdetails/index.jsx";
import Notification from "./pages/buyer/notification/index.jsx";
import Offers from "./pages/buyer/offers/index.jsx";
import Proudect from "./pages/buyer/proudect/index.jsx";
import Proudects from "./pages/buyer/proudects/index.jsx";
import { Authcontext } from "./store/context.js";
import CompletedRequests from "./pages/buyer/completedrequests/index.jsx";
import CanceledRequests from "./pages/buyer/canceledrequests/index.jsx";
import Cart from "./pages/buyer/cart/index.jsx";
import CheckoutAddress from "./pages/buyer/checkoutaddress/index.jsx";
import StoreDetails from "./pages/buyer/storedetails/index.jsx";
import CheckoutDone from "./pages/buyer/checkoutdone/index.jsx";
import Trems from "./layout/trems/index.jsx";
import ProudectSearch from "./pages/buyer/proudectsearch/index.jsx";
import SellerSingUp from "./pages/seler/auth/singup.jsx";
import CurrentrSeller from "./pages/seler/requests/currentr.jsx";
import PreviousSeller from "./pages/seler/requests/previous.jsx";
import ProudectSeller from "./pages/seler/proudectseller/index.jsx";
import ProudectOnlaySeller from "./pages/seler/proudectonlayseller/index.jsx";
import SellerCode from "./pages/seler/auth/sellercode.jsx";
import Home from "./layout/home/index.jsx";
import Profile from "./layout/profile/index.jsx";
import StoreData from "./pages/seler/storedata/index.jsx";
import Ratings from "./pages/seler/ratings/index.jsx";
import EditeStoreData from "./pages/seler/editestoredata/index.jsx";
import EditeProudect from "./pages/seler/editeproudect/index.jsx";
import AddProudect from "./pages/seler/addproudect/index.jsx";
import ModailNotLogin from "./components/shared/modail/modailnotlogin.jsx";
import PaymentVisa from "./pages/buyer/paymentvisa/index.jsx";
import SellerSingin from "./pages/seler/auth/singin.jsx";
import NotFound from "./layout/not-found/index.jsx";


function App() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (
    <main className={language}>
      <BrowserRouter>
        <ScrollToTop>

          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/products/:page/:filtersort/:filtercategory/:filtersubcategory/:filterminvalue/:filtermaxvalue"
             element={<Proudects />} exact />
            <Route path="/proudect/:id" element={<Proudect />} exact />
            <Route path="/storedetails/:id" element={<StoreDetails />} exact />

            <Route path="/coupons/:page/:filtersort/:filtercategory/:filtersubcategory/:filterminvalue/:filtermaxvalue"
             element={<Offers />} exact />
            <Route path="/favorite" element={<Favorite />} exact />
            <Route path="/trems" element={<Trems />} exact />
            <Route path="/privacypolicy" element={<Privacypolicy />} exact />
            <Route path="/aboutus" element={<AboutUs />} exact />
            <Route path="/connectus" element={<ConnectUs />} exact />
            <Route path="/profile" element={<Profile />} exact />
            <Route path="/editeprofile" element={<EditeProfile />} exact />
            <Route path="/myaddresses" element={<MyAddresses />} exact />
            <Route path="/notification/:id/:page" element={<Notification />} exact />

            <Route path="/currentrequests/:page" element={<CurrentRequests />} exact />
            <Route path="/completedrequests/:page" element={<CompletedRequests />} exact />
            <Route path="/canceledrequests/:page" element={<CanceledRequests />} exact />

            <Route path="/myordersdetails/:id" element={<MyOrdersDetails />} exact />
            <Route path="/cart" element={<Cart />} exact />
            <Route path="/checkoutaddress/:id" element={<CheckoutAddress />} exact />
            <Route path="/checkoutdone/:id" element={<CheckoutDone />} exact />
            <Route path="/paymentvisa" element={<PaymentVisa />} exact />
            <Route path="/paymentvisaerrror" element={<PaymentVisa />} exact />
            <Route path="/proudectsearch/:search/:page" element={<ProudectSearch />} exact />

              // seller page

            <Route path="/sellersingup" element={<SellerSingUp />} exact />
            <Route path="/sellercode" element={<SellerCode />} exact />
            <Route path="/sellersingin" element={<SellerSingin />} exact />

            <Route path="/proudectseller/:page" element={<ProudectSeller />} exact />
            <Route path="/editeproudectseller/:id" element={<EditeProudect />} exact />
            <Route path="/addproudect" element={<AddProudect />} exact />

            <Route path="/currentrequestsseller/:page" element={<CurrentrSeller />} exact />
            <Route path="/requestsseller/:id" element={<ProudectOnlaySeller />} exact />
            <Route path="/previousrequestsseller/:page" element={<PreviousSeller />} exact />
            
            <Route path="/storedata" element={<StoreData />} exact />
            <Route path="/editestoredata" element={<EditeStoreData />} exact />

            <Route path="/ratings/:id/:page" element={<Ratings />} exact />

            <Route path='*' element={<NotFound />} exact />
            <Route path='pageerror' element={<NotFound />} exact />

          </Routes>
          <ModailNotLogin />
          <Footer />
        </ScrollToTop>
      </BrowserRouter>
    </main>
  );
}

export default App;