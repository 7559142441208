import React, { useState, useEffect } from 'react';
import LatestRequests from '../../../components/seller/home/latestrequests';
import NavListRequest from '../../../components/seller/requests/navlistrequest';
import ImageEmpty from '../../../images/icon/icon_shoppingcart-empty.png';
import { GetCurrentSellerOrders } from '../../../api/actions';
import Loading from '../../../layout/loading/loading';
import MyOrdersEmpty from '../../../components/seller/home/empty';
import { Authcontext } from '../../../store/context';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import Paginate from '../../../components/shared/paginate';

function CurrentrSeller() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  let navigate = useNavigate();
  const { page } = useParams();
  const pagenumber = +page;
  const [pageCount, setpageCount] = useState(pagenumber);
  const [perpage, setPerpage] = useState(0);

  useEffect(() => {
    GetCurrentSellerOrders(pageCount, "current", setData, setLoading, setPerpage);
    //current,completed,canceled,previous
  }, [loading, pageCount]);

  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    setpageCount(number);
    navigate(`/currentrequestsseller/${number}`);
  };

  return (
    <>
      {loading === false ?
        <Loading />
        :
        <section className='requestsseller'>
          <div className="container">
            <NavListRequest />
            {data.length === 0 ?
              <MyOrdersEmpty ImageEmpty={ImageEmpty}
                Title={language === "ar" ? "لا توجد طلبات حتى الآن" : "There are no requests yet"} />
              :
              <LatestRequests Data={data} />
            }
            <div className="row">
              <div className="col-9">
                <Paginate perpage={perpage} handlePageClick={handlePageClick} pagenumber={pagenumber} />
              </div>
              <div className="col-3"></div>
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default CurrentrSeller;