import React, { useContext, useEffect, useState } from 'react'
import FavoriteEmpty from '../../../components/buyer/favorite/empty/index.jsx';
import FavoriteAll from '../../../components/buyer/favorite/index.jsx';
import Titlepage from '../../../components/shared/title/index.jsx';
import Loading from '../../../layout/loading/loading.jsx';
import { Authcontext } from '../../../store/context.js';
import { GetDataFavorite } from '../../../api/actions.js';
import { TokenLate, UserIdlate } from '../../../api/index.js';

function Favorite() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(UserIdlate === 3 && TokenLate !== null  ){
      window.location.pathname = `/`
    }
  }, []);


  useEffect(() => {
    GetDataFavorite(setData, setLoading)
  });

  return (

    <>
      {loading === false ?
        <Loading />
        :
        <section className='favorite'>
          <div className="container">
            <Titlepage Title={language === "ar" ? "المفضلة" : "Favorite"} />

            {data.length === 0 ?
            <FavoriteEmpty/>
              :
              <FavoriteAll Data={data} setData={setData}/>
            }
          </div>
        </section>
      }
    </>
  )
}

export default Favorite;