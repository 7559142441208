import React from 'react'
import MyOrderData from './myorderdata.jsx';
import MyOrdersRowListTitle from './title.jsx';
import ReactPaginate from 'react-paginate';
import { useState } from 'react';
import { useEffect } from 'react';
import Paginate from '../../proudect/review/paginate.jsx';

function MyOrdersRowList(props) {
  const { Data} = props;





  return (
    <>
          {Data.map(item =>
            <div className="myorders__rowlist__item" key={item.id}>
              <MyOrdersRowListTitle Day={item.date} OrderNumber={item.serial} />
              <MyOrderData Item={item} />
            </div>
          )}
    </>
  )
}

export default MyOrdersRowList;