import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import Iconnotfiction from "../../../../images/icon/icon_notfiction-wihet.png";
import IconLogo from "../../../../images/logo/logo.png";

import { useContext } from 'react';
import { TokenLate } from '../../../../api/index';
import { Authcontext } from '../../../../store/context';
import IconProfile from '../buyer/iconprofile';

function NavbarBottomSeller() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  let location = useLocation(),
    textlocation = location.pathname,
    previousrequestsseller = textlocation.includes("previousrequestsseller");

  return (
    <section className='navbar__seller__bottom'>
      <div className="container">
        <div className="navbottom">
          <div className="left">

            <IconProfile />

            {/*
            <NavLink to={`/notification/${1}`} className="icon_hart">
                  <img src={Iconnotfiction} alt="" />
                </NavLink>
              */}
          </div>
          <div className="medima">
            <NavLink to={"/"} className="navbar_seller">
              <span className='text'>{language === "en" ? "Home" : "الرئيسية"}</span>
              <span className='line'></span>
            </NavLink>
            <NavLink to={`/proudectseller/${1}`} className="navbar_seller">
              <span className='text'>{language === "en" ? "Products" : "المنتجات"}</span>
              <span className='line'></span>
            </NavLink>
            <NavLink to={`/currentrequestsseller/${1}`}
              className={previousrequestsseller === true ? "navbar_seller active" : "navbar_seller"}>
              <span className='text'>{language === "en" ? "Requests" : "الطلبات"}</span>
              <span className='line'></span>
            </NavLink>
          </div>
          <div className="right">
            <NavLink to={"/"} className="icon_logo">
              <img src={IconLogo} alt="" />
            </NavLink>
          </div>

        </div>
      </div>
    </section>
  )
}

export default NavbarBottomSeller;

