import React from 'react';
import IconEdit from '../../../../../images/icon/icon_edit.png';
import { NavLink } from 'react-router-dom';
import { Authcontext } from '../../../../../store/context';
import { useContext } from 'react';

function Personal(props) {
    const {Data}=props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    
  return (
    <>
    <div className="image">
        <img src={Data.image} alt={Data.name}  
         onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                    "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg";
            }} />
    </div>
    <div className="edite">
        <NavLink to={"/editeprofile"} className="btn-edite">
            <img src={IconEdit} alt="IconEdit" />
        </NavLink>
    </div>

    <form>
        <div className="form">
            <div className="inputdata">
                <label>{language === "ar" ? "الاسم" : "Name"}</label>
                <div className="inputitem">
                    <input type="text" disabled value={Data.name === null ? "": Data.name}  />
                </div>
            </div>
            <div className="inputdata">
                <label>{language === "ar" ? "رقم الجوال" : "Mobile Number"}</label>
                <div className="inputitem">
                    <input type="number" disabled value={Data.phone === null ? "": Data.phone} className='phone-input' />
                </div>
            </div>
            <div className="inputdata">
                <label>{language === "ar" ? "البريد إلكتروني" : "Email"}</label>
                <div className="inputitem">
                    <input type="email" disabled value={Data.email=== null ? "": Data.email} 
                   pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"/>
                </div>
            </div>
        </div>
    </form>
    
    </>
  )
}

export default Personal;