import React, { useEffect, useState, useContext } from 'react'
import { Authcontext } from '../../../store/context';
import { Api } from '../../../api';
import axios from 'axios';
import OTPInput from 'react-otp-input';
import TimerCode from '../../../components/navbar/navbarbottom/modal/outer/timercode';

function SellerCode() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;


  const [message, setMessage] = useState("");
  const [loadingbutton, setLoadingbutton] = useState(false);
  const [disabledinput, setDisabledinput] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [otp, setOtp] = useState('');


  useEffect(() => {
    setModalOpen(true)
  })



  const SendCode = async (otp, setMessage) => {
    var data = new FormData();
    data.append('code', otp);
    data.append('device_id', "granted");
    data.append('device_type', "web");
    data.append('forget_pass', 0);
    data.append('email_phone', JSON.parse(sessionStorage.getItem("emaillate")));
    data.append("verfiy_token", JSON.parse(sessionStorage.getItem("verfiy_token")));

    const options = {
      method: "POST",
      url: `${Api}activate-account`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        'Access-Control-Allow-Origin': '*',
      },
      data: data
    };
    axios(options)
      .then(function (response) {
        sessionStorage.removeItem("verfiy_token");
        sessionStorage.removeItem("emaillate");
        window.location.pathname = `/sellersingin`;
      })
      .catch(function (error) {
        setMessage(error.response.data.message)
      });
  };


  const onSubmit = () => {
    SendCode(otp, setMessage)
  }

  return (
    <section className='sellersingin'>
      <div className="container">

        <div className="sellercode__row">
          <div className='sellercode__header'>
            <h3>{language === "en" ? "Enter The Verification Code" : "إدخال كود التحقق"}</h3>
          </div>

          <div className="sellercode__form">


            <div className='otp__contner'>
              <OTPInput value={otp} onChange={setOtp} numInputs={4} renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props}
                  className={disabledinput === false ? "otp__input" : "otp__input otp__input__disabled"} />}
              />
            </div>



            <div className="sellercode__end">
              <span className="errorfiled">{message}</span>

              {ModalOpen === true ?
                <TimerCode initSeconds={60} setDisabledinput={setDisabledinput} setMessage={setMessage}
                  ModalOpen={ModalOpen} setModalOpen={setModalOpen} />
                : ""}


              {loadingbutton === false ?
                <button type="button" className={"btn button-login button-active"} onClick={onSubmit}>
                  {language === "en" ? "Confirmation" : "تأكيد"}
                </button>
                :
                <button type="button" className="btn button-login button-disabled">
                  <span className="text">{language === "en" ? "Loading" : "جار التحميل"}</span>
                  <span className="spinner"></span>
                </button>
              }

            </div>
          </div>



        </div>
      </div>


    </section>
  )
}

export default SellerCode;