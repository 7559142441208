import React, { useContext } from 'react';
import NotificationCard from './card';

function NotificationContant(props) {
  const {Data}=props;
  return (

        <div className="notification__row">
                {Data.map(item =>
                    <NotificationCard key={item.id} Item={item}/>
                )}
        </div>
  )
}

export default NotificationContant;

