import React, { useEffect } from 'react'
import { useContext } from 'react';
import { Authcontext } from '../../../../../../store/context';
import ModalHeader from '../../outer/modalheader';
import FormActive from './form/form';

function ModalActive(props) {
  const { ModalOpen ,setModalOpen} = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    
  return (
    <div
      className="modal fade modal-late hide"
      id="singin_active" aria-hidden="true"aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          
          <ModalHeader Title={language === "en" ? "Enter the verification code" : "إدخال كود التحقق"}
          Subtitle={""} Id={""} ButtonBack={"singinModal"} ButtonClose={"singin_active"} Modailstart={false} 
          ModalOpen={ModalOpen} setModalOpen={setModalOpen} />

           <FormActive ModalOpen={ModalOpen} setModalOpen={setModalOpen}/>

        </div>
      </div>
    </div>
  )
}

export default ModalActive;