import React from 'react'
import { Authcontext } from '../../../store/context';
import { useContext } from 'react';
import Iconstar from '../../../images/icon/Icon_star.png';

function RatingsTop(props) {
  const {Data}=props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (
    <div className='ratingscontent__top'>

      <div className="custmerdat">
        <h6>{language === "ar" ? "العملاء" : "Customers"}</h6>
        <p>{Data.rate_count}</p>
      </div>

      <div className="custmerdat">
        <h6>{language === "ar" ? "تقييم البائع" : "Seller Rating"}</h6>
        <p>
          <img src={Iconstar} alt="Iconstar" />
          <span>{Data.rate}</span>
        </p>
      </div>
    </div>
  )
}

export default RatingsTop