import React, { useEffect, useState } from 'react'
import MyOrdersDetailsContant from '../../../components/buyer/myordersdetails/contant/index.jsx';
import { useParams } from 'react-router';
import { GetOnlayOrders } from '../../../api/actions';
import Loading from '../../../layout/loading/loading.jsx';
import ProfileListbuyer from '../../../layout/profile/list/buyer/index.jsx';
import { TokenLate, UserIdlate } from '../../../api/index.js';

function MyOrdersDetails() {
  const { id } = useParams();
  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if(UserIdlate === 3 && TokenLate !== null  ){
      window.location.pathname = `/`
    }
  }, []);

  useEffect(() => {
    GetOnlayOrders(id,setData,setLoading);
  }, [loading]);


  return (
    <>
      {loading ===  false ? 
      <Loading/>
      :
    <section className='profile'>
        <div className="container">
            <div className="profile__data">
                <ProfileListbuyer />
                <MyOrdersDetailsContant Data={data}/>
            </div>
        </div>
    </section>
    }
  </>
  )
}

export default MyOrdersDetails;