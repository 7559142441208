import React ,{ useEffect ,useState}  from 'react'
import { GetDataCategories, GetDataPercentage } from '../../../api/actions';
import Loading from '../../../layout/loading/loading';
import FormAdd from '../../../components/seller/proudect/formadd';

function AddProudect() {
  
  const [getCategories, setGetCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState("");

  
  
  
  useEffect(() => {
    GetDataCategories(setLoading, setGetCategories);
    GetDataPercentage(setLoading, setPercentage)
    
  }, [loading]);
  

  return (

    <>
        {loading === false ?
        <Loading />
            :
        <section className='proudect'>
          <div className="container">
            <FormAdd getCategories={getCategories} Numberpercentage={+percentage}/>
          </div>
        </section>
            }
        </>
  )
}

export default AddProudect;