import React, { useContext, useEffect, useState } from 'react'
import { Authcontext } from '../../../../store/context';
import IconAdd from "../../../../images/icon/icon_add_proudect.png"
import IconSort from "../../../../images/icon/icon_filter.png"
import { GetDataCategories } from '../../../../api/actions';
import { useNavigate } from 'react-router';


function NavlistSeller(props) {
    let navigate = useNavigate();
    const { setSortdata, products } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    const [Categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        GetDataCategories(setLoading, setCategories)
    }, [loading]);



    const AddProudect=()=>{
        navigate(`/addproudect`)
    }

    return (
        <div className='proudectseller__navlistseller'>
            <button className="btn add-proudect" type='button' 
                        onClick={AddProudect}>
                <span className="image">
                    <img src={IconAdd} alt="IconAdd" />
                </span>
                <span className='text'>
                    {language === "en" ? "Add A New Product" : "إضافة منتج جديد"}
                </span>
            </button>


           
                <div className='sort'>
                    <a className="btn btn-sort" href="#" role="button"
                        id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src={IconSort} alt="IconSort" />
                        <span>{language === "ar" ? "تصنيف" : "Rating"}</span>
                    </a>

                    <>
                        {loading === false ? (
                            ""
                        ) : (
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <>
                                {Categories.map(item =>
                                    <li className="dropdown-item" onClick={() => setSortdata(item.id)} key={item.id}>
                                        {item.name}
                                    </li>
                                )}
                                <li className="dropdown-item" onClick={() => setSortdata("")}>
                                {language === "ar" ? "مشاهده الكل" : "View All"}
                                </li>
                            </>
                            </ul>
                        )}
                    </>
                </div>
        </div>
    )
}

export default NavlistSeller;