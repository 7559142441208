import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import MyaddressesContant from '../../../components/buyer/myaddresses/index.jsx';
import Loading from '../../../layout/loading/loading';
import { GetShippingAddresses } from '../../../api/actions.js';
import ProfileListbuyer from '../../../layout/profile/list/buyer/index.jsx';
import { TokenLate, UserIdlate } from '../../../api/index.js';

function MyAddresses() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(UserIdlate === 3 && TokenLate !== null  ){
      window.location.pathname = `/`
    }
  }, []);

  useEffect(() => {
    GetShippingAddresses(setData, setLoading)
  }, [loading]);

  return (
    <>
    {loading === false ?
      <Loading />
      :
    <section className='profile'>
        <div className="container">
            <div className="profile__data">
                <ProfileListbuyer/>
                <MyaddressesContant Data={data} setData={setData}/>
            </div>
        </div>
    </section>
      }
    </>
  )
}

export default MyAddresses;