import React from 'react'
import { useState } from 'react';
import ModalForgetPasswordOne from './forgetpassword/screenone/index.jsx';
import ModalForgetPasswordThree from './forgetpassword/screenthree/index.jsx';
import ModalForgetPasswordTwo from './forgetpassword/screentwo/index.jsx';
import ModalSingin from './singin/modalsingin.jsx';
import ModelSingup from './singup/modelsingup.jsx';
import ModalVerificationCode from './verificationcode/index.jsx';
import ModalActive from './singin/singinactive/index.jsx';

function Model() {
  const [ModalOpen, setModalOpen] =useState(false);
  return (
    <>
    <ModelSingup setModalOpen={setModalOpen}/>
    <ModalVerificationCode ModalOpen={ModalOpen} setModalOpen={setModalOpen}/>

    <ModalSingin setModalOpen={setModalOpen}/>
    <ModalActive ModalOpen={ModalOpen} setModalOpen={setModalOpen}/>

    <ModalForgetPasswordOne setModalOpen={setModalOpen}/>
    <ModalForgetPasswordTwo  ModalOpen={ModalOpen} setModalOpen={setModalOpen}/>
    <ModalForgetPasswordThree/>
    </>
  )
}

export default Model;