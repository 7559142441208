import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { Authcontext } from '../../store/context';
import ImageLogo from '../../images/logo/logo.png';

function FooterTop() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  
  return (
    <div className="footer__top">
      <ul>
        <li>
          <NavLink to={"/connectus"} >
            {language === "ar" ? "تواصل معنا" : "Connect Us"}
          </NavLink>
        </li>
        <li>
          <NavLink to={"/privacypolicy"} >
            {language === "ar" ? "سياسة الخصوصية" : "Privacy Policy"}
          </NavLink>
        </li>
        <li>
          <NavLink to={"/trems"} >
            {language === "ar" ? "الأحكام والشروط" : "Terms And Conditions"}
          </NavLink>
        </li>
        <li>
          <NavLink to={"/aboutus"} >
            {language === "ar" ? "نبذة عنا" : "About Us"}
          </NavLink>
        </li>
      </ul>


    <div className="logo"><img src={ImageLogo} alt="ImageLogo" /> </div>
    </div>
  )
}

export default FooterTop