import React from 'react';
import IconLatestrequests from "../../../../images/icon/icon_latestrequests.png";
import { Authcontext } from '../../../../store/context';
import { useContext } from 'react';
import LatestRequestsTable from './table';

function LatestRequests(props) {
    const {Data}=props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    return (
        <div className='homeseller__latestrequests'>
            <div className="title">
                <img src={IconLatestrequests} alt="IconLatestrequests" />
                <h3>{language === "en" ? "Latest Requests" : "أحدث الطلبات"}</h3>
            </div>
            
            <LatestRequestsTable Data={Data}/>
        </div>
    )
}

export default LatestRequests;