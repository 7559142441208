import React from 'react'
import { useState } from 'react';
import { GetDataPercentage, GetMyProduct } from '../../../api/actions';
import Loading from '../../../layout/loading/loading';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import FormEdite from '../../../components/seller/proudect/formedite';

function EditeProudect() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      GetMyProduct(id, setData, setLoading);
    }, [loading]);
  return (

    <>
      {loading === false ? (
        <Loading />
      ) : (

        <section className='proudect'>
          <div className="container">
            <FormEdite Data={data} Id={id} />
          </div>
        </section>
      )}
    </>
  )
}

export default EditeProudect;