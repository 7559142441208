import React, { useContext, useState } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import { Authcontext } from '../../../../../../../store/context.js';
import { InputCode } from '../../../outer/inputs.jsx';
import { Api, languagelate } from '../../../../../../../api/index.js';
import axios from 'axios';
import { ReSendCode } from '../../../../../../../api/actions.js';
import TimerCode from '../../../outer/timercode.jsx';
import OtpInput from 'react-otp-input';


function FormActive(props) {
  const { ModalOpen, setModalOpen } = props

  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [message, setMessage] = useState("");
  const [loadingbutton, setLoadingbutton] = useState(false);
  const [disabledinput, setDisabledinput] = useState(false);

  const Stringeemail = JSON.parse(sessionStorage.getItem("emaillate"));
  const [otp, setOtp] = useState('');


  const CheckCode = async (otp, setMessage) => {
    var data = new FormData();
    data.append('code', otp);
    data.append('device_id', "granted");
    data.append('device_type', "web");
    data.append('forget_pass', 0);
    data.append('email_phone', JSON.parse(sessionStorage.getItem("emaillate")));
    data.append("verfiy_token", JSON.parse(sessionStorage.getItem("verfiy_token")));


    const options = {
      method: "POST",
      url: `${Api}activate-account`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        'Access-Control-Allow-Origin': '*',
        "Accept-Language": `${languagelate === null ? 'ar' : languagelate}`,
      },
      data: data
    };
    await axios(options)
      .then(function (response) {
        setLoadingbutton(false)
        
        localStorage.setItem("tokenlate", JSON.stringify(response.data.data.user.token));
        localStorage.setItem("languagelate", JSON.stringify(response.data.data.user.lang));
        localStorage.setItem("usertypelate", JSON.stringify(response.data.data.user.user_type.id));
        localStorage.setItem("useridlate", JSON.stringify(response.data.data.user.id));
        
        sessionStorage.removeItem("verfiy_token");
        sessionStorage.removeItem("emaillate");

        document.getElementById("singinModal_close").click();
        window.location.reload();
      })
      .catch(function (error) {
        setLoadingbutton(false)
        setMessage(error.response.data.message)
      });
  };

  const onSubmit = () => {
    setLoadingbutton(true)
    CheckCode(otp, setMessage);
    setModalOpen(!ModalOpen)
  }

  const form = (props) => {
    return <form onSubmit={props.handleSubmit}>
      
      {Stringeemail === null ? "" :
        <div className="otp__data">
          {language === "en" ?
            <>
              <span className="text">Verification code has been sent to</span>
              <span className="email">{Stringeemail.slice(0, 3)}***{Stringeemail.slice(-3)}</span>
            </>
            :
            <>
              <span className="text">تم إرسال كود التحقق إلى</span>
              <span className="email">{Stringeemail.slice(0, 3)}***{Stringeemail.slice(-3)}</span>
            </>
          }
        </div>
      }
      <div className='otp__contner'>
        <OtpInput value={otp} onChange={setOtp} numInputs={4} renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} 
          className={disabledinput === false ? "otp__input" : "otp__input otp__input__disabled"} />}
        />
      </div>

      <span className="errorfiled">{message}</span>


      {ModalOpen === true ?
        <TimerCode initSeconds={60} setDisabledinput={setDisabledinput} setMessage={setMessage}
          ModalOpen={ModalOpen} setModalOpen={setModalOpen} />
        : ""}

      {loadingbutton === false ?
        <button type="submit" className={"btn button-login button-active"}>
          {language === "en" ? "Confirmation" : "تأكيد"}
        </button>
        :
        <button className="btn button-login button-disabled" type="button">
          <span className="text">{language === "en" ? "Loading" : "جار التحميل"}</span>
          <span className="spinner"></span>
        </button>
      }
    </form>
  }



  return (
    <div className="modal-body">
      <Formik
        initialValues={otp}
        onSubmit={onSubmit}
        render={form}
        validateOnChange={false}
        enableReinitialize={true}
        validateOnBlur={false}
      />

    </div>
  )
}


export default FormActive;
