import React, { useContext } from 'react';
import CardList from './cardlist';
import CardTop from './cardtop';
import { Authcontext } from '../../../../store/context';

function MyAddressesCard(props) {
    const {Isnotify,Item ,setData}=props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    
  return (
    <div className='myaddresses__card'>
        <ul>
            <CardTop Isnotify={Isnotify} Id={Item.id} setData={setData}/>
            <CardList Name={Item.user_name}
            Phone={Item.phone}
            Address={Item.address}/>
        </ul>
    </div>
  )
}

export default MyAddressesCard;
