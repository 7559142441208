import React from 'react'
import Slider from 'react-slick';

function ListImage(props) {
    const { Images } = props;
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        cssEase: "linear"
    };

    return (
        <>

            <Slider {...settings}>
                {Images.map((item,index) =>

                    <div className="image" key={item.id}>
                        <span className="top">
                            {Images.length}
                            /
                            {index+1}
                        </span>
                        <img src={item.image} alt="Imageproudect"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                    "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg";
                            }} />
                    </div>
                )}
            </Slider>
        </>
    )
}

export default ListImage;