import React, { useContext, useEffect, useState } from 'react';
import IconCartImage from "../../../../images/icon/icon_shopping-cart.png";
import { NavLink } from 'react-router-dom';
import { Authcontext } from '../../../../store/context';
import { GetcartIcon } from '../../../../api/actions';

function IconCart() {
    const authcontext = useContext(Authcontext);
    const cart = authcontext.cart;
    const setCart = authcontext.setCart;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        GetcartIcon(setLoading, setCart)
    }, [loading])

    return (

        <NavLink to={"/cart"} className="icon_cart">

            <>
                {loading === false ?
                    ""
                    :
                    <>
                        {cart.length === 0 ? "" :
                            <span>{cart.length}</span>
                        }
                    </>
                }
            </>

            <img src={IconCartImage} alt="" />
        </NavLink>
    )
}

export default IconCart