import { Rating } from "@mui/material";
import React, { useContext } from "react";
import { useState, useEffect } from "react";
import Paginate from "./paginate";
import { Authcontext } from "../../../../store/context";
import ReactPaginate from "react-paginate";

function CustomerReview(props) {
  const { Data, Proudectpage, Title } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemPerpage = 3;
  const Arrayraiting = [];

  for (let index = 0; index < Data.length; index++) {
    Arrayraiting.push(Data[index].rate);
  }

  useEffect(() => {
    const endOffest = itemOffset + itemPerpage;
    setCurrentItems(Data.slice(itemOffset, endOffest));
    setPageCount(Math.ceil(Data.length / itemPerpage));
  }, [itemOffset, itemPerpage, Data]);

  const handlePageClick = (e) => {
    const newOffest = (e.selected * itemPerpage) % Data.length;
    setItemOffset(newOffest);
  };

  let sumnumber = 0;

  const SumArray = (Array) => {
    const sumFunction = (a, b) => a + b;
    Array.forEach((rating) => {
      sumnumber = sumFunction(sumnumber, rating);
    });
  };
  SumArray(Arrayraiting);
  let ringenumber = sumnumber / 5;

  return (
    <div className="proudect__review">
      <h3>
        <span className="title">{Title}</span>
        <span className="number">({Data.length})</span>
      </h3>

      {Data.length <= 3 ?
        <div className="review_lest">
          {Proudectpage === true ?

            <>
              {Data.length === 0 ?
                ""
                :
                <div className="startlest">
                  {Data.length === 1 ?
                    <>
                      <span className="text">{sumnumber}</span>
                      <Rating name="read-only" value={sumnumber} readOnly />
                    </>
                    :
                    <>
                      <span className="text">{ringenumber}</span>
                      <Rating name="read-only" value={ringenumber} readOnly />
                    </>}
                  <span className="text">
                    {language === "ar"
                      ? `Based on ${Data.length} reviews`
                      : `بناء على ${Data.length} تقييمات`}
                  </span>
                </div>
              }
            </>


            : ""}
          <ul className="list">
            {Data.map((item) => (
              <li key={item.id}>
                <span className="top">
                  {item.author === null ?
                    <span className="image">
                      <img src="https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg" alt="user" />
                    </span>
                    :
                    <span className="image">
                      <span>
                        {item.author.substring(0, 1)}
                      </span>
                    </span>
                  }
                  <span className="content">
                    <span className="name">
                      {item.author === null ? "User" :
                        <>
                          {item.author}
                        </>
                      }
                    </span>

                    <Rating name="read-only" value={item.rate} readOnly />
                    {/*
                <span className="day">12/12/2012</span>
                 */}
                  </span>
                </span>

                <span className="bottom">{item.comment}</span>
              </li>
            ))}
          </ul>
        </div>
        : (
          <div className="review_lest">
            {Proudectpage === true ? (
              <>
                {Data.length === 0 ? (
                  ""
                ) : (
                  <div className="startlest">
                    <span className="text">{ringenumber}</span>
                    <Rating name="read-only" value={ringenumber} readOnly />
                    <span className="text">
                      {language === "ar"
                        ? `Based on ${Data.length} reviews`
                        : `بناء على ${Data.length} تقييمات`}
                    </span>
                  </div>
                )}
              </>
            ) : (
              ""
            )}
            <ul className="list">
              {currentItems.map((item) => (
                <li key={item.id}>
                  <span className="top">
                    {item.author === null ?
                      <span className="image">
                        <img src="https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg" alt="user" />
                      </span>
                      :
                      <span className="image">
                        <span>
                          {item.author.substring(0, 1)}
                        </span>
                      </span>
                    }
                    <span className="content">
                      <span className="name">
                        {item.author === null ? "User" :
                          <>
                            {item.author}
                          </>
                        }
                      </span>
                      <Rating name="read-only" value={item.rate} readOnly />
                      {/*
                <span className="day">12/12/2012</span>
                 */}
                    </span>
                  </span>

                  <span className="bottom">{item.comment}</span>
                </li>
              ))}
            </ul>
            <div className="row">
              <div className="col-9">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  postsPerPage={2}
                  pageSize={1}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  renderOnZeroPageCount={null}
                />
              </div>
              <div className="col-3"></div>
            </div>
          </div>
        )}
    </div>
  );
}

export default CustomerReview;
