import React from 'react'
import { useLocation } from 'react-router';
import ModalFail from '../../../components/buyer/checkoutdone/modalfail';
import ModalSuccess from '../../../components/buyer/checkoutdone/modalsuccess';

function PaymentVisa() {
    let location = useLocation(),
        textlocation = location.pathname,
        paymentvisa = textlocation.includes("paymentvisa"),
        paymentvisaerrror = textlocation.includes("paymentvisaerrror");
    return (
        <>
                <section className='checkout'>
                    {
                        paymentvisaerrror === true ?
                            <ModalFail />
                            :
                            paymentvisa === true ?
                                <ModalSuccess />
                                : ""}
                </section>
        </>
    )
}

export default PaymentVisa;