import React, { useContext, useState } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import { Authcontext } from '../../../../../../../store/context.js';
import { Inputchangepassword, InputPassword } from '../../../outer/inputs.jsx';
import { Api , languagelate } from '../../../../../../../api/index.js';
import axios from 'axios';

function FormForgetPasswordThree() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [message, setMessage] = useState("");
  const state = {
    password: "",
    password_confirmation: "",
  };

  const Actionmodail = () => {
    document.getElementById(`modalforgetpasswordthree`).style.display = "none";
    document.getElementById(`modalforgetpasswordthree`).classList.remove('show');
    localStorage.removeItem("tokenlatepass");
    window.location.reload();
  }

  
  const Chingepassword = async (values, setMessage) => {
    var data = new FormData();
    data.append('password', values.password);
    data.append('password_confirmation', values.password_confirmation);
    const options = {
      method: "POST",
      url: `${Api}reset-password`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        'Access-Control-Allow-Origin': '*',
        "Accept-Language": `${languagelate === null ? 'ar' : languagelate}`,
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("tokenlatepass"))}`,
        
      },
      data: data
    };
    await axios(options)
      .then(function (response) {
        localStorage.setItem("tokenlate", JSON.stringify(response.data.data.user.token));
        localStorage.setItem("languagelate", JSON.stringify(response.data.data.user.lang));
        localStorage.setItem("usertypelate", JSON.stringify(response.data.data.user.user_type.id));
        localStorage.setItem("useridlate", JSON.stringify(response.data.data.user.id));
        Actionmodail();
      })
      .catch(function (error) {
        setMessage(error.response.data.message)
      });
  };


  const onSubmit = (values) => {
    Chingepassword(values, setMessage);
  }

  const form = (props) => {
    return <form onSubmit={props.handleSubmit}>
      <InputPassword errors={props.errors} />
      <Inputchangepassword errors={props.errors} />



      <span className="errorfiled">{message}</span>
      <button type="submit"
        className={"btn button-login button-active"} >
        {language === "en" ? "Confirmation" : "تأكيد"}
      </button>
    </form>
  }


  const schema = () => {
    const schema = Yup.object().shape({
      password: Yup.string()
      .min(6, 'Password Must Not Be Less Than 6 Characters')
      .max(10, 'Password Must Not Be More Than 10 Characters')
      .required('Password Is Required'),
      password_confirmation: Yup.string()
      .min(6, 'Password Must Not Be Less Than 6 Characters')
      .max(10, 'Password Must Not Be More Than 10 Characters')
      .required('Password Is Required'),

    });
    return schema;
  }

  return (
    <div className="modal-body">
      <Formik
        initialValues={state}
        onSubmit={onSubmit}
        render={form}
        validationSchema={schema()}
        validateOnChange={false}
        enableReinitialize={true}
        validateOnBlur={false}
      />

    </div>
  )
}


export default FormForgetPasswordThree;
