import React from 'react'
import { TokenLate, UserTypelate } from '../../api/index.js'
import HomeSeller from '../../pages/seler/home/index.jsx'
import HomeBuyer from '../../pages/buyer/home/index.jsx'
import { useState } from 'react';
import cookie from 'react-cookies'

function Home() {
    const cookieall = cookie.loadAll();
    //console.log(cookieall.tokenlate);
    //console.log(cookieall.usertypelate);
    // cookie.remove('userId', { path: '/' })
    return (
        <>
            {UserTypelate === 3 && TokenLate !== null ?
                <HomeSeller /> : <HomeBuyer />}
        </>
    )
}

export default Home