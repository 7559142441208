import { ErrorMessage, Field } from 'formik';
import React, { useState } from 'react';
import PhoneInput from "react-phone-input-2";

export const PhoneInputField = ({ field, form, ...props }) => {
    return (
      <div className="input-field">
        <PhoneInput
          placeholder="Enter phone number"
          name={field.name}
          country={'sa'}
          value={field.value}
          onChange={value => {
            if (!form.touched[field.name]) form.setFieldTouched(field.name);
            form.setFieldValue(field.name, value);
          }}
        />
      </div>
    );
  };
export function InputPhonecountry(props) {
const { Error, valueInput, Name, Label, Type } = props;
return (
    <div className={Error ? "input_form position-relative" : "input_form"}>
    <label>{Label}</label>
    <Field placeholder={Label} type={Type} name={Name} value={valueInput} component={PhoneInputField}
        className={Error ? "form-control is-invalid form-phone" : "form-control form-phone"} />
    <ErrorMessage name={Name} component="div" className='errorfiled' />
    </div>
)
};




export function InputPassword(props) {
    const { Error, valueInput, Label, Name, Type } = props;
    const [toggle, setToggle] = useState(false);
  
    return (
      <div className={Error ? "input_form position-relative" : "input_form"}>
        <label className="form-label">{Label}</label>
  
        <div className="position-relative">
  
          <Field type={toggle === false ? Type : "text"} name={Name}
            className={Error ? "form-control is-invalid" : "form-control"} 
            placeholder={Label} valie={valueInput} />
  
  
          <span className='toggoleimg' onClick={() => setToggle(!toggle)}>
            {toggle === false ?
              <img src="/assets/icons/icon-invisible.svg" alt="Invisible" 
              className={Error ? "hide invisible_img" : "invisible_img"} />
              :
              <img src="/assets/icons/icon-eye-regular.svg" alt="Visible" 
              className={Error ? "hide invisible_img" : "invisible_img"} />
            }
          </span>
  
        </div>
  
        <ErrorMessage name={Name} component="div" className='errorfiled' />
      </div>
  
    )
  };