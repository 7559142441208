import React from 'react';
import SellerSinginrow from "../../../components/seller/auth/singin/index.jsx"

function SellerSingin() {
  return (
    <section className='sellersingin'>
      <div className="container">
        <SellerSinginrow/>
      </div>
    </section>
  )
}

export default SellerSingin;