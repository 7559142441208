import React, { useContext } from 'react'
import { Authcontext } from '../../../store/context';

function OffersScreen() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div className='offers__screen'>
        <h6>{language === "ar" ? "العروض" : "Offers"}</h6>
    </div>
  )
}

export default OffersScreen;