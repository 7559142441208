import React, { useContext, useState } from 'react'
import { Authcontext } from '../../../../store/context';
import swal from 'sweetalert';
import { TokenLate, languagelate, Api } from '../../../../api';
import axios from 'axios';

function Detiles(props) {
    const { ordernumber, dateorder, Orderstatus, Id } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const [status, setStatus] = useState(Orderstatus === "Canceled" ||
        Orderstatus === "Refunded" ? `canceled` :
        Orderstatus === "Shipped" ? "shipped" :
        Orderstatus === "Delivered" ? "delivered" :
        Orderstatus === "Created" ? "created" : "in_progress");

    const ChingestatusOrderItem = async (Id, value) => {
        setStatus(value)
        var data = new FormData();
        data.append('status', value);

        const options = {
            method: "post",
            url: `${Api}order-items/${Id}`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "Retry-After": 3600,
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${TokenLate}`,
                "Accept-Language": `${languagelate === null ? 'ar' : languagelate}`,
            },
            data
        };
        await axios(options)
            .then(function (response) {
                swal({ text: response.data.message, icon: "success", buttons: false, timer: 3000 });
            })
            .catch(function (error) {
                swal({ text: error.response.data.message, icon: "info", buttons: false, timer: 3000 })
            });
    }

    const Chingestatus = (value) => {

        if (value === "canceled") {
            swal(language === "en" ? "Do you want to confirm the cancellation of this order ?" : "هل تريد تأكيد إلغاء هذا الطلب ؟",
                {
                    buttons: {
                        cancel: language === "en" ? "Cancel" : "إلغاء",
                        catch: { text: language === "en" ? "Confirm" : "تأكيد", value: "canceled", },
                    },
                })
                .then((value) => {
                    switch (value) {
                        case "canceled":
                            ChingestatusOrderItem(Id, value);
                            break;
                        default:
                            return false;
                    }
                });
        } else {
            ChingestatusOrderItem(Id, value)
        }
    }
    return (
        <div className='detiles'>
            <div className="item">
                <div className="top">{language === "ar" ? "رقم الطلب" : "Order Number"}</div>
                <div className="bottom">{ordernumber}</div>
            </div>
            <div className="item">
                <div className="top">{language === "ar" ? "تاريخ الطلب" : "The date of application"}</div>
                <div className="bottom">{dateorder}</div>

            </div> <div className="item">
                <div className="top">{language === "ar" ? "حالة الطلب" : "Order status"}</div>
                <div className="bottom">


                    <select value={status} className={`${status} orderstatus`} onChange={e => Chingestatus(e.target.value)}>

                        <option value="created" disabled={status === "created" ? false : true}>
                            {language === "ar" ? "تم الأنشاء" : "Created"}
                        </option>

                        <option value="in_progress"
                            disabled={status === "created" || status === "in_progress" ? false : true}>
                            {language === "ar" ? "قيد التنفيذ" : "Underway"}
                        </option>

                        <option value="shipped"
                            disabled={status === "shipped" || status === "in_progress" ? false : true}>
                            {language === "ar" ? "تم الشحن" : "Charged"}
                        </option>

                        <option value="delivered"
                            disabled={status === "shipped" || status === "delivered" ? false : true}>
                            {language === "ar" ? "تم التسليم" : "Delivered"}
                        </option>

                        <option value="canceled">{language === "ar" ? "تم الإلغاء" : "Canceled"}</option>
                    </select>
                </div>
            </div>

        </div>
    )
}

export default Detiles;