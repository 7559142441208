import React, { useState } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import { InputPassword, InputSinginEmail } from '../../outer/inputs.jsx';
import { useContext } from 'react';
import axios from 'axios';
import { Authcontext } from '../../../../../../store/context.js';
import { Api, languagelate } from '../../../../../../api/index.js';
import cookie from 'react-cookies'

function FormSingIn(props) {
  const { setModalOpen } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [message, setMessage] = useState("");
  const [messagestatus, setMessagestatus] = useState("");
  const state = {
    email: "",
    password: "",
  };

  const SignIn = async (values, setMessage) => {
    var data = new FormData();
    data.append('email_phone', values.email);
    data.append('password', values.password);
    data.append('device_id', "granted");
    data.append('device_type', "web");

    const options = {
      method: "POST",
      url: `${Api}login`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        'Access-Control-Allow-Origin': '*',
        "Accept-Language": `${languagelate === null ? 'ar' : languagelate}`,
      },
      data: data
    };
    axios(options)
      .then(function (response) {
        if (response.data.data.user.is_email_verified === false && response.data.data.user.token === "") {
          setMessagestatus("is_email_verified")
          sessionStorage.setItem("emaillate", JSON.stringify(values.email));
          sessionStorage.setItem("verfiy_token", JSON.stringify(response.data.data.user.verfiy_token));
          setMessage(response.data.message)

        } else if (response.data.data.user.is_phone_verified === false && response.data.data.user.token === "") {

          setMessagestatus("is_phone_verified")
          sessionStorage.setItem("emaillate", JSON.stringify(values.email));
          sessionStorage.setItem("verfiy_token", JSON.stringify(response.data.data.user.verfiy_token));
          setMessage(response.data.message)

        } else {
          localStorage.setItem("tokenlate", JSON.stringify(response.data.data.user.token));
          localStorage.setItem("languagelate", JSON.stringify(response.data.data.user.lang));
          localStorage.setItem("usertypelate", JSON.stringify(response.data.data.user.user_type.id));
          localStorage.setItem("useridlate", JSON.stringify(response.data.data.user.id));
          document.getElementById("singinModal_close").click();

         cookie.save('tokenlate', response.data.data.user.token, { path: '/' })
         cookie.save('usertypelate', response.data.data.user.user_type.id, { path: '/' })


          window.location.reload();
        }
      })
      .catch(function (error) {
        // console.log(error.response.data.message);
        setMessage(error.response.data.message)
      });
  };

  const onSubmit = (values) => {
    SignIn(values, setMessage);
  }
  const OpenSingUp = () => {
    document.getElementById("singinModal_close").click();
  }

  const OpenSellersingin = () => {
    window.location.pathname = `/sellersingup`;
  }


  const OpenmodalActive = () => {
    document.getElementById(`singinModal`).classList.remove('show');
    document.getElementById(`singinModal`).style.display = "none";
    document.getElementById(`singin_active`).classList.add('show');
    document.getElementById(`singin_active`).style.display = "block";
    setModalOpen(true)
    setMessage("")
    setMessagestatus("")
  }


  const form = (props) => {
    return <form onSubmit={props.handleSubmit}>
      <InputSinginEmail errors={props.errors} values={props.values} />
      <InputPassword errors={props.errors} singin={true} />
      <span className="errorfiled">

        {messagestatus === "is_email_verified" ?
          <span className="link">
            {language === "en" ?
              <>
                {message}{" - "}To Activate The Email{" "}
                <button className="btn btnlogin" type='button' role="button"
                  onClick={OpenmodalActive}>From Here</button>
              </> :
              <>
                <button className="btn btnlogin" type='button' role="button"
                  onClick={OpenmodalActive}>من هنا</button> {" "}
                {message}{" - "}لتفعيل البريد الالكتروني
              </>
            }
          </span>
          : messagestatus === "is_phone_verified" ?
            <span className="link">
              {language === "en" ?
                <>
                  {message}{" - "}To Activate The Phone Number{" "}
                  <button className="btn btnlogin" type='button' role="button"
                    onClick={OpenmodalActive}>From Here</button>
                </> :
                <>
                  <button className="btn btnlogin" type='button' role="button"
                    onClick={OpenmodalActive}>من هنا</button> {" "}
                  {message}{" - "} لتفعيل رقم الهاتف
                </>
              }
            </span>
            :
            <span className="link">{message}</span>
        }
      </span>
      <button type="submit"
        className={"btn button-login button-active"} >
        {language === "en" ? "Sign In" : "تسجيل الدخول"}
      </button>
    </form>
  }


  const schema = () => {
    const schema = Yup.object().shape({
      email: Yup.string().required("Email Is Required"),
      password: Yup.string().required('Password Is Required'),
    });
    return schema;
  }

  return (
    <div className="modal-body">
      <Formik
        initialValues={state}
        onSubmit={onSubmit}
        render={form}
        validationSchema={schema()}
        validateOnChange={false}
        enableReinitialize={true}
        validateOnBlur={false}
      />



      <div className='end'>
        <div className='first'>
          {language === "en" ? "Don't have an account?" : "لا تملك حساب ؟"}
          {" "}
          <button className="btn btnlogin"
            data-bs-toggle="modal"
            href="#singupModal"
            role="button" onClick={OpenSingUp}
          >{language === "en" ? "Create User account ?" : "حساب مستخدم ؟"}</button>
        </div>

        <div className="sacend">
          <button type='button' className={"btn open_sellersingin"} onClick={OpenSellersingin}>
            {language === "en" ? "Merchant account ?" : "حساب تاجر ؟"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default FormSingIn;