import React, { useContext } from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { ApplyCoupon } from '../../../../api/actions';
import { Authcontext } from '../../../../store/context';

function CartData(props) {
  const { Proudectlentgh, Price, Shipping, Totle, Orderid, Item } = props;
  let navigate = useNavigate();
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [couponnumber, setCouponnumber] = useState(Item.coupon_code === null ? '' : Item.coupon_code);
  const [message, setMessage] = useState("");
  const [totalcopune, setTotalcopune] = useState("");
  const [discountcopune, setDiscountcopune] = useState("");
  

  const [coponeactive, setCponeactive] = useState(true);

  const Checkoutgo = () => {
    navigate(`/checkoutaddress/${Orderid}`);
  }

  const SendCoupon = () => {
    ApplyCoupon(couponnumber, setMessage, setCponeactive, setTotalcopune,setDiscountcopune)
  }
  return (
    <div className='cart__data'>
      <h6>{language === "ar" ? "ملخص الطلبية" : "Order Summary"}</h6>
      <div className="input-group-last">

        <input type="text" className="form-control"
          placeholder={language === "ar" ? "ادخل الكود" : "Enter The Code"}
          onChange={(e) => setCouponnumber(e.target.value)}
          value={couponnumber || ''} />
        <button type="button" className={"btn"}onClick={SendCoupon}>
          {language === "ar" ? "تطبيق" : "Running"}
        </button>

      </div>
      {message === "" ? "" :
        <>
          {coponeactive === true ?
            <span className="errorfiled filedtrue">{message}</span> :
            <span className="errorfiled">{message}</span>
          }
          <br />
        </>
      }

      <ul className='list'>
        <li>
          <span className='left'>
            <span>{language === "ar" ? "المجموع الفرعي" : "Subtotal"} </span>

            <span>{language === "ar" ? `(${Proudectlentgh} منتجات)` : `(${Proudectlentgh} products)`}  </span>
          </span>
          <span className="right">
            {`SR ${Price}`}</span>
        </li>
        {Item.coupon_code === null ? "" :
          <li>
            <span className='left'>
              <span>{language === "ar" ? "قيمه الخصم" : "Discount Value"} </span>
            </span>
            {discountcopune === "" ?
              <span className="right">{`SR ${Item.discount}`}</span>
              :
              <span className="right">{`SR ${discountcopune}`}</span>
            }
          </li>
        }


        <li>
          <span className='left'>
            <span>{language === "ar" ? "الشحن" : "Shipping"} </span>
          </span>
          <span className="right">{Shipping}</span>
        </li>
        <li className="line"></li>
        <li className='end'>
          <span className='left'>
            <span>{language === "ar" ? "المجموع" : "Total"} </span>
            <span>{language === "ar" ? `(يشمل ضريبة القيمة المضافة)` : `(including VAT)`}  </span>
          </span>

          {totalcopune === "" ?
            <span className="right">{Totle}</span>
            :
            <span className="right">{`SR ${totalcopune}`}</span>
          }
        </li>
      </ul>
      <button className='btn btn-checkout' type='button' onClick={Checkoutgo}>
        {language === "ar" ? "إتمام الشراء" : "Checkout"}
      </button>
    </div>
  )
}

export default CartData;