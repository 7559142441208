import React from 'react'
import CartCard from '../card';

function CartRowList(props) {
    const { CartItem,setData,setItemsdata } = props;
    console.log(CartItem);
    
    return (
        <div className="cart__rowlist">
            {CartItem.map(item =>
                <CartCard  SellerName={""} key={item.id} Item={item} setData={setData} setItemsdata={setItemsdata}/>
            )}
        </div>
    )
}

export default CartRowList;