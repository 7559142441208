import React from 'react'

function Privacypolicydata(props) {
    const {Title , Data}=props
    return (
        <div className="privacypolicy__data">
            <h6>{Title}</h6>
            <p>{Data}</p>
        </div>
    )
}

export default Privacypolicydata