import { Switch } from '@mui/material';
import React, { useContext } from 'react'
import { Authcontext } from '../../../../store/context';

function FormMap(props) {
  const { notify, setNotify, addressdetails
    , setAddressdetails, phone
    , setPhone,firstname
    ,setFirstname,familyname
    ,setFamilyname } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;




  const handleChangeSwitch = () => {
    setNotify(!notify);
  }

  const handleChange = (e) => {
    const value = e.target.value;

  };

  return (
    <div className='formmap'>
      <h3 className='information'>
        {language === "ar" ? "معلومات الموقع" : "Location information"}
      </h3>
      <div className="input_form">
        <label>{language === "en" ? "Additional address details" : "تفاصيل العنوان الإضافية"}</label>
        <input type="text" className="form-control" name="addressdetails"
          placeholder={language === "en" ? "Building number, road number, apartment number" : "رقم المبنى , رقم الطريق , رقم الشقة"}
          onChange={(e) => setAddressdetails(e.target.value)}
          value={addressdetails || ''} />
      </div>
      <div className="input_form">
        <label>{language === "en" ? "Mobile number" : "رقم الجوال"}</label>
        <input type="number" className="form-control" name="phone"
          placeholder={"54 454 1704"}
          onChange={(e) => setPhone(e.target.value)}
          value={phone || ''} />
      </div>
      <h3 className='information'>
        {language === "ar" ? "معلوماتك الشخصية" : "Your personal information"}
      </h3>

      <div className="row">
        <div className="col-6">
          <div className="input_form">
            <label>{language === "en" ? "First Name" : "الاسم الأول"}</label>
            <input type="text" className="form-control" name="firstname"
              placeholder={language === "en" ? "First Name" : "الاسم الأول"}
              onChange={(e) => setFirstname(e.target.value)} 
              value={firstname || ''} />
          </div>

        </div>
        <div className="col-6">
          <div className="input_form">
            <label>{language === "en" ? "Family Name" : "اسم العائلة"}</label>
            <input type="text" className="form-control" name="familyname"
              placeholder={language === "en" ? "Family Name" : "اسم العائلة"}
              onChange={(e) => setFamilyname(e.target.value)} 
              value={familyname || ''} />
          </div>
        </div>
      </div>
      <span className='icon_switchnotify'>
        <span
          className={notify === false ? "text" : "text active"}>{
            language === "ar" ? "تعيين العنوان الافتراضي" : "Set the default address"}</span>
        <Switch onChange={handleChangeSwitch} checked={notify} width={43} height={24} />
      </span>

    </div>
  )
}

export default FormMap;