import React, { useState } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import { useContext } from 'react';
import axios from 'axios';
import { Authcontext } from '../../../../../../../store/context.js';
import { InputSinginEmail } from '../../../outer/inputs.jsx';
import { Api, languagelate } from '../../../../../../../api/index.js';

function FormForgetPasswordOne(props) {
  const { setModalOpen} = props;


  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [message, setMessage] = useState("");
  const state = {
    email: "",
  };


  const Actionmodail = () => {
    document.getElementById(`modalforgetpasswordone`).style.display = "none";
    document.getElementById(`modalforgetpasswordone`).classList.remove('show');
    document.getElementById(`modalforgetpasswordtwo`).classList.add('show');
    document.getElementById(`modalforgetpasswordtwo`).style.display = "block";
    setModalOpen(true)
    setMessage("")
  }


  const SendCode = async (values, setMessage) => {
    
    sessionStorage.setItem("emaillate", JSON.stringify(values.email));

    var data = new FormData();
    data.append('email_phone', values.email);
    data.append('forget_pass', 1);

    const options = {
      method: "POST",
      url: `${Api}send-code`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        'Access-Control-Allow-Origin': '*',
        "Accept-Language": `${languagelate === null ? 'ar' : languagelate}`,
      },
      data: data
    };
    axios(options)
      .then(function (response) {
        sessionStorage.setItem("verfiy_token", JSON.stringify(response.data.data.verfiy_token));
        Actionmodail();
      })
      .catch(function (error) {
        setMessage(error.response.data.message)
      });
  };
  const onSubmit = (values) => {
    SendCode(values, setMessage);
  }
  const form = (props) => {
    return <form onSubmit={props.handleSubmit}>
      <InputSinginEmail errors={props.errors} values={props.values} />

      <span className="errorfiled">{message}</span>
      <button type="submit"
        className={"btn button-login button-active"} >
        {language === "en" ? "Send verification code" : "إرسال كود التحقق"}
      </button>
    </form>
  }


  const schema = () => {
    const schema = Yup.object().shape({
      email: Yup.string().required("Email Is Required"),
    });
    return schema;
  }

  return (
    <div className="modal-body">
      <Formik
        initialValues={state}
        onSubmit={onSubmit}
        render={form}
        validationSchema={schema()}
        validateOnChange={false}
        enableReinitialize={true}
        validateOnBlur={false}
      />

    </div>
  )
}

export default FormForgetPasswordOne;
