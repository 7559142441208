import React, { useContext } from 'react'
import MyOrderCard from '../card';
import ShipingeDetiles from './shipingedetiles';

function MyOrderData(props) {
    const { Item } = props;
    const order_items = Item.orderItemProducts;

    return (
        <>
            <ShipingeDetiles DeliveryNumber={Item.shipping_num} Item={Item} />


            {order_items.product === null ? "" : <>
                {order_items.map(order =>
                    <div key={order.id} className='order'>
                        <MyOrderCard Title={order.product.title} Color={order.color.name}
                            Imageproudect={order.product.media.length === 0 ?
                                "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg"
                                : order.product.media[0].image}
                            Size={order.size.name} Quantity={order.quantity}
                            Price={`SR ${order.price}`} Item={order} Provider={order.provider} />


                    </div>

                )}
            </>}



        </>
    )
}

export default MyOrderData;
/*

                                    
*/