import React from 'react';
import { NavLink } from 'react-router-dom';
import FaceBookIcon from '../../images/icon/facebook.png';
import TwitterIcon from '../../images/icon/twitter.png';
import InstagremIcon from '../../images/icon/instagram.png';
import { useState } from 'react';
import { useEffect } from 'react';
import { GetDataSettings } from '../../api/actions';


function FooterBottom() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataSettings(setData, setLoading)
  }, [loading]);


  return (


    <>
      {loading === false ? "" :
        <div className="footer__bottom">


          {data.map(item =>
            <a href={item.link} target="_blank" key={item.id}>
              <img src={item.image} alt={item.title} />
            </a>
          )}
        </div>
      }
    </>
  )
}

export default FooterBottom;