/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useContext } from "react";
import { Authcontext } from "../../../../store/context";

function Iconlogin() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (
    <>

        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        
          <li className="dropdown-item">
            <button
              type="button"
              className="btn"
              data-bs-toggle="modal"
              href="#singupModal"
              role="button"
            > 
            {language === "ar" ? "إنشاء حساب" : "Create An Account" } 
            </button>
          </li>

          <li className="dropdown-item">/</li>
          <li className="dropdown-item">
            <button
              className="btn"
              data-bs-toggle="modal"
              href="#singinModal"
              role="button"
            >
            {language === "ar" ? "تسجيل الدخول" : "Sign In" }  
            </button>
          </li>
        </ul>

    </>
  );
}

export default Iconlogin;


