import React, { useState } from 'react'
import CoverProudect from './coverproudect';
import ListImage from './listimage';
import DataProudect from './dataproudect';

function ProudectContent(props) {
  const {Data}=props;
  const [index, setindex] = useState(0);
  const Images=[...Data.media];
  return (
    <div className='proudect__content'>
        <ListImage Images={Images} setindex={setindex}/>
        <CoverProudect ItemImage={Images} Index={index}/>
        <DataProudect Data={Data}/>
    </div>
  )
}

export default ProudectContent;