import React, { useEffect, useState } from 'react'
import ProfileContant from '../../../components/buyer/profile/contant/index.jsx';
import NavListProfile from '../../../components/buyer/profile/navlist/index.jsx';
import { GetDataProfile } from '../../../api/actions.js';
import ProfileListSeller from '../../../layout/profile/list/seller/index.jsx';
import Loading from '../../../layout/loading/loading.jsx';
import FormTwo from "../../../components/seller/storedata/formtwo.jsx";
import FormThree from "../../../components/seller/storedata/formthree.jsx";
import FormFour from '../../../components/seller/storedata/formfour.jsx';
import FormFive from '../../../components/seller/storedata/formfive.jsx';
import IconEdit from '../../../images/icon/icon_edit.png';
import { NavLink } from 'react-router-dom';


function StoreData() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stutes, setStutes] = useState(2);

    useEffect(() => {
        GetDataProfile(setData, setLoading)
    }, [loading]);

    return (
        <>
            {loading === false ?
                <Loading />
                :
                <section className='profile'>
                    <div className="container">
                        <div className="profile__data">
                            <>
                                <ProfileListSeller Id={data.id} />
                                <div className='profile__data__rowcoumn'>
                                    <NavListProfile stutes={stutes} setStutes={setStutes} />


                                    <div className='profile__form'>

                                        <div className="edite">
                                            <NavLink to={"/editestoredata"} className="btn-edite">
                                                <img src={IconEdit} alt="IconEdit" />
                                            </NavLink>
                                        </div>



                                        {stutes === 3 ?
                                            <FormThree Data={data} setStutes={setStutes} />
                                            : stutes === 4 ?
                                                <FormFour Data={data} setStutes={setStutes} />
                                                : stutes === 5 ?
                                                    <FormFive Data={data} setStutes={setStutes} />
                                                    :
                                                    <FormTwo Data={data} setStutes={setStutes} />
                                        }
                                    </div>

                                </div>
                            </>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default StoreData;