import React, { useContext, useState } from 'react'
import Iconloction from '../../../../images/icon/icon_location.png';
import Switch from "react-switch";
import { Authcontext } from '../../../../store/context';
import { GetShippingAddresses, RemoveAddresses } from '../../../../api/actions';


function CardTop(props) {
    const { Isnotify ,Id ,setData} = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    const [notify, setNotify] = useState(Isnotify);
    const [loading, setLoading] = useState(false);
  


    const handleChangeSwitch = () => {
        setNotify(!notify);
    }
    const RemoveItemAddress=()=>{
        RemoveAddresses(Id);
        GetShippingAddresses(setData, setLoading)
    }
    return (
        <li className="top">
            <span className="left">
                <span className="icon">
                    <img src={Iconloction} alt="Iconloction" />
                </span>
                {notify === false ? "":
                <span className="text">
                    {language === "ar" ? "افتراضي" : "Default"}
                </span>
                }
            </span>

            <span className="right">
                <button className="btn icon_delete" type='button' onClick={RemoveItemAddress} >
                        {language === "ar" ? "حذف" : "Delete"}
                </button>

{/*
                <button className="btn icon_edite">
                    <img src={IconEditeAddress} alt="" />
                    <span className='text'>
                        {language === "ar" ? "تعديل" : "Edit"}
                    </span>
                </button>
                    */}

                <span className='icon_switchnotify'>
                    <span
                        className={notify === false ? "text" : "text active"}>{
                            language === "ar" ? "العنوان الافتراضي" : "Default Address"}</span>
                    <Switch onChange={handleChangeSwitch} checked={notify}  width={43} height={24} boxShadow={"none"} />
                </span>

            </span>
        </li>
    )
}

export default CardTop;