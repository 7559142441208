import React, { useContext } from 'react';
import IconHartImage from "../../../../images/icon/icon_heart.png";
import { NavLink } from 'react-router-dom';
import { Authcontext } from '../../../../store/context';

function IconHart() {
    const authcontext = useContext(Authcontext);
    return (
            
            <NavLink to={"/favorite"} className="icon_hart">
            <img src={IconHartImage} alt="" />
      </NavLink>
        
    )
}

export default IconHart;