import React, { useContext, useState } from "react";

import Invisible from "../../../../images/icon/invisible.svg";
import Visible from "../../../../images/icon/eye-regular.svg";
import ModelGallaryImage from "./modelimages";
import { Authcontext } from "../../../../store/context";
import IconPluse from "../../../../images/icon/plus-solid.svg";

export function InputName(props) {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const { handleChange, validation, value } = props;

  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>
        {language === "en" ? "Name" : "الاسم"}
      </label>
      <input
        type="text"
        required value={value}
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "Name" : "الاسم"}
        name="name"
        onChange={handleChange} />
      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
export function InputPhone(props) {
  const { handleChange, validation, value } = props;

  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>{language === "en" ? "Mobile Number" : "رقم الجوال"}</label>
      <input
        type="number"
        required value={value}
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        placeholder={"545561870"} name="phone"
        onChange={handleChange} />

      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  )
};
export function InputEmail(props) {
  const { handleChange, validation, value } = props;

  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>{language === "en" ? "E-mail" : "البريد الإلكتروني"}</label>
      <input type="email" required value={value}
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "E-mail" : "البريد الإلكتروني"} name="email" onChange={handleChange} />

      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  )
};
export function InputPassword(props) {
  const { handleChange, validation, value } = props;
  const [toggle, setToggle] = useState(false);

  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>{language === "en" ? "Password" : "كلمة المرور"}</label>

      <div className="position-relative">

        <input type={toggle === false ? "password" : "text"} value={value}
          className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
          placeholder={language === "en" ? "Password" : "كلمة المرور"}
          name="password" onChange={handleChange} />
        <span className='toggoleimg' onClick={() => setToggle(!toggle)}>
          {toggle === false ?
            <img src={Invisible} alt="Invisible"
              className={"invisible_img"}
            />
            :
            <img src={Visible} alt="Visible"
              className={"invisible_img"}
            />
          }
        </span>
      </div>

      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  )
};
export function InputStoreName(props) {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const { handleChange, validation, value } = props;

  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>
        {language === "en" ? "Store name" : "اسم المتجر"}
      </label>
      <input
        type="text"
        required value={value}
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "Store name" : "اسم المتجر"}
        name="storename"
        onChange={handleChange} />
      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
export function InputStorePhone(props) {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const { handleChange, validation, value } = props;

  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>
        {language === "en" ? "Store mobile number" : "رقم جوال المتجر"}
      </label>

      <input
        type="number"
        required value={value}
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        placeholder={"545561870"} name="storephone"
        onChange={handleChange} />


      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
export function InputStoreEmail(props) {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const { handleChange, validation, value } = props;

  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>
        {language === "en" ? "Store Email" : "البريد الإلكتروني الخاص بالمتجر"}
      </label>

      <input type="email" required value={value}
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "E-mail" : "البريد الإلكتروني"} name="storeemail" onChange={handleChange} />


      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
export function InputCities(props) {
  const { cities, setCities, Data, language, validation, setGetSubCaty } = props;

  const handleVlaue = (value) => {
    setCities(value);
    var aquaticCreatures = Data.filter(function (creature) {
      return creature.id == value;
    });
    setGetSubCaty(aquaticCreatures[0].regions)
  };

  if (cities !== "") {
    handleVlaue(cities);
  }

  return (
    <div className={validation === false && cities === "" ? "form-group selectbox position-relative" : "form-group selectbox"} >
      <label> {language === "en" ? "The city of the store" : "المدينة التابع لها المتجر"}</label>
      <select
        required
        className={validation === false && cities === "" ? "form-control is-invalid" : "form-control"}
        value={cities}
        onChange={(e) => handleVlaue(e.target.value)} >
        <option > </option>
        {Data.map((item, index) =>
          <option value={item.id} key={index} >
            {item.name}
          </option>
        )}
      </select>
      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>

  );
}
export function InputSubCities(props) {
  const { subcaty, setsubcaty, Data, language, validation } = props;

  const handleVlaue = (e) => {
    const value = e.target.value;
    setsubcaty(value);
  };

  return (
    <div
      className={validation === false && subcaty === "" ? "form-group selectbox position-relative" : "form-group selectbox"} >
      <label> {language === "en" ? "District" : "الحي"}</label>
      <select required
        className={validation === false && subcaty === "" ? "form-control is-invalid" : "form-control"}
        value={subcaty}
        placeholder={language === "en" ? "District" : "الحي"}
        onChange={handleVlaue} >
        <option > </option>
        {Data.map((item, index) =>
          <option value={item.id} key={index} >
            {item.name}
          </option>
        )}
      </select>
      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>

  );
}
export function InputCategory(props) {
  const { category, setCategory, Data, language, validation } = props;
  const handleVlaue = (e) => {
    const value = e.target.value;
    setCategory(e.target.value)
  };

  return (
    <div className={validation === false && category === "" ? "form-group selectbox position-relative" : "form-group selectbox"} >
      <label> {language === "en" ? "What types of products do you sell?" : "ماهى أنواع المنتجات التي تقوم ببيعها ؟"}</label>
      <select required
        className={validation === false && category === "" ? "form-control is-invalid" : "form-control"}
        value={category}
        onChange={handleVlaue} >
        <option > </option>
        {Data.map((item, index) =>
          <option value={item.id} key={index} >
            {item.name}
          </option>
        )}
      </select>
      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>

  );
}
export function InputDesstore(props) {
  const { handleChange, language, validation, value } = props;
  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>{language === "en" ? "About the store" : "نبذة عن المتجر"}</label>
      <textarea
        rows="3" value={value}
        placeholder={language === "en" ? "About the store" : "نبذة عن المتجر"}
        name="description_store"
        required
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        onChange={handleChange}
      ></textarea>
      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
export function InputFile(props) {
  const { validation, language, setFile, file, Title, DataFile } = props;
  const [serFile, SetserFile] = useState(null);

  const UpdateFile = (e) => {
    SetserFile(e.value)
    setFile(e.files[0])
    console.log(e.files[0]);
  }
  return (
    <div className={validation === false && file === null ? "companydata position-relative" : "companydata"}>

      <button type='button' className='btn btn-upload'>
        <input type="file" className="input-file" name="cr_files"
          accept="application/pdf"
          onChange={e => { UpdateFile(e.target) }} />
        <span >
          <img src={IconPluse} alt="IconPluse" />
          {Title}
        </span>
      </button>

      <button className='btn open-modal' data-bs-toggle="modal"
        data-bs-target={`#modelgallaryimage${1}`}>
        {language === "ar" ? "استعراض الملفات" : "Browse files"}
      </button>
      <ModelGallaryImage Data={DataFile} Id={1} />

      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );

}
export function InputNameUserBanck(props) {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const { handleChange, validation, value } = props;

  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>{language === "en" ? "Beneficiary Name" : "اسم المستفيد"}</label>
      <input
        type="text"
        required value={value}
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "Beneficiary Name" : "اسم المستفيد"}
        name="nameuserbanck"
        onChange={handleChange} />
      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
export function InputNameBanck(props) {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const { handleChange, validation, value } = props;

  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>
        {language === "en" ? "Bank name" : "اسم البنك"}
      </label>
      <input
        type="text"
        required value={value}
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "Bank name" : "اسم البنك"}
        name="namebanck"
        onChange={handleChange} />
      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
export function InputBranchName(props) {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const { handleChange, validation, value } = props;

  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>
        {language === "en" ? "Branch Name" : "اسم الفرع"}
      </label>
      <input
        type="text"
        required value={value}
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "Branch Name" : "اسم الفرع"}
        name="branchname"
        onChange={handleChange} />
      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
export function InputAccountNumber(props) {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const { handleChange, validation, value } = props;

  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>
        {language === "en" ? "Account Number" : "رقم الحساب"}
      </label>
      <input
        type="text"
        required value={value}
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "Account Number" : "رقم الحساب"}
        name="accountnumber"
        onChange={handleChange} />
      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
export function InputSwiftcode(props) {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const { handleChange, validation, value } = props;

  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>
        {language === "en" ? "Swift code" : "رمز السويفت"}
      </label>
      <input
        type="number"
        required value={value}
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "Swift code" : "رمز السويفت"}
        name="swiftcode"
        onChange={handleChange} />
      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
export function InputRegistrationNumber(props) {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const { handleChange, validation, value } = props;

  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>
        {language === "en" ? "Tax registration number" : "رقم التسجيل الضريبي"}
      </label>
      <input
        type="number"
        required value={value}
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "Tax registration number" : "رقم التسجيل الضريبي"}
        name="registrationnumber"
        onChange={handleChange} />
      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
export function InputCode(props) {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const { handleChange, validation, value } = props;

  return (
    <div className={validation === false && value === "" ? "form-group position-relative" : "form-group"} >
      <label>{language === "en" ? "Validation code" : "كود التحقق"}</label>
      <input
        type="number" required value={value} name="code"
        className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
        placeholder={language === "en" ? "Validation code" : "كود التحقق"}
        onChange={handleChange} />
      <div className="invalid-feedback">
        {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
export function InputPrice(props) {
  const { handleChange, language, validation, value, Name } = props;
  return (
    <div className="form-group">

      <label> {language === "en" ? "Price " : "السعر"} </label>

      <div className="price-relative">
        <input type="number" required value={value}
          className={validation === false && value === "" ? "form-control is-invalid" : "form-control"}
          placeholder={language === "en" ? "Price " : "السعر"}
          name={Name} onChange={handleChange} />

        <span className="dataprice"> {language === "en" ? "s.r" : " ر.س"}</span>
      </div>

      <span className="shipping-span">{language === "en" ?
        "Please note that the shipping price will include the entire order and not the product separately *"
        : "يرجى العلم أن سعر الشحن سوف يشمل الطلب بالكامل وليس للمنتج بشكل منفصل"}
      </span>

      {validation === false && value === "" ?
        <div className="invalid-feedback">
          {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
        </div>
        : ""}
    </div>
  );
}