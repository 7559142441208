import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import ProfileListSeller from '../../../layout/profile/list/seller';
import Loading from '../../../layout/loading/loading';
import RatingsContent from '../../../components/seller/ratings';
import { GetMyRates } from '../../../api/actions';
import RatingsEmpty from '../../../components/seller/ratings/empty';

function Ratings() {
  const { id ,page } = useParams();
  const pagenumber = +page;
  const [perpage, setPerpage] = useState(0);
  const [pageCount, setpageCount] = useState(pagenumber);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    GetMyRates(id, setData, setLoading,setPerpage);
  }, [loading]);

  console.log(data);
  return (

    <>
      {loading === false ?
        <Loading />
        :
        <section className='profile'>
          <div className="container">
            <div className="profile__data">
              <>
                <ProfileListSeller Id={id} />
                {data.rates.length === 0 ?
                  <RatingsEmpty/>
                  :
                  <RatingsContent Data={data} Id={id} setpageCount={setpageCount}
                  perpage={perpage} pagenumber={pageCount}/>
                }
              </>
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default Ratings;

