import React, { useContext, useState } from "react";

import ModelGallaryImage from "./modelimages";
import { Authcontext } from "../../../store/context";


export function InputControl(props) {

  const { value, Title } = props;

  return (
    <div className={"form-group"} >
      <label>{Title}</label>
      <input disabled type="text" required value={value} className={"form-control"} />
    </div>
  );
}

export function InputTextarea(props) {
  const { Title, value } = props;
  return (
    <div className={"form-group"} >
      <label>{Title}</label>
      <textarea rows="3" disabled type="text" required value={value} className={"form-control"} ></textarea>
    </div>
  );
}

export function InputFile(props) {
  const { file,Title,language ,Id} = props;

  return (
    <div className={"companydata form-group"}>

      <label>{Title}</label>
      <br/>
      <br/>
      <button className='btn open-modal' data-bs-toggle="modal"
        data-bs-target={`#modelgallaryimage${Id}`}>
        {language === "ar" ? "استعراض الملفات" : "Browse files"}
      </button>
      <ModelGallaryImage Data={file} Id={Id} />
    </div>
  );

}
export function InputPrice(props) {
  const {  valueInput, Label, Name, Type, Placeholder, Datapricespan,ShippingText } = props;

  return (
    <div className={"form-group"}>
      <label>{Label}</label>

      <div className="price-relative">
        <input type={Type}  disabled placeholder={Placeholder} value={valueInput}
          className={"form-control"} name={Name} />
        <span className="dataprice">{Datapricespan}</span>
      </div>

    <span className="shipping-span">{ShippingText}</span>
    </div>
  )
};
