import React, { useContext } from 'react'
import { Authcontext } from '../../../../../store/context';
import ModalHeader from '../outer/modalheader';
import FormVerificationCode from './form/form';

function ModalVerificationCode(props) {
  const { ModalOpen ,setModalOpen} = props;


  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (
    <div
      className="modal fade modal-late"
      id="verificationcode"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <ModalHeader Title={language === "en" ? "Enter The Verification Code" : "إدخال كود التحقق"}
          Subtitle={""} Id={""} ButtonBack={"singupModal"} ButtonClose={"verificationcode"} Modailstart={false}
          ModalOpen={ModalOpen} setModalOpen={setModalOpen} />

           <FormVerificationCode ModalOpen={ModalOpen} setModalOpen={setModalOpen}/>
        </div>
      </div>
    </div>
  )
}

export default ModalVerificationCode;