import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import IconArrowBack from '../../../../images/icon/icon_arrow-right.png';
import { Authcontext } from '../../../../store/context';

function MyOrdersDetailsTop(props) {
  const {Status}=props;

    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div className='myordersdetails__top'>
        <NavLink to={Status === "Canceled" ? `/canceledrequests/${1}`: 
        Status === `Delivered` ? `/completedrequests/${1}` 
        :`/currentrequests/${1}`}>
          <img src={IconArrowBack} alt="IconArrowBack" />
          <span>{language === "ar" ? "رجوع" : "Back"}</span>
        </NavLink>
        
    </div>
  )
}

export default MyOrdersDetailsTop;