import React, { useContext } from 'react';
import ImageEmpty from '../../../../images/icon/icon_shoppingcart-empty.png';
import { Authcontext } from '../../../../store/context';
import { useLocation } from 'react-router';

function MyOrdersEmpty() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  let location = useLocation(),
    textlocation = location.pathname,
    completedrequests = textlocation.includes("completedrequests"),
    canceledrequests = textlocation.includes("canceledrequests");


  return (
    <div className='profile__empty'>
      <img src={ImageEmpty} alt="ImageEmpty" />
      <p>
      {completedrequests === true ? 

      <>{language === "ar" ? "لا توجد طلبات مكتملة حتى الآن" : "There are no completed requests yet"}</>

      :canceledrequests === true ? 

      <>{language === "ar" ? "لا توجد طلبات ملغاة حتى الآن" : "There are no canceled orders yet"}</>

        :<>{language === "ar" ? "لا توجد طلبات حالية حتى الآن" : "There are no current requests yet"}</>}
      </p>
    </div>
  )
}

export default MyOrdersEmpty