import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import NavList from '../../../components/buyer/checkoutaddress/navlist/index.jsx';
import OrderData from '../../../components/buyer/checkoutdone/OrderData.jsx';
import Loading from '../../../layout/loading/loading.jsx';
import { useParams } from 'react-router';
import { GetOnlayOrdersBuyer } from '../../../api/actions.js';
import { TokenLate, UserIdlate } from '../../../api/index.js';

function CheckoutDone() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();


  useEffect(() => {
    if (UserIdlate === 3 && TokenLate !== null) {
      window.location.pathname = `/`
    }
  }, []);


  useEffect(() => {
    GetOnlayOrdersBuyer(id, setData, setLoading);
  }, [loading]);

  return (
    <>
      {loading === false ?
        <Loading />
        :
        <section className='checkout'>
          <div className="container">
            <NavList />

            <div className='myordersdetails__data'>

              <OrderData Price={data.products_price} Priceshipping={data.shipping} data={data}
                Totleprice={data.total}  OrderItems={data.order_items} />

            </div>
          </div>
        </section>
      }
    </>
  )
}

export default CheckoutDone;
