import React from 'react'
import { useState } from 'react';
import { useContext } from 'react';
import MyaddressesEmpty from '../../myaddresses/empty/index.jsx';
import CheckoutAddressCard from '../card/index.jsx';
import CheckoutAddressEnd from '../end/index.jsx';
import { Authcontext } from '../../../../store/context';

function CheckoutAddressDetiles(props) {
  const { Data } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [selectnumber, setSelectnumber] = useState(0);
  const [total, setTotal] = useState("");
  const [orderid, setOrderid] = useState("");
  const [loadingbutton, setLoadingbutton] = useState(false);

  return (
    <div className='checkout__address__detiles'>

      {Data.length === 0 ?<>
        <div className="myaddresses__row">
          <MyaddressesEmpty />
        </div>
      </>
        :
        <>
          <h6>{language === "ar" ? "حدد عنوان التسليم" : "Select The Delivery Address"}</h6>
          {Data.map(item =>
            <CheckoutAddressCard Selectnumber={selectnumber} setSelectnumber={setSelectnumber}
              Id={item.id} key={item.id} Item={item} setTotal={setTotal}
               setOrderid={setOrderid} setLoadingbutton={setLoadingbutton}/>
          )}
        </>
      }
      <CheckoutAddressEnd loadingbutton={loadingbutton} orderid={orderid} />

    </div>
  )
}

export default CheckoutAddressDetiles;