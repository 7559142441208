import React, { useContext, useEffect, useState } from "react";
import InputImgUpload from "../../../../images/icon/img-upload.png";
import IconDelete from "../../../../images/icon/icon_close_input_image.png";
import Select from 'react-select'
import { GetDataColor, GetDataSize } from "../../../../api/actions";


export function InputImages(props) {
  const {
    imageone,
    setImageone,
    imagetwo,
    setImagetwo,
    imagethree,
    setImagethree,
    imagefour,
    setImagefour,
    imagefive,
    setImagefive,
    imagesix,
    setImagesix,
    language,
    validation,
    selectedImages,
  } = props;

  const [filefive, setFilefive] = useState(null);
  const [fileone, setFileone] = useState(null);
  const [filetwo, setFiletwo] = useState(null);
  const [filethree, setFilethree] = useState(null);
  const [filefour, setFilefour] = useState(null);
  const [filesix, setFilesix] = useState(null);

  return (
    <div
      className={
        validation === false && selectedImages.length === 0
          ? "form-proudect_images position-relative"
          : "form-proudect_images"
      }
    >
      <label> {language === "en" ? "Product Pictures" : "صور المنتج"}</label>

      <div className="row">
        <div className="col-lg-3 col-md-6">
          <div className="item">
            {imageone === null ? (
              <></>
            ) : (
              <div className="over">
                <img src={fileone} alt="" />
              </div>
            )}
            <span>
              <img src={InputImgUpload} alt="" />
              <input
                type="file"
                accept="image/*"
                className="input-file"
                onChange={(e) => {
                  setImageone(e.target.files[0]);
                  setFileone(URL.createObjectURL(e.target.files[0]));
                }}
              />
            </span>
            {imageone === null ? (
              <></>
            ) : (
              <button
                onClick={() => {
                  setImageone(null);
                  setFileone(null);
                }}
                type="button"
              >
                <img src={IconDelete} alt="" />
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item">
            {imagetwo === null ? (
              <></>
            ) : (
              <div className="over">
                <img src={filetwo} alt="" />
              </div>
            )}
            <span>
              <img src={InputImgUpload} alt="" />
              <input
                type="file"
                accept="image/*"
                className="input-file"
                onChange={(e) => {
                  setImagetwo(e.target.files[0]);
                  setFiletwo(URL.createObjectURL(e.target.files[0]));
                }}
              />
            </span>
            {imagetwo === null ? (
              <></>
            ) : (
              <button
                onClick={() => {
                  setImagetwo(null);
                  setFiletwo(null);
                }}
                type="button"
              >
                <img src={IconDelete} alt="" />
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item">
            {imagethree === null ? (
              <></>
            ) : (
              <div className="over">
                <img src={filethree} alt="" />
              </div>
            )}
            <span>
              <img src={InputImgUpload} alt="" />
              <input
                type="file"
                accept="image/*"
                className="input-file"
                onChange={(e) => {
                  setImagethree(e.target.files[0]);
                  setFilethree(URL.createObjectURL(e.target.files[0]));
                }}
              />
            </span>
            {imagethree === null ? (
              <></>
            ) : (
              <button
                onClick={() => {
                  setImagethree(null);
                  setFilethree(null);
                }}
                type="button"
              >
                <img src={IconDelete} alt="" />
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item">
            {imagefour === null ? (
              <></>
            ) : (
              <div className="over">
                <img src={filefour} alt="" />
              </div>
            )}
            <span>
              <img src={InputImgUpload} alt="" />
              <input
                type="file"
                accept="image/*"
                className="input-file"
                onChange={(e) => {
                  setImagefour(e.target.files[0]);
                  setFilefour(URL.createObjectURL(e.target.files[0]));
                }}
              />
            </span>
            {imagefour === null ? (
              <></>
            ) : (
              <button
                onClick={() => {
                  setImagefour(null);
                  setFilefour(null);
                }}
                type="button"
              >
                <img src={IconDelete} alt="" />
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item">
            {imagefive === null ? (
              <></>
            ) : (
              <div className="over">
                <img src={filefive} alt="" />
              </div>
            )}
            <span>
              <img src={InputImgUpload} alt="" />
              <input
                type="file"
                accept="image/*"
                className="input-file"
                onChange={(e) => {
                  setImagefive(e.target.files[0]);
                  setFilefive(URL.createObjectURL(e.target.files[0]));
                }}
              />
            </span>
            {imagefive === null ? (
              <></>
            ) : (
              <button
                onClick={() => {
                  setImagefive(null);
                  setFilefive(null);
                }}
                type="button"
              >
                <img src={IconDelete} alt="" />
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item">
            {imagesix === null ? (
              <></>
            ) : (
              <div className="over">
                <img src={filesix} alt="" />
              </div>
            )}
            <span>
              <img src={InputImgUpload} alt="" />
              <input
                type="file"
                accept="image/*"
                className="input-file"
                onChange={(e) => {
                  setImagesix(e.target.files[0]);
                  setFilesix(URL.createObjectURL(e.target.files[0]));
                }}
              />
            </span>
            {imagesix === null ? (
              <></>
            ) : (
              <button
                onClick={() => {
                  setImagesix(null);
                  setFilesix(null);
                }}
                type="button"
              >
                <img src={IconDelete} alt="" />
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="invalid-feedback">
        {language === "en"
          ? "At Least One Value Must Be Entered"
          : "يجب أدخال قيمه واحده على الاقل"}
      </div>
    </div>
  );
}

export function InputName(props) {
  const { handleChange, language, validation, value } = props;
  return (
    <div
      className={
        validation === false && value === ""
          ? "form-group position-relative"
          : "form-group"
      }
    >
      <label>{language === "en" ? "Product Name" : "اسم المنتج"}</label>
      <input
        type="text"
        required
        className={
          validation === false && value === ""
            ? "form-control is-invalid"
            : "form-control"
        }
        placeholder={language === "en" ? "Product Name" : "اسم المنتج"}
        name="title"
        onChange={handleChange}
      />
      <div className="invalid-feedback">
        {language === "en"
          ? "This Field Must Be Entered"
          : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}

export function InputPrice(props) {
  const { handleChange, language, validation, value ,Newprice ,setNewprice ,Numberpercentage} = props;
  return (
    <div className="form-group-password ">
      <div className="position-relative">

        <label> {language === "en" ? "Price " : "السعر"} </label>

        <div className="price-relative">
          <input
            type="number"
            required
            className={
              validation === false && value === ""
                ? "form-control is-invalid"
                : "form-control"
            }
            placeholder={language === "en" ? "Price " : "السعر"}
            name="price"
            onChange={handleChange} onInput={(e)=> 
              {
                const nuwnumber = e.target.value - ((e.target.value / 100) *Numberpercentage);
                setNewprice(nuwnumber)
              }
            }
          />
          <span className="dataprice"> {language === "en" ? "s.r" : " ر.س"}</span>
        </div>

        {validation === false && value === "" ?
          <div className="invalid-feedback">
            {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
          </div>
          : ""}


        <p className="discount-percentage">
          {language === "en" ? `*Please note that ${Numberpercentage}% tax will be deducted from this amount` 
          : `*يرجى العلم أنه سيتم خصم ${Numberpercentage}% ضريبة لاتي من هذا المبلغ`}
        </p>
      </div>

      <div className="will-receive">
        {language === "en" ? "Will Receive" : "سوف تستقبل"}
      </div>
      <div className="position-relative">
        
      <div className="price-relative">
          <input type="number"className= "form-control"name="price" defaultValue={Newprice}/>
          <span className="dataprice"> {language === "en" ? "s.r" : " ر.س"}</span>
        </div>
      </div>
    </div>
  );
}

export function InputColor(props) {
  const { language, validation, selectedcolor, setSelectedcolor } = props;

  const [color, setColor] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataColor(setLoading, setColor);
  }, [loading]);


  const optionsArray = []

  for (var i = 0; i < color.length; i++) {
    var obj = {};
    obj['value'] = color[i].id;
    obj['label'] = color[i].name;
    optionsArray.push(obj);
  }


  return (
    <>
      {loading === false ? (
        ""
      ) : (
        <div className={validation === false && selectedcolor.length === 0 ? "form-group position-relative" : "form-group"}>
          <label>
            {" "}
            {language === "en" ? "Available Colors " : "الألوان المتاحة"}{" "}
          </label>

          <Select options={optionsArray}
            isMulti className="basic-multi-select" classNamePrefix="select"
            name="colors" onChange={(e) => setSelectedcolor(e)}
            placeHolder={
              language === "en"
                ? "Add Size And Press Enter"
                : "أضف المقاس واضغط على Enter"
            } />


          <div className="invalid-feedback">
            {language === "en"
              ? "At Least One Value Must Be Entered"
              : "يجب أدخال قيمه واحده على الاقل"}
          </div>
        </div>
      )}
    </>
  );
}

export function InputSize(props) {
  const { language, validation, selectedsize, setSelectedsize } = props;

  const [size, setSize] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataSize(setLoading, setSize);
  }, [loading]);


  const optionsArray = []

  for (var i = 0; i < size.length; i++) {
    var obj = {};
    obj['value'] = size[i].id;
    obj['label'] = size[i].name;
    optionsArray.push(obj);
  }


  return (
    <div
      className={
        validation === false && selectedsize.length === 0
          ? "form-group position-relative"
          : "form-group"
      }
    >
      <label>
        {" "}
        {language === "en" ? "Available sizes" : "المقاسات المتاحة"}{" "}
      </label>

      <Select options={optionsArray}
        isMulti className="basic-multi-select" classNamePrefix="select"
        name="size" onChange={(e) => setSelectedsize(e)}
        placeHolder={
          language === "en"
            ? "Add Size And Press Enter"
            : "أضف المقاس واضغط على Enter"
        } />

      <div className="invalid-feedback">
        {language === "en"
          ? "At Least One Value Must Be Entered"
          : "يجب أدخال قيمه واحده على الاقل"}
      </div>
    </div>
  );
}

export function InputDes(props) {
  const { handleChange, language, validation, value } = props;
  return (
    <div
      className={
        validation === false && value === ""
          ? "form-group position-relative"
          : "form-group"
      }
    >
      <label>
        {language === "en" ? "Product Description " : " وصف المنتج "}
      </label>
      <textarea
        rows="3"
        placeholder={
          language === "en" ? "Product Description " : " وصف المنتج "
        }
        name="description"
        required
        className={
          validation === false && value === ""
            ? "form-control is-invalid"
            : "form-control"
        }
        onChange={handleChange}
      ></textarea>
      <div className="invalid-feedback">
        {language === "en"
          ? "This Field Must Be Entered"
          : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}

export function InputCategory(props) {
  const { type, setType, Data, language, validation } = props;
  const handleVlaue = (e) => {
    const value = e.target.value;
    setType(value);
  };
  return (
    <div
      className={
        validation === false && type === ""
          ? "form-group selectbox position-relative"
          : "form-group selectbox"
      }
    >
      <label> {language === "en" ? "Product Category" : "فئة المنتج"}</label>
      <select
        required
        className={
          validation === false && type === ""
            ? "form-control is-invalid"
            : "form-control"
        }
        onChange={handleVlaue}
      >
        <option> </option>
        {Data.map((item, index) => (
          <option value={item.id} key={index}>
            {item.name}
          </option>
        ))}
      </select>
      <div className="invalid-feedback">
        {language === "en"
          ? "This Field Must Be Entered"
          : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
