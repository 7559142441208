import React, { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Iconprofile from '../../../../images/icon/icon_profile_data.png'
import Iconaddress from '../../../../images/icon/icon_address.png'
import Iconnotfiction from '../../../../images/icon/icon_notfiction-wihet.png'
import Iconorder from '../../../../images/icon/icon_order.png'
import IconLogout from '../../../../images/icon/icon_logout.png'
import { Authcontext } from '../../../../store/context';
import { Api, TokenLate } from '../../../../api/index.js';
import axios from 'axios';

function ProfileListbuyer(props) {
    const { Id } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;



    const LogOut = async () => {
        const options = {
            method: "POST",
            url: `${Api}logout`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "Retry-After": 3600,
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${TokenLate}`,
            },
        };
        await axios(options).then(function (response) {
            localStorage.removeItem("tokenlate")
            localStorage.removeItem("languagelate")
            localStorage.removeItem("useridlate")
            localStorage.removeItem("usertypelate")
            window.location.pathname = `/`;
        })
            .catch(function (error) {
                localStorage.removeItem("tokenlate")
                localStorage.removeItem("languagelate")
                localStorage.removeItem("useridlate")
                localStorage.removeItem("usertypelate")
                window.location.pathname = `/`;
            });
    }


    const Signoutacount = () => {
        LogOut();
    }

    let location = useLocation(),
        textlocation = location.pathname,
        profileloction = textlocation.includes("profile"),
        myaddressesloction = textlocation.includes("myaddresses"),
        notificationloction = textlocation.includes("notification"),
        requestsloction = textlocation.includes("requests");
    return (
        <div className='profile__list'>
            <ul>
                <li>
                    <NavLink to={"/profile"} className="icon_profile">
                        <img src={Iconprofile} alt="Iconprofile" />
                        <span
                            className={profileloction === true ? "text active" : "text"}
                        >{language === "ar" ? "ملفي الشخصي" : "My Profile"} </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/myaddresses"} className="icon_profile">
                        <img src={Iconaddress} alt="Iconprofile" />
                        <span
                            className={myaddressesloction === true ? "text active" : "text"}>
                            {language === "ar" ? "العناوين" : "Addresses"} </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/notification/${Id}/${1}`} className="icon_profile">
                        <img src={Iconnotfiction} alt="Iconprofile" />
                        <span
                            className={notificationloction === true ? "text active" : "text"}
                        >{language === "ar" ? "الإشعارات" : "Notification"} </span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/currentrequests/${1}`} className="icon_profile">
                        <img src={Iconorder} alt="Iconprofile" />
                        <span className={requestsloction === true ? "text active" : "text"}>
                            {language === "ar" ? "الطلبات" : "Requests"} </span>
                    </NavLink>
                </li>
            </ul>
            <ul>
                <li>
                    <button type='button' onClick={Signoutacount}>
                        <img src={IconLogout} alt="Iconprofile" />
                        <span className="text">
                            {language === "ar" ? "تسجيل الخروج" : "Sign Out"} </span>
                    </button>
                </li>
            </ul>
        </div>
    )
}

export default ProfileListbuyer;