import React from 'react';

function MyOrdersEmpty(props) {
  const {ImageEmpty ,Title}=props;
  return (
    <div className='homeseller__empty'>
        <img src={ImageEmpty} alt="ImageEmpty" />
        <p>{Title}</p>
    </div>
  )
}

export default MyOrdersEmpty