import React, { useContext, useState } from 'react'
import { useLoadScript, GoogleMap } from "@react-google-maps/api";
import { Authcontext } from '../../../../store/context';

function LoctionIfram() {
  const authcontext = useContext(Authcontext),
    centerrequest = authcontext.centerrequest;

  const center = {
    lat: centerrequest.lat,
    lng: centerrequest.lng
  };
  
  const { isLoaded } = useLoadScript({
   // googleMapsApiKey: "AIzaSyBn3NtsJ5lgHSIxUJ4AuqAMm2RXldDDjN8"
    googleMapsApiKey: "AIzaSyAGveCWYwqpHRvPCSpSKdM8R8WZrcVJ_Ls"
    
  });



  const renderMap = () => {
    return (
      <div className={"iframe-map"}>
        <GoogleMap
          center={center}
          zoom={13}
          mapContainerStyle={{
            height: "100%",
            width: "100%"
          }}
        />

      </div>
    );
  };

  return isLoaded ? renderMap() : null;
}

export default LoctionIfram