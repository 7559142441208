/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState } from 'react';
import { Formik } from "formik";
import * as Yup from "yup";
import {  InputPassword, InputSinginEmail } from '../../../../navbar/navbarbottom/modal/outer/inputs.jsx';
import { useContext } from 'react';
import axios from 'axios';
import { Authcontext } from '../../../../../store/context.js';
import { useNavigate } from 'react-router';
import { Api, languagelate } from '../../../../../api/index.js';

function FormSingIn() {
  let navigate = useNavigate();
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [message, setMessage] = useState("");
  const [messagestatus, setMessagestatus] = useState("");
  const state = {
    email: "",
    password: "",
  };

  const SignIn = async (values, setMessage) => {
    var data = new FormData();
    data.append('email_phone', values.email);
    data.append('password', values.password);
    data.append('device_id', "granted");
    data.append('device_type', "web");

    const options = {
      method: "POST",
      url: `${Api}login`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        'Access-Control-Allow-Origin': '*',
        "Accept-Language": `${languagelate === null ? 'ar' : languagelate}`,
      },
      data: data
    };
    axios(options)
    .then(function (response) {
      console.log(response.data.message);

      if (response.data.data.user.is_email_verified === false && response.data.data.user.token === "") {
        setMessagestatus("is_email_verified")
        sessionStorage.setItem("emaillate", JSON.stringify(values.email));
        sessionStorage.setItem("verfiy_token", JSON.stringify(response.data.data.user.verfiy_token));
        setMessage(response.data.message)

      } else if (response.data.data.user.is_phone_verified === false && response.data.data.user.token === "") {

        setMessagestatus("is_phone_verified")
        sessionStorage.setItem("emaillate", JSON.stringify(values.email));
        sessionStorage.setItem("verfiy_token", JSON.stringify(response.data.data.user.verfiy_token));
        setMessage(response.data.message)

      } else {

        localStorage.setItem("tokenlate", JSON.stringify(response.data.data.user.token));
        localStorage.setItem("languagelate", JSON.stringify(response.data.data.user.lang));
        localStorage.setItem("usertypelate", JSON.stringify(response.data.data.user.user_type.id));
        localStorage.setItem("useridlate", JSON.stringify(response.data.data.user.id));
        sessionStorage.removeItem("verfiy_token");
        sessionStorage.removeItem("emaillate");
        window.location.pathname = `/`;

      }
    })
      .catch(function (error) {
        console.log(error);
        setMessage(error.response.data.message)
      });
  };

  const onSubmit = (values) => {
    SignIn(values, setMessage);
  }
  const Createaccount=()=>{
   navigate(`/sellersingup`);
}

const OpenPageActive = () => {
  navigate(`/sellercode`);
}
  const form = (props) => {
    return <form onSubmit={props.handleSubmit}>
      <InputSinginEmail errors={props.errors} values={props.values} />
      <InputPassword errors={props.errors} singin={false} />

      <span className="errorfiled">
       
      {messagestatus === "is_email_verified" ?
          <span className="link">
            {language === "en" ?
              <>
                {message}{" - "}To Activate The Email{" "}
                <button className="btn btnlogin" type='button' role="button"
                  onClick={OpenPageActive}>From Here</button>
              </> :
              <>
                <button className="btn btnlogin" type='button' role="button"
                  onClick={OpenPageActive}>من هنا</button> {" "}
                {message}{" - "}لتفعيل البريد الالكتروني
              </>
            }
          </span>
          : messagestatus === "is_phone_verified" ?
            <span className="link">
              {language === "en" ?
                <>
                  {message}{" - "}To Activate The Phone Number{" "}
                  <button className="btn btnlogin" type='button' role="button"
                    onClick={OpenPageActive}>From Here</button>
                </> :
                <>
                  <button className="btn btnlogin" type='button' role="button"
                    onClick={OpenPageActive}>من هنا</button> {" "}
                  {message}{" - "} لتفعيل رقم الهاتف
                </>
              }
            </span>
            :
            <span className="link">{message}</span>
        }
      </span>

      <button type="submit"
        className={"btn button-login button-active"} >
        {language === "en" ? "Sign In" : "تسجيل الدخول"}
      </button>
    </form>
  }


  const schema = () => {
    const schema = Yup.object().shape({
      email: Yup.string().required("Email Is Required"),
      password: Yup.string().required('Password Is Required'),
    });
    return schema;
  }

  return (
    <div className="sellersingin__body">
      <Formik
        initialValues={state}
        onSubmit={onSubmit}
        render={form}
        validationSchema={schema()}
        validateOnChange={false}
        enableReinitialize={true}
        validateOnBlur={false}
      />



      <div className='end'>
        <div className='first'>
          {language === "en" ? "Don't have an account?" : "لا تملك حساب ؟"}
          {" "}
          <button className="btn btnlogin"  type='button'  
          onClick={Createaccount}>
            {language === "en" ? "Create your account now" : "انشىء حسابك الان"}
            </button>
        </div>

      </div>
    </div>
  )
}

export default FormSingIn;
