import React, { useContext, useEffect, useState } from 'react'
import MyAddressesCard from './card';
import MyaddressesEmpty from './empty';
import ModalAddress from './modal/modaladdres';
import ModalMap from './modal/modalmap';
import { Authcontext } from '../../../store/context';
import ReactPaginate from 'react-paginate';

function MyaddressesContant(props) {
    const { Data, setData } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    const [address, setAddress] = useState("");
    const [clickedLatLng, setClickedLatLng] = useState({ lat: "", lng: "" });
    const [placeid, setPlaceid] = useState("");

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemPerpage = 3;


    useEffect(() => {
        const endOffest = itemOffset + itemPerpage;
        setCurrentItems(Data.slice(itemOffset, endOffest));
        setPageCount(Math.ceil(Data.length / itemPerpage));
    }, [itemOffset, itemPerpage, Data]);

    const handlePageClick = (e) => {
        const newOffest = (e.selected * itemPerpage) % Data.length;
        setItemOffset(newOffest);
    };

    return (

        <div className='myaddresses__contant'>
            <button type='button' className='btn btn-modal'
                data-bs-toggle="modal" data-bs-target="#modalmap">
                {language === "ar" ? "إضافة عنوان جديد" : "Add a New Address"}
            </button>
            {Data.length === 0 ?
                <div className="myaddresses__row">
                    <MyaddressesEmpty />
                </div>
                : <>


                    {Data.length <= 3 ?
                        <div className="myaddresses__row">
                            {Data.map(item =>
                                <MyAddressesCard Isnotify={item.is_default} key={item.id} Item={item} setData={setData} />
                            )}
                        </div>
                        :
                        <>
                            <div className="myaddresses__row">
                                {currentItems.map(item =>
                                    <MyAddressesCard Isnotify={item.is_default} key={item.id} Item={item} setData={setData} />
                                )}
                            </div>
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                postsPerPage={2}
                                pageSize={1}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                                renderOnZeroPageCount={null}
                            />
                        </>
                    }
                </>
            }
            <ModalMap clickedLatLng={clickedLatLng} setClickedLatLng={setClickedLatLng}
                setAddress={setAddress} setPlaceid={setPlaceid} />
            <ModalAddress placeid={placeid} Address={address} clickedLatLng={clickedLatLng} />
        </div>
    )
}

export default MyaddressesContant;

/*
*/