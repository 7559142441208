import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import Titlepage from '../../shared/title/index.jsx';
import { Authcontext } from '../../../store/context';
import Slider from 'react-slick';
import  ImageBack  from '../../../images/home/categories_one.png';

function HomeCategories(props) {
  const { Categories } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "15px",
    slidesToShow: 3,
    speed: 500,
    dots: false,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: false
        }
      },
    ]
  };

  return (
    <div className='homebuyer__categories'>
      <Titlepage Title={language === "ar" ? "الفئات" : "Categories"} />

      <div className="row">
        <Slider {...settings}>

          {Categories.map(item =>
            <NavLink
              to={`/products/page:${1}/filtersort:${"all"}/filtercategory:${item.id}/filtersubcategory:${0}/minValue:${0}/maxValue:${0}`}
              className="categories-card" key={item.id}>
              <img src={item.image} alt="Backgroundone"
                onError={(e) =>
                  e.target.src = ImageBack
                } />
              <span>{item.name}</span>
              <div className="overload"></div>
            </NavLink>
          )}
        </Slider>
      </div>


    </div>
  )
}

export default HomeCategories;