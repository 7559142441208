/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Authcontext } from '../../../../store/context';
import { useContext } from 'react';
import NavListSingup from './navlist';
import { useState } from 'react';
import { Createaccount } from '../../../../api/actions';
import Form_One from './form/form-one';
import Form_Two from './form/form-two';
import Form_Three from './form/form-three';
import Form_Four from './form/form-four';
import Form_Five from './form/form-five';

function SellerSingUprow() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [stutesform, setStutesform] = useState(1);
  const [message, setMessage] = useState("");

  const [StateArrayone, setStateArrayone] = useState({});
  const [StateArraytwo, setStateArraytwo] = useState({});
  const [StateArrayfour, setStateArraytfour] = useState({});

  const [StateArrayfive, setStateArrayFive] = useState({
    registrationnumber:""
  });
  const [fileone, setFileone] = useState(null);
  const [fileotwo, setFiletwo] = useState(null);
  const [filethree, setFilethree] = useState(null);

  const [loadingbutton, setLoadingbutton] = useState(false);

  const handleSubmit = (e) => {
    setLoadingbutton(true)
    
    Createaccount(StateArrayone,StateArraytwo,fileone,fileotwo,StateArrayfour,StateArrayfive
      ,filethree,setMessage,setLoadingbutton)
  }

  return (
        <div className="sellersingin__row">
          <div className='sellersingin__header'>
            <h3>{language === "ar" ? "إنشاء متجرك" : "Create Your Store"}</h3>
          </div>

          <div className="sellersingup__body">
            <NavListSingup stutesform={stutesform}  />


            {stutesform === 1 ?
            <Form_One setStutesform={setStutesform} setStateArrayone={setStateArrayone}
            StateArrayone={StateArrayone}/>
            :stutesform === 2 ?
            <Form_Two setStutesform={setStutesform} setStateArraytwo={setStateArraytwo}
            StateArraytwo={StateArraytwo}/>
            :stutesform === 3 ?
            <Form_Three setFileone={setFileone} fileone={fileone} setFiletwo={setFiletwo} fileotwo={fileotwo}
             setStutesform={setStutesform} />
             :stutesform === 4 ?
            <Form_Four setStutesform={setStutesform} setStateArraytfour={setStateArraytfour} 
            StateArrayfour={StateArrayfour}/>
            :stutesform === 5 ?

            <Form_Five setStutesform={setStutesform} 
            setFilethree={setFilethree} filethree={filethree} handleSubmit={handleSubmit} message={message} 
            loadingbutton={loadingbutton} StateArrayfive={StateArrayfive} setStateArrayFive={setStateArrayFive}/>
            :""}
          </div>
        </div>
  )
}

export default SellerSingUprow;