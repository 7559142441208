import React, { useContext, useState } from 'react'
import { InputFile } from './inputes';
import { Authcontext } from '../../../../store/context';


function FormThree(props) {
    const { setFileone, fileone, setFiletwo, fileotwo, setStutesform ,Title,DataFileone
        ,DataFiletwo} = props;
    const [validation, setvalidation] = useState("hide");
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const handleNextform = (e) => {
        e.preventDefault();
            setvalidation(true);
            setStutesform(4)
    }



    const handleBackform = (e) => {
        e.preventDefault();
        setStutesform(2)
    }

    return (
        <>
            <div className="title">
                <h6>{Title}</h6>
            </div>
            <InputFile language={language} setFile={setFileone} file={fileone} validation={validation}
                Title={language === "ar" ? "تحميل رخصة تجارية جديدة" : "Upload a new trade license"} DataFile={DataFileone}/>


            <InputFile language={language} setFile={setFiletwo} file={fileotwo} validation={validation}
                Title={language === "ar" ? "تحميل بطاقة هوية جديدة" : "Upload a new ID card"} DataFile={DataFiletwo}/>



            <div className="form-end">
                <button type='button' className='btn btn-back' onClick={handleBackform}>
                    {language === "en" ? "Back" : "الرجوع"}
                </button>

                <button type='button' className='btn btn-next' onClick={handleNextform}>
                    {language === "en" ? "Next" : "التالي"}
                </button>
            </div>
        </>
    )
}

export default FormThree;