import React from 'react'
import { Authcontext } from '../../../../store/context';
import { useContext } from 'react';
import List from './list';

function LatestRequestsTable(props) {
    const { Data } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    
    return (
        <div className='homeseller__latestrequests__table'>

            <ul className="top row">
                <li className="col">
                    {language === "en" ? "Order Number" : "رقم الطلب"}
                </li>
                <li className="col">
                    {language === "en" ? "Customer Name" : "اسم العميل"}
                </li>
                <li className="col">
                    {language === "en" ? "The Date Of Application" : "تاريخ الطلب"}
                </li>
                <li className="col">
                    {language === "en" ? "Paying Off" : "الدفع"}
                </li>
                <li className="col">
                    {language === "en" ? "The Total" : "المجموع"}
                </li>
                <li className="col">
                    {language === "en" ? "Order Status" : "حالة الطلب"}
                </li>
            </ul>

            <div className='listitems'>

                {Data.map(item =>
                    <List Orderstatus={item.status_en}  key={item.id} Item={item} />
                )}


            </div>
        </div>
    )
}

export default LatestRequestsTable;