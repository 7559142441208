import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import NotificationContant from '../../../components/buyer/notification/index.jsx';
import NotificationEmpty from '../../../components/buyer/notification/empty';
import { useNavigate, useParams } from 'react-router';
import Paginate from '../../../components/shared/paginate/index.jsx';
import Loading from '../../../layout/loading/loading';
import { GetDataNotifications } from '../../../api/actions.js';
import ProfileListSeller from '../../../layout/profile/list/seller/index.jsx';
import ProfileListbuyer from '../../../layout/profile/list/buyer/index.jsx';
import { TokenLate, UserTypelate } from '../../../api/index.js';

function Notification() {
  const { page,id } = useParams();
  const pagenumber = +page;
  let navigate = useNavigate();
  const [pageCount, setpageCount] = useState(pagenumber);
  const [perpage, setPerpage] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataNotifications(setData, setLoading, pageCount, setPerpage)
  }, [loading, pageCount]);



  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    setpageCount(number)
    navigate(`/notification/${number}`)
  };

  return (
    <>
      {loading === false ?
        <Loading />
        :
        <section className='profile'>
          <div className="container">
            <div className="profile__data">

              {UserTypelate === 3 && TokenLate !== null ?
                <ProfileListSeller Id={id}/>

                :
                <ProfileListbuyer Id={id}/>

              }



              {data.length === 0 ?

                <div className='notification__contant'>
                  <NotificationEmpty />
                </div>
                :
                <div className='notification__contant'>
                  <NotificationContant Data={data} />
                </div>
              }




            </div>
            {data.length === 0 ?"" :
            <div className="row">
              <div className="col-9">
                <Paginate setpageCount={setpageCount} perpage={perpage}
                  handlePageClick={handlePageClick} pagenumber={pagenumber} />
              </div>
              <div className="col-3">
              </div>
            </div>
            }



          </div>
        </section>
      }
    </>
  )
}



export default Notification;