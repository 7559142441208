import React, { useContext } from 'react'
import { Authcontext } from '../../../store/context';
import { InputControl } from './inputes';

function FormFour(props) {
    const { Data, setStutes } = props;

    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const handleNextform = (e) => {
        e.preventDefault();
        setStutes(5)
    }



    const handleBackform = (e) => {
        e.preventDefault();
        setStutes(3)
    }

    return (
        <>
            <div className="title">
                <h6>{language === "en" ? "Your bank details" : "تفاصيل البنك الخاص بك"}</h6>
            </div>

            <InputControl value={Data.provider.bank_account_name}
                Title={language === "en" ? "Beneficiary Name" : "اسم المستفيد"} />


            <InputControl value={Data.provider.bank_name}
                Title={language === "en" ? "Bank name" : "اسم البنك"} />

            <InputControl value={Data.provider.bank_branch_name}
                Title={language === "en" ? "Branch Name" : "اسم الفرع"} />

            <InputControl value={Data.provider.bank_account_num}
                Title={language === "en" ? "Account Number" : "رقم الحساب"} />

            <InputControl value={Data.provider.bank_swift_code}
                Title={language === "en" ? "Swift code" : "رمز السويفت"} />



            <div className="form-end">

                <button type='button' className='btn btn-back' onClick={handleBackform}>
                    {language === "en" ? "Back" : "الرجوع"}
                </button>

                <button type='button' className='btn btn-next' onClick={handleNextform}>
                    {language === "en" ? "Next" : "التالي"}
                </button>
            </div>
        </>
    )
}

export default FormFour;