import React, { useContext, useState } from 'react';
import IconDelete from '../../../../images/icon/icon-delete.png';
import { Authcontext } from '../../../../store/context';
import { NavLink } from 'react-router-dom';
import IconFavorite from '../../../../images/icon/icon_heart.png';
import IconhartFavorite from '../../../../images/icon/icon_heart_color_favorit.png';
import IconPluse from '../../../../images/icon/icon_pluse.png';
import IconMinus from '../../../../images/icon/icon_minus.png';
import IconEmptySize from '../../../../images/icon/emptysize.png';
import IconFavourit from '../../../../images/icon/icon_favorite-nn.png';
import swal from 'sweetalert';
import { Api, TokenLate, languagelate } from '../../../../api/index.js';
import axios from 'axios';
import { GetDataCart, RemoveToCart } from '../../../../api/actions';

function CartCard(props) {
    const { SellerName, Item, setData, setItemsdata } = props;
    const [number, setNumber] = useState(Item.quantity);
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    const cart = authcontext.cart;
    const setCart = authcontext.setCart;
    const Id = Item.product.id;

    const [loading, setLoading] = useState(false);

    const UpdateCartItem = async (Id, quantity) => {

        var data = new FormData();
        data.append('quantity', quantity);
        const options = {
            method: "PUT",
            url: `${Api}cart/${Id}`,
            headers: {
                Accept: "application/json",
                'Content-Type': 'application/json',
                Authorization: `Bearer ${TokenLate}`,
                "Accept-Language": `${languagelate === null ? 'ar' : languagelate}`,
            },
            data
        };
        await axios(options).then(function (response) {
            GetDataCart(setData, setItemsdata, setLoading)
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    const AddToFavourite = async (id) => {
        const options = {
            method: "post",
            url: `${Api}products/${id}/favorite`,
            headers: {
                Accept: "application/json",
                'Content-Type': 'application/json',
                Authorization: `Bearer ${TokenLate}`,
                "Accept-Language": `${languagelate === null ? 'ar' : languagelate}`,
            }
        };
        axios(options).then(function (response) {
            GetDataCart(setData, setItemsdata, setLoading)
        })
            .catch(function (error) {
                console.log(error);
            });
    };

    const RemoveToCart = (Id) => {
        const options = {
            method: "DELETE",
            url: `${Api}cart/${Id}`,
            headers: {
                Accept: "application/json",
                'Content-Type': 'application/json',
                Authorization: `Bearer ${TokenLate}`,
                "Accept-Language": `${languagelate === null ? 'ar' : languagelate}`,
            }
        };
        axios(options).then(function (response) {
            GetDataCart(setData, setItemsdata, setLoading)

        })
            .catch(function (error) {
                console.log(error);
            });
    };
    const Plusaction = (e) => {
        e.preventDefault();
        setNumber(number + 1)
        let newnumber = Item.quantity + 1;
        UpdateCartItem(Item.id, newnumber)
    }
    const Downaction = (e) => {
        e.preventDefault();
        if (number <= 1) {
            setNumber(1)
            UpdateCartItem(Item.id, 1)
        } else {
            setNumber(number - 1)
            let newnumber = Item.quantity - 1;
            UpdateCartItem(Item.id, newnumber)
        }
    }
    const AddItemFavourite = () => {
        AddToFavourite(Item.product.id)

        if (Item.product.is_favorite === true) {

            {
                language === "en" ?
                    swal({
                        text: "The product has been removed from your favourites",
                        icon: IconFavourit,
                        buttons: false,
                        timer: 3000
                    })
                    :
                    swal({
                        text: "تمت إزاله المنتج من المفضله",
                        icon: IconFavourit,
                        buttons: false,
                        timer: 3000
                    })
            }
        } else {

            {
                language === "en" ?
                    swal({
                        text: "The product has been added to favourites !",
                        icon: IconFavourit,
                        buttons: false,
                        timer: 3000
                    })
                    :
                    swal({
                        text: "تمت إضافة المنتج للمفضله",
                        icon: IconFavourit,
                        buttons: false,
                        timer: 3000
                    })
            }
        }


    }
    const RemoveItemToCart = () => {

        cart.forEach((item, index) => {
            if (item === Id) {
                cart.splice(index, 1)
                RemoveToCart(Item.id)

                const MassegaRemove = language === "en" ?
                    "The product has been removed from the shopping cart"
                    : "تم مسح المنتج  من عربة التسوق";
                swal({ text: MassegaRemove, icon: IconEmptySize, buttons: false, timer: 2000 })
            } else {
                const MassegaNotRemove = language === "en" ? "The product has already been removed" :
                    "المنتج تم حذفه بالفعل ";
                swal({ text: MassegaNotRemove, icon: IconEmptySize, buttons: false, timer: 2000 })

            }
        })
        setCart([...cart])
    }
    return (
        <div className='cart__card'>
            <div className="image">
                <NavLink to={`/proudect/${Item.product.id}`}>
                    <img src={Item.product.media[0].image} alt="Itemproudect" />
                </NavLink>
            </div>
            <ul className="data">
                <li className="title">
                    <span className="left">{Item.product.title}</span>
                    <button className='btn btn-delete' type='button' onClick={RemoveItemToCart}>
                        <img src={IconDelete} alt="IconDelete" />
                    </button>
                </li>
                <li className="price">{`SR ${Item.price}`}</li>

                <li className='color-list'>
                    <span className='lift'>
                        <span className="title">{language === "ar" ? "اللون" : "Item.color.name"}</span>
                        <span className="datacolor">{Item.color.name}</span>
                    </span>
                    <span className='right'>
                        <span className="title">{language === "ar" ? "المقاس" : "Item.size.name"}</span>
                        <span className="datasize">{Item.size.name}</span>
                    </span>
                </li>

                <li className='list-end'>
                    <span className="left">
                        {SellerName === "" ? ""
                            :
                            <span>
                                <span className="title">{language === "ar" ? "البائع" : "Seller"}</span>
                                <NavLink to={"/"} className="dataseller">{SellerName}</NavLink>
                            </span>
                        }
                        <button type='button' className='btn btn-favorite'
                            onClick={AddItemFavourite}>
                            {Item.product.is_favorite === false ?
                                <img src={IconFavorite} alt="IconFavorite" />
                                :
                                <img src={IconhartFavorite} alt="IconFavorite" />
                            }
                            <span>{language === "ar" ? "أضف إلى المفضلة" : "Add To Favourites"}</span>
                        </button>
                    </span>

                    <div className="conter">
                        <button className='btn-plus' type='button' onClick={Plusaction} >
                            <img src={IconPluse} alt="IconPluse" />
                        </button>
                        <span className='number'>
                            {number}
                        </span>
                        <button className={number === 1 ? 'button-disabled btn-plus' : 'btn-plus'} type='button'
                            onClick={Downaction} >
                            <img src={IconMinus} alt="IconMinus" />
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default CartCard;