import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import CheckoutAddressDetiles from '../../../components/buyer/checkoutaddress/detiles/index.jsx';
import NavList from '../../../components/buyer/checkoutaddress/navlist/index.jsx';
import Loading from '../../../layout/loading/loading.jsx';
import { GetShippingAddresses } from '../../../api/actions.js';
import { TokenLate, UserIdlate } from '../../../api/index.js';

function CheckoutAddress() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (UserIdlate === 3 && TokenLate !== null) {
      window.location.pathname = `/`
    }
  }, []);

  useEffect(() => {
    GetShippingAddresses(setData, setLoading)
  }, [loading]);

  return (
    <>
      {loading === false ?
        <Loading />
        :
        <section className='checkout'>
          <div className="container">
            <NavList />
            <CheckoutAddressDetiles Data={data} />
          </div>
        </section>
      }
    </>
  )
}

export default CheckoutAddress;