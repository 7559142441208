import React from 'react';
import SinginHeader from "./header/index.jsx";
import FormSingIn from './form/form.jsx';

function SellerSinginrow() {
  return (
    <div className="sellersingin__row">
      <SinginHeader />
      <FormSingIn />
    </div>
  )
}

export default SellerSinginrow;