import React, { useContext } from 'react';
import { Authcontext } from '../../../store/context';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot, DotGroup } from 'pure-react-carousel';
import IconNext from '../../../images/icon/arrow-right_back_icon.png';
import IconBack from '../../../images/icon/icon_arrow-left.png';
import { NavLink } from 'react-router-dom';

function HomeSlider(props) {
    const { Banners } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
    };


    return (
        <>
            <CarouselProvider
                visibleSlides={1}
                totalSlides={Banners.length}
                step={1}
                currentSlide={0}
                naturalSlideWidth={100}
                naturalSlideHeight={125}
            >
                <Slider>
                    {Banners.map((item, index) =>
                        <Slide key={item.id} index={index}>
                            <div className="left">
                                <NavLink to={"/"}  className='slider-image'>
                                    <img src={item.image} alt="Slider" />
                                </NavLink>
                                {Banners.length === 1 ? "" :
                                    <span className="btn-slider">
                                        <DotGroup className="dot-group" />
                                    </span>
                                }
                            </div>
                            <div className="right">
                                <div className="title">
                                    <h2>
                                        {item.name}
                                        <span className='line'></span>
                                    </h2>
                                    <h4>
                                        {item.desc}
                                    </h4>
                                </div>

                                <div className="data">
                                    {item.desc}
                                </div>
                            </div>
                                {Banners.length === 1 ? "" :
                                    <span className="btn-slider">

                                        <DotGroup className="dot-group" />

                                    </span>
                                }
                        </Slide>
                    )}
                </Slider>
            </CarouselProvider>
        </>
    )
}

export default HomeSlider;