import React, { useContext, useEffect, useState } from 'react'
import { InputCategory, InputColor, InputDes, InputImages, InputName, InputPrice, InputSize } from './inputs/inputesedite.jsx';
import { GetDataCategories, GetDataPercentage, updateProduct } from '../../../api/actions';
import { Authcontext } from '../../../store/context';

function FormEdite(props) {
    const { Data, Id } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    const [state, setState] = useState({
        title: Data.title,
        price: Data.price,
        description: Data.desc,
    });
    const [validation, setvalidation] = useState("hide");

    const [imageone, setImageone] = useState(null);
    const [imagetwo, setImagetwo] = useState(null);
    const [imagethree, setImagethree] = useState(null);
    const [imagefour, setImagefour] = useState(null);
    const [imagefive, setImagefive] = useState(null);
    const [imagesix, setImagesix] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [type, setType] = useState(Data.category.id);
    const [message, setMessage] = useState("");
    const [loadingbutton, setLoadingbutton] = useState(false);

    const [getCategories, setGetCategories] = useState([]);
    const [loadingfilter, setLoadingfilter] = useState(false);


    const [loading, setLoading] = useState(false);
    const [percentage, setPercentage] = useState("");

    const oldArrayColors = [...Data.colors],
        newArrayColor = [];

    for (var i = 0; i < oldArrayColors.length; i++) {
        newArrayColor.push(oldArrayColors[i].id);
    }

    const [selectedcolor, setSelectedcolor] = useState([...newArrayColor]);


    const oldArraySize = [...Data.sizes],
        newArraySize = [];

    for (var i = 0; i < oldArraySize.length; i++) {
        newArraySize.push(oldArraySize[i].id);
    }


    const [selectedsize, setSelectedsize] = useState([...newArraySize]);

    let NewSize = [];
    let NewColor = [];

    const handleChange = (e) => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value,
        });

    };
    const handleSubmit = (e) => {
        e.preventDefault();

        var mammals = new Array(imagesix, imageone, imagetwo, imagethree, imagefour, imagefive);

        var animalString = [];
        for (var i = 0; i < mammals.length; i++) {
            animalString.push(mammals[i]);
        }
        function removeChars(element, index, array) {
            return (element !== null);
        }
        var ImageSelect = animalString.filter(removeChars);
        setSelectedImages(ImageSelect);

        if (state.title === "" ||
            state.price === "" ||
            state.description === "" ||
            selectedsize.length === 0 ||
            selectedcolor.length === 0) {
            setvalidation(false);
        } else {
            setvalidation(true);
            setLoadingbutton(true)
            console.log(selectedImages);
            updateProduct(Id, state, type, selectedsize, NewSize, selectedcolor, NewColor, ImageSelect, setMessage, setLoadingbutton)
        }
    }

    useEffect(() => {
        GetDataCategories(setLoadingfilter, setGetCategories)
    }, [loadingfilter]);

    useEffect(() => {
        GetDataPercentage(setLoading, setPercentage)
    }, [loading]);


    return (

        <>

            <div className="proudect__form">
                <form>

                    <InputImages Id={Id}
                        imageone={imageone} setImageone={setImageone}
                        imagetwo={imagetwo} setImagetwo={setImagetwo}
                        imagethree={imagethree} setImagethree={setImagethree}
                        imagesix={imagesix} setImagesix={setImagesix}
                        imagefour={imagefour} setImagefour={setImagefour}
                        imagefive={imagefive} setImagefive={setImagefive}
                        selectedImages={selectedImages} language={language} validation={validation}
                        Data={Data} />


                    <InputName handleChange={handleChange} language={language}
                        validation={validation} value={state.title} />
                    {loadingfilter === false ?
                        ""
                        :
                        <InputCategory type={type} setType={setType} Data={getCategories}
                            validation={validation} language={language} />
                    }
                    {loading === false ?
                        ""
                        :
                        <InputPrice handleChange={handleChange} language={language}
                            validation={validation} value={state.price} Numberpercentage={+percentage} />
                    }

                    <InputColor language={language} selectedcolor={selectedcolor}
                        setSelectedcolor={setSelectedcolor} validation={validation} ArrayData={oldArrayColors}
                        NewColor={NewColor} />

                    <InputSize language={language} validation={validation} selectedsize={selectedsize}
                        setSelectedsize={setSelectedsize} ArrayData={oldArraySize} NewSize={NewSize} />

                    <InputDes handleChange={handleChange} language={language}
                        validation={validation} value={state.description} />

                    <span className="errorfiled">{message}</span>

                    <div className="end_form">

                        {loadingbutton === false ?
                            <button className="btn btn-save" type="button" onClick={handleSubmit}>
                                {language === "en" ? "Save" : "حفظ"}
                            </button>
                            :
                            <button className="btn btn-save button-disabled" type="button">
                                {language === "en" ? "Loading" : "جار التحميل"}
                                <span className="spinner"></span>
                            </button>}

                        <button className="btn btn-cancel" type="button"
                            data-bs-dismiss="modal" aria-label="Close">
                            {language === "en" ? "Cancel" : "إلغاء"}
                        </button>
                    </div>
                </form>
            </div>

        </>
    )
}

export default FormEdite;
