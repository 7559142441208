import React, { useContext, useEffect, useState } from "react";
import InputImgUpload from "../../../../images/icon/img-upload.png";
import IconDelete from "../../../../images/icon/icon_close_input_image.png";
import { TagsInput } from "react-tag-input-component";
import Select from 'react-select'
import { GetDataColor ,GetDataSize, RemoveImageProduct } from "../../../../api/actions";


export function InputImages(props) {
  const {
    Id,
    setImageone,
    setImagetwo,
    setImagethree,
    setImagefour,
    setImagefive,
    setImagesix,
    language,
    validation,
    selectedImages,
    Data
  } = props;

  const [fileone, setFileone] = useState(Data.media[0] === undefined ? null : Data.media[0].image);
  const [filetwo, setFiletwo] = useState(Data.media[1] === undefined ? null : Data.media[1].image);
  const [filethree, setFilethree] = useState(Data.media[2] === undefined ? null : Data.media[2].image);
  const [filefour, setFilefour] = useState(Data.media[3] === undefined ? null : Data.media[3].image);
  const [filefive, setFilefive] = useState(Data.media[4] === undefined ? null : Data.media[4].image);
  const [filesix, setFilesix] = useState(Data.media[5] === undefined ? null : Data.media[5].image);

  const DeletImage=(setImage,setFile,Id)=>{
    setImage(null);
    setFile(null);
    RemoveImageProduct(Id)
  }

  const UpdateImage=(e,setImage,setFile)=>{
    setImage(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <div
      className={
        validation === false && selectedImages.length === 0
          ? "form-proudect_images position-relative"
          : "form-proudect_images"
      }
    >
      <label> {language === "en" ? "Product Pictures" : "صور المنتج"}</label>

      <div className="row">
        <div className="col-lg-3 col-md-6">
          <div className="item">
            {fileone === null ? (
              <></>
            ) : (
              <div className="over">
                <img src={fileone} alt="" />
              </div>
            )}
            <span>
              <img src={InputImgUpload} alt="" />
            </span>
              <input
                type="file"
                accept="image/*"
                className="input-file"
                onChange={(e) => UpdateImage(e,setImageone,setFileone)}
              />
            {fileone === null ? (
              <></>
            ) : (
              <button
                onClick={() => DeletImage(setImageone,setFileone,Data.media[0].id)}
                type="button"
              >
                <img src={IconDelete} alt="" />
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item">
            {filetwo === null ? (
              <></>
            ) : (
              <div className="over">
                <img src={filetwo} alt="" />
              </div>
            )}
            <span>
              <img src={InputImgUpload} alt="" />
            </span>
              <input
                type="file"
                accept="image/*"
                className="input-file"
                onChange={(e) => UpdateImage(e,setImagetwo,setFiletwo)}
              />
            {filetwo === null ? (
              <></>
            ) : (
              <button
                type="button"
                onClick={() => DeletImage(setImagetwo,setFiletwo,Data.media[1].id)}
              >
                <img src={IconDelete} alt="" />
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item">
            {filethree === null ? (
              <></>
            ) : (
              <div className="over">
                <img src={filethree} alt="" />
              </div>
            )}
            <span>
              <img src={InputImgUpload} alt="" />
            </span>
              <input
                type="file"
                accept="image/*"
                className="input-file"
                onChange={(e) => UpdateImage(e,setImagethree,setFilethree)}
              />
            {filethree === null ? (
              <></>
            ) : (
              <button type="button"
                onClick={() => DeletImage(setImagethree,setFilethree,Data.media[2].id)} >
                <img src={IconDelete} alt="" />
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item">
            {filefour === null ? (
              <></>
            ) : (
              <div className="over">
                <img src={filefour} alt="" />
              </div>
            )}
            <span>
              <img src={InputImgUpload} alt="" />
            </span>
              <input
                type="file"
                accept="image/*"
                className="input-file"
                onChange={(e) => UpdateImage(e,setImagefour,setFilefour)}/>
            {filefour === null ? (
              <></>
            ) : (
              <button
                type="button"
                onClick={() => DeletImage(setImagefour,setFilefour,Data.media[3].id)} >
              
                <img src={IconDelete} alt="" />
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item">
            {filefive === null ? (
              <></>
            ) : (
              <div className="over">
                <img src={filefive} alt="" />
              </div>
            )}
            <span>
              <img src={InputImgUpload} alt="" />
            </span>
              <input
                type="file"
                accept="image/*"
                className="input-file"
                onChange={(e) => UpdateImage(e,setImagefive,setFilefive)}/>
            {filefive === null ? (
              <></>
            ) : (
              <button
                type="button"
                onClick={() => DeletImage(setImagefive,setFilefive,Data.media[4].id)} >
                <img src={IconDelete} alt="" />
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="item">
            {filesix === null ? (
              <></>
            ) : (
              <div className="over">
                <img src={filesix} alt="" />
              </div>
            )}
            <span>
              <img src={InputImgUpload} alt="" />
            </span>
              <input
                type="file"
                accept="image/*"
                className="input-file"
                onChange={(e) => UpdateImage(e,setImagesix,setFilesix)}/>
            {filesix === null ? (
              <></>
            ) : (
              <button
                type="button"
                onClick={() => DeletImage(setImagesix,setFilesix,Data.media[5].id)} >
                <img src={IconDelete} alt="" />
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="invalid-feedback">
        {language === "en"
          ? "At Least One Value Must Be Entered"
          : "يجب أدخال قيمه واحده على الاقل"}
      </div>
    </div>
  );
}

export function InputName(props) {
  const { handleChange, language, validation, value } = props;
  return (
    <div
      className={
        validation === false && value === ""
          ? "form-group position-relative"
          : "form-group"
      }
    >
      <label>{language === "en" ? "Product Name" : "اسم المنتج"}</label>
      <input type="text" required
        className={
          validation === false && value === ""
            ? "form-control is-invalid"
            : "form-control"
        }
        placeholder={language === "en" ? "Product Name" : "اسم المنتج"}
        name="title" defaultValue={value}
        onChange={handleChange}
      />
      <div className="invalid-feedback">
        {language === "en"
          ? "This Field Must Be Entered"
          : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}

export function InputPrice(props) {
  const { handleChange, language, validation, value ,Newprice  ,Numberpercentage} = props;
  
  let newnumber = value - ((value / 100) *Numberpercentage);
  const [newprice, setNewprice] = useState(newnumber);
//  setNewprice(newnumber)

  return (
    <div className="form-group-password ">
      <div className="position-relative">

        <label> {language === "en" ? "Price " : "السعر"} </label>

        <div className="price-relative">
          <input type="number" required
            className={validation === false && value === ""? "form-control is-invalid": "form-control" }
            placeholder={language === "en" ? "Price " : "السعر"} name="price" defaultValue={value} onChange={handleChange} 
            onInput={(e)=> 
              {const nuwnumber = e.target.value - ((e.target.value / 100) * Numberpercentage);
                setNewprice(nuwnumber)}
            }/>
          <span className="dataprice"> {language === "en" ? "s.r" : " ر.س"}</span>
        </div>

        {validation === false && value === "" ?
          <div className="invalid-feedback">
            {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
          </div>
          : ""}


        <p className="discount-percentage">
          {language === "en" ? `*Please note that ${Numberpercentage}% tax will be deducted from this amount` 
          : `*يرجى العلم أنه سيتم خصم ${Numberpercentage}% ضريبة لاتي من هذا المبلغ`}
        </p>
      </div>

      <div className="will-receive">
        {language === "en" ? "Will Receive" : "سوف تستقبل"}
      </div>
      <div className="position-relative">
        
      <div className="price-relative">
          <input type="number"className= "form-control"name="price" defaultValue={newprice}/>
          <span className="dataprice"> {language === "en" ? "s.r" : " ر.س"}</span>
        </div>
      </div>
    </div>
  );
}

export function InputColor(props) {
  const { language, validation, selectedcolor, setSelectedcolor ,ArrayData ,NewColor } = props;

  const [color, setColor] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataColor(setLoading, setColor);
  }, [loading]);



  const oldArray = [];

  for (var i = 0; i < ArrayData.length; i++) {
    var obj = {};
    obj['value'] = ArrayData[i].id;
    obj['label'] = ArrayData[i].name;
    oldArray.push(obj);
  }
  
  const optionsArray = [];

  for (var i = 0; i < color.length; i++) {
    var obj = {};
    obj['value'] = color[i].id;
    obj['label'] = color[i].name;
    optionsArray.push(obj);
  }
 
const ChingeData=(e)=>{
  let newArray = [...e];
  console.log(newArray);
  for (let i = 0; i < newArray.length; i++) {
    NewColor.push(newArray[i].value)
  };
}

  return (
    <>
      {loading === false ? (
        ""
      ) : (
        <div className={validation === false && selectedcolor.length === 0 ? "form-group position-relative" : "form-group"}>
          <label>
            {" "}
            {language === "en" ? "Available Colors " : "الألوان المتاحة"}{" "}
          </label>
          
          <Select options={optionsArray} 
             isMulti className="basic-multi-select" classNamePrefix="select"
             name="colors" onChange={(e)=>ChingeData(e)} 
             placeHolder={language === "en"? "Add Size And Press Enter": "أضف المقاس واضغط على Enter"}
             defaultValue={[...oldArray]} />


          <div className="invalid-feedback">
            {language === "en"
              ? "At Least One Value Must Be Entered"
              : "يجب أدخال قيمه واحده على الاقل"}
          </div>
        </div>
      )}
    </>
  );
}

export function InputSize(props) {
  const { language, validation, selectedsize, setSelectedsize,ArrayData ,NewSize} = props;
  
  const [size, setSize] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetDataSize(setLoading, setSize);
  }, [loading]);

  
  const oldArray = [];

  for (var i = 0; i < ArrayData.length; i++) {
    var obj = {};
    obj['value'] = ArrayData[i].id;
    obj['label'] = ArrayData[i].name;
    oldArray.push(obj);
  }

  const optionsArray = []

  for (var i = 0; i < size.length; i++) {
    var obj = {};
    obj['value'] = size[i].id;
    obj['label'] = size[i].name;
    optionsArray.push(obj);
  }

const ChingeData=(e)=>{
  let newArray = [...e];
  console.log(newArray);
  for (let i = 0; i < newArray.length; i++) {
   NewSize.push(newArray[i].value)
  };
}
  return (
    <div
      className={
        validation === false && selectedsize.length === 0
          ? "form-group position-relative"
          : "form-group"
      }
    >
      <label>
        {" "}
        {language === "en" ? "Available sizes" : "المقاسات المتاحة"}{" "}
      </label>

      <Select options={optionsArray} 
             isMulti className="basic-multi-select" classNamePrefix="select"
             name="size" onChange={(e)=>ChingeData(e)} 
             placeHolder={language === "en"? "Add Size And Press Enter": "أضف المقاس واضغط على Enter"}
             defaultValue={[...oldArray]} />

      <div className="invalid-feedback">
        {language === "en"
          ? "At Least One Value Must Be Entered"
          : "يجب أدخال قيمه واحده على الاقل"}
      </div>
    </div>
  );
}

export function InputDes(props) {
  const { handleChange, language, validation, value } = props;
  return (
    <div
      className={
        validation === false && value === ""
          ? "form-group position-relative"
          : "form-group"
      }
    >
      <label>
        {language === "en" ? "Product Description " : " وصف المنتج "}
      </label>
      <textarea
        rows="3" defaultValue={value}
        placeholder={
          language === "en" ? "Product Description " : " وصف المنتج "
        }
        name="description"
        required
        className={
          validation === false && value === ""
            ? "form-control is-invalid"
            : "form-control"
        }
        onChange={handleChange}
      ></textarea>
      <div className="invalid-feedback">
        {language === "en"
          ? "This Field Must Be Entered"
          : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}

export function InputCategory(props) {
  const { type, setType, Data, language, validation } = props;

  const handleVlaue = (e) => {
    const value = e.target.value;
    setType(value);
  };
  return (
    <div
      className={ validation === false && type === "" ? "form-group selectbox position-relative": "form-group selectbox"}
    >
      <label> {language === "en" ? "Product Category" : "فئة المنتج"}</label>
      <select required
        className={
          validation === false && type === ""? "form-control is-invalid": "form-control"}
        onChange={handleVlaue} value={type}>
        <option> </option>
        {Data.map((item, index) => (
          <option value={item.id} key={index}>
            {item.name}
          </option>
        ))}
      </select>
      <div className="invalid-feedback">
        {language === "en"
          ? "This Field Must Be Entered"
          : "يجب إدخال هذا الحقل"}
      </div>
    </div>
  );
}
