import React, { useContext } from 'react'
import CardProudect from '../../shared/cardproudect/index.jsx';
import CardFavorite from './cardfavorite.jsx';
function FavoriteAll(props) {
    const {Data ,setData}=props;

    return (
        <div className="favoriteall">
        <div className="row">
            {Data.map(item =>
                       <div className="col-md-6 col-lg-3" key={item.id}>
                       <CardFavorite 
                        Image={item.media.length === 0 ? "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg"
                        : item.media[0].image}
                         Price={`SR ${item.price}`}  Item={item} 
                            Data={item.title} Favoritecard={true} Id={item.id} Vailable={item.in_stock} setData={setData}/>
                </div>
                )}
        </div>

        </div>
    )
}

export default FavoriteAll;