import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import OffersScreen from '../../../components/buyer/offers/offerscreen';
import OffersTop from '../../../components/buyer/offers/offertop';
import ProudectsAll from '../../../components/buyer/proudects/allproudect/index.jsx';
import ProudectsFilter from '../../../components/shared/filter/index.jsx';
import Paginate from '../../../components/shared/paginate/index.jsx';
import SortIcon from '../../../components/shared/sort';
import { GetDataCoupons } from '../../../api/actions';
import Loading from '../../../layout/loading/loading';
import { TokenLate, UserIdlate } from '../../../api';
import ButtonFillter from '../../../components/shared/buttonfillter';
import ProudectsEmpty from '../../../components/buyer/proudects/empty';

function Offers() {

    
  const { page, filtersort ,filtercategory,filtersubcategory,filterminvalue , filtermaxvalue} = useParams();


  let navigate = useNavigate();
  const pageCount =  +page.slice(page.indexOf(":") + 1, page.length);
  const [perpage, setPerpage] = useState(0);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const minValue = +filterminvalue.slice(filterminvalue.indexOf(":") + 1, filterminvalue.length);
  const maxValue =  +filtermaxvalue.slice(filtermaxvalue.indexOf(":") + 1, filtermaxvalue.length);
  const categoryid = +filtercategory.slice(filtercategory.indexOf(":") + 1, filtercategory.length);
  const subcategoryid = +filtersubcategory.slice(filtersubcategory.indexOf(":") + 1, filtersubcategory.length);
  const sortdata = filtersort.slice(filtersort.indexOf(":") + 1, filtersort.length);

  
    const [data, setData] = useState([]);


    useEffect(() => {
        if (UserIdlate === 3 && TokenLate !== null) {
            window.location.pathname = `/`
        }
    }, []);


    const [categorid, setCategorid] = useState("");
    const [togglefilter, setTogglefilter] = useState("hidefillter");

    useEffect(() => {
        GetDataCoupons(pageCount, setData, setLoading, setProducts, setPerpage)
    }, [loading]);

    const handlePageClick = (data) => {
        let number = 1 + data.selected;
        navigate(`/coupons/page:${number}/sortorder:${sortdata}/filtercategory:${categoryid}/filtersubcategory:${subcategoryid}/minValue:${minValue}/maxValue:${maxValue}`);
        setLoading(false)
    };

    const ToggleFilterclass = () => {
        if (togglefilter === "hidefillter") {
            setTogglefilter("showfillter")
        } else {
            setTogglefilter("hidefillter")
        }
    }

    return (
        <>
            {loading === false ?
                <Loading />
                :
                <section className='offers'>
                    <OffersScreen />
                    <div className="container">
                        <OffersTop Data={data} />


                        {products.length === 0 ?
                            <ProudectsEmpty /> :
                            <>
                                <ButtonFillter ToggleFilterclass={ToggleFilterclass} />

                                <div className="proudects__all">
                                    <div className="row">
                                        <div className="col-12 col-lg-9">
                                            <SortIcon setLoading={setLoading}/>
                                            <ProudectsAll Products={products} />
                                        </div>
                                        <ProudectsFilter togglefilter={togglefilter} setTogglefilter={setTogglefilter} setLoading={setLoading}  />
                                    </div>

                                    <div className="row">
                                        <div className="col-9">
                                            <Paginate perpage={perpage} handlePageClick={handlePageClick} pagenumber={pageCount}/>
                                        </div>
                                        <div className="col-3">
                                        </div>
                                    </div>
                                </div>
                            </>}


                    </div>
                </section>
            }
        </>
    )
}

export default Offers;