import React from 'react'
import SellerSingUprow from '../../../components/seller/auth/singup/singup';
// import SellerSingUprow from '../../../components/seller/auth/singup/index';

function SellerSingUp() {
  return (
    <section className='sellersingin'>
      <div className="container">
        <SellerSingUprow/>
      </div>
    </section>
  )
}

export default SellerSingUp;