import axios from "axios";
import { Api, languagelate, TokenLate } from "./index.js";
// Feka Api

export const GetDataHomeBuyer = async (setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}home`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
      Authorization: `Bearer ${TokenLate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const GetDataHomeSeller = async (setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}home`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
      console.log(error);
      /*
      if (error.response.status === 500) {
        window.location.pathname = `/pageerror`
      }
      */
    });
};
export const GetDataSettings = async (setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}settings`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data.socials);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const GetDataAbout = async (setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}settings`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setLoading(true);
      setData(response.data.data.about);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const GetDataPrivacypolicy = async (setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}settings`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setLoading(true);
      setData(response.data.data.policy);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const GetDataTrems = async (setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}settings`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setLoading(true);
      setData(response.data.data.terms);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const SendDataContactus = async (
  values,
  setMessageerror,
  setMessagesuccessfully
) => {
  const options = {
    method: "POST",
    url: `${Api}contact`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data: JSON.stringify({
      name: values.name,
      email: values.email,
      phone: values.phone,
      message: values.message,
    }),
  };
  await axios(options)
    .then(function (response) {
      setMessagesuccessfully(response.data.message);
      window.location.reload();
    })
    .catch(function (error) {
      setMessageerror(error.response.data.message);
    });
};
export const UpdateProfile = async (
  Data,
  image,
  setMessage,
  setLoadingbutton
) => {
  var data = new FormData();
  if (image !== null) {
    data.append("image", image);
  }

  data.append("name", Data.name);
  data.append("phone", Data.phone);
  data.append("email", Data.email);
  data.append("device_id", "default");
  data.append("device_type", "web");

  const options = {
    method: "post",
    url: `${Api}update-profile`,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data,
  };
  await axios(options)
    .then(function (response) {
      setMessage("");
      window.location.pathname = `/profile`;
      setLoadingbutton(false);
    })
    .catch(function (error) {
      setMessage(error.response.data.message);

      setLoadingbutton(false);
    });
};
export const GetDataCoupons = async (
  page,
  setData,
  setLoading,
  setProducts,
  setPerpage
) => {
  const options = {
    method: "get",
    url: `${Api}coupons?page=${page}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data.coupons);
      console.log(response);
      setPerpage(
        Math.ceil(
          response.data.data.meta.total / response.data.data.meta.per_page
        )
      );
      setProducts(response.data.data.products);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const GetProduct = async (Id, setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}products/${Id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
      Authorization: `Bearer ${TokenLate}`,
    },
  };
  axios(options)
    .then(function (response) {
      setLoading(true);
      setData(response.data.data.product);
    })
    .catch(function (error) {
      setLoading(true);
      console.log(error.code);
      if (error.code === "ERR_BAD_REQUEST") {
        window.location.pathname = `/`;
      }
    });
};
export const GetDataProfile = async (setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}get-profile`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data.user);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const GetShippingAddresses = async (setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}shipping-addresses`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data.shipping_addresses);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const GetDataNotifications = async (
  setData,
  setLoading,
  pageCount,
  setPerpage
) => {
  const options = {
    method: "get",
    url: `${Api}notifications?page=${pageCount}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      console.log(response);
      setData(response.data.data.notifications.data);
      setPerpage(
        Math.ceil(
          response.data.data.notifications.pagination.total_items /
            response.data.data.notifications.pagination.per_page
        )
      );
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const DeleteNotification = (Id) => {
  const options = {
    method: "DELETE",
    url: `${Api}notifications/${Id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  axios(options)
    .then(function (response) {
      window.location.reload();
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const GetDataFavorite = async (setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}favorites`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data.favorites);
      setLoading(true);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};
export const AddToFavourite = async (id) => {
  const options = {
    method: "post",
    url: `${Api}products/${id}/favorite`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  axios(options)
    .then(function (response) {})
    .catch(function (error) {
      console.log(error);
    });
};
export const RemoveToFavourite = async (id) => {
  const options = {
    method: "post",
    url: `${Api}products/${id}/favorite`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  axios(options)
    .then(function (response) {
      //window.location.reload();
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const AddToCart = async (
  id,
  numberquantity,
  numbersize,
  numbercolor
) => {
  var data = new FormData();
  data.append("product_id", id);
  data.append("quantity", numberquantity);
  data.append("size_id", numbersize);
  data.append("color_id", numbercolor);

  const options = {
    method: "post",
    url: `${Api}cart`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data,
  };
  await axios(options)
    .then(function (response) {})
    .catch(function (error) {
      console.log(error);
    });
};
export const GetDataCart = async (setData, setItemsdata, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}cart`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data.cart);
      setItemsdata(response.data.data.cart.items);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const AddAddresses = async (
  firstname,
  familyname,
  addressdetails,
  phone,
  centerrequest,
  notify,
  setMessage
) => {
  var data = new FormData();
  data.append("name", firstname);
  data.append("first_name", firstname);
  data.append("last_name", familyname);
  data.append("address", addressdetails);
  data.append("phone", phone);
  data.append("lat", centerrequest.lat);
  data.append("lng", centerrequest.lng);
  if (notify == true) {
    data.append("is_default", 1);
  } else {
    data.append("is_default", 0);
  }

  const options = {
    method: "post",
    url: `${Api}shipping-addresses`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data,
  };
  await axios(options)
    .then(function (response) {
      document.getElementById("btn-close=mpdalmaptwo").click();
      window.location.reload();
    })
    .catch(function (error) {
      console.log(error.response.data.message);
      setMessage(error.response.data.message);
    });
};
export const RemoveAddresses = async (id) => {
  const options = {
    method: "delete",
    url: `${Api}shipping-addresses/${id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  axios(options)
    .then(function (response) {
      //window.location.reload();
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const UpdateCartItem = (Id, quantity) => {
  var data = new FormData();
  data.append("quantity", quantity);

  const options = {
    method: "PUT",
    url: `${Api}cart/${Id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data,
  };
  axios(options)
    .then(function (response) {
      //window.location.reload();
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const SelectAddresses = async (
  Id,
  setTotal,
  setOrderid,
  setLoadingbutton
) => {
  var data = new FormData();
  data.append("shipping_address_id", Id);

  const options = {
    method: "post",
    url: `${Api}set-address`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data,
  };
  await axios(options)
    .then(function (response) {
      setTotal(response.data.data.cart.total);
      setOrderid(response.data.data.cart.id);
      setLoadingbutton(false);
    })
    .catch(function (error) {
      setLoadingbutton(false);
    });
};
export const ApplyCoupon = async (
  code,
  setMessage,
  setCponeactive,
  setTotalcopune,
  setDiscountcopune
) => {
  var data = new FormData();
  data.append("code", code);

  const options = {
    method: "post",
    url: `${Api}apply-coupon`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data,
  };
  await axios(options)
    .then(function (response) {
      setTotalcopune(response.data.data.cart.total);
      setMessage(response.data.message);

      setDiscountcopune(response.data.data.cart.discount);
      setCponeactive(true);
    })
    .catch(function (error) {
      console.log(error.response.data.message);
      setMessage(error.response.data.message);
      setCponeactive(false);
    });
};
export const AddRevwe = async (Id, rating, message) => {
  var data = new FormData();
  data.append("rate", rating);
  data.append("comment", message);
  data.append("product_id", Id);

  const options = {
    method: "post",
    url: `${Api}rates`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data,
  };
  await axios(options)
    .then(function (response) {
      console.log(response);
      document.getElementById("modalpouperevewe-btn").click();
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    });
};
export const GetCurrentOrders = async (
  pageCount,
  statusfilter,
  setData,
  setLoading,
  setPerpage
) => {
  const options = {
    method: "get",
    url: `${Api}order-items?page=${pageCount}&status=${statusfilter}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data.order_items);
      setPerpage(
        Math.ceil(
          response.data.data.meta.total / response.data.data.meta.per_page
        )
      );
      setLoading(true);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};

export const GetCurrentSellerOrders = async (
  pageCount,
  statusfilter,
  setData,
  setLoading,
  setPerpage
) => {
  const options = {
    method: "get",
    url: `${Api}order-items?page=${pageCount}&status=${statusfilter}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data.order_items);
      setPerpage(
        Math.ceil(
          response.data.data.meta.total / response.data.data.meta.per_page
        )
      );
      setLoading(true);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};

export const GetOnlayOrdersBuyer = async (Id, setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}orders/${Id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data.order);
      setLoading(true);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};
export const GetOnlayOrders = async (Id, setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}order-items/${Id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data.order_item);
      setLoading(true);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};

export const GetOnlayOrdersSeller = async (Id, setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}order-items/${Id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data.order_item);
      setLoading(true);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};

// chinge status
export const ChingestatusOrderItem = async (Id, value, setMessage) => {
  var data = new FormData();
  data.append("status", value);

  const options = {
    method: "post",
    url: `${Api}order-items/${Id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data,
  };
  await axios(options)
    .then(function (response) {
      console.log(response);
      //window.location.pathname = `/currentrequests`;
      //  window.location.reload();
    })
    .catch(function (error) {
      setMessage(error.response.data.message);
    });
};

export const CanselOrderItem = async (Id, setMessage) => {
  var data = new FormData();
  data.append("status", "canceled");

  const options = {
    method: "post",
    url: `${Api}orders/${Id}/cancel`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data,
  };
  await axios(options)
    .then(function (response) {
      console.log(response);
      window.location.reload();
    })
    .catch(function (error) {
      setMessage(error.response.data.message);
    });
};
export const GetDataFilter = async (
  setprice,
  setLoading,
  set_minValue,
  set_maxValue,
  setGetCategories
) => {
  const options = {
    method: "get",
    url: `${Api}categories`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setprice(response.data.data);
      setLoading(true);
      setGetCategories(response.data.data.categories);
      set_minValue(parseInt(response.data.data.min_price));
      set_maxValue(parseInt(response.data.data.max_price) + 500);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};
export const GetProductsAll = async (
  pageCount,
  minValue,
  maxValue,
  categories,
  subcategoryid,
  setPerpage,
  setProducts,
  sortdata,
  setLoading
) => {
  const options = {
    method: "get",
    url: `${Api}products?page=${pageCount}
    ${
      subcategoryid === 0
        ? `&${categories === 0 ? "" : `category_id=${categories}`}`
        : `&${`category_id=${subcategoryid}`}`
    }&${maxValue === 0 ? "" : `price=${minValue},${maxValue}`}
    &${sortdata === "all" ? "" : `order=${sortdata}`}`,

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setProducts(response.data.data.products);
      console.log(response.data.data.meta);
      setPerpage(
        Math.ceil(
          response.data.data.meta.total / response.data.data.meta.per_page
        )
      );
      setLoading(true);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};
export const GetCategoryFilter = async (
  Id,
  setData,
  setLoading,
  set_minValue,
  set_maxValue,
  setNameCategory,
  setGetCategorieschildren
) => {
  const options = {
    method: "get",
    url: `${Api}categories/${Id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setData(response.data.data);
      setNameCategory(response.data.data.category.name);
      setGetCategorieschildren(response.data.data.category.children);
      setLoading(true);
      set_minValue(parseInt(response.data.data.min_price));
      set_maxValue(parseInt(response.data.data.max_price) + 1000);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};
export const GetStoreDetails = async (
  Id,
  setName,
  setphone,
  setemail,
  setcity,
  setrate,
  setComments,
  setproducts,
  setClientscount,
  setLoading
) => {
  const options = {
    method: "get",
    url: `${Api}providers/${Id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  axios(options)
    .then(function (response) {
      setLoading(true);
      setproducts(response.data.data.provider.products);
      setrate(response.data.data.provider.rate);
      setClientscount(response.data.data.provider.clients_count);
      setName(response.data.data.provider.business_name);
      setphone(response.data.data.provider.business_phone);
      setemail(response.data.data.provider.business_email);
      setcity(response.data.data.provider.city.name);
      setComments(response.data.data.provider.comments);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const Setpayment = async (total, orderid, userid, setLoadingbutton) => {
  var data = new FormData();
  data.append("price", total.toFixed(2));
  data.append("order_id", orderid);
  data.append("user_id", userid);

  const options = {
    method: "post",
    url: `${Api}payment`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data,
  };
  await axios(options)
    .then(function (response) {
      setLoadingbutton(false);
      window.open(response.data.data, "_blank");
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
      setLoadingbutton(false);
    });
};
export const SetpaymentInstallment = async (
  total,
  orderid,
  userid,
  setLoadingbutton
) => {
  var data = new FormData();
  data.append("price", total.toFixed(2));
  data.append("order_id", orderid);
  data.append("user_id", userid);
  const options = {
    method: "post",
    url: `${Api}tamara-pay`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data,
  };
  await axios(options)
    .then(function (response) {
      setLoadingbutton(false);
      window.open(response.data.data.checkout_url, "_blank");
      console.log(response.data.data.checkout_url);
    })
    .catch(function (error) {
      console.log(error);
      setLoadingbutton(false);
    });
};

export const ProudectSearch = async (word) => {
  const options = {
    method: "get",
    url: `${Api}products?word=${word}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  axios(options)
    .then(function (response) {
      window.location.pathname = `/proudectsearch/${word}/${1}`;
    })
    .catch(function (error) {});
};
export const ProudectFilterSearch = async (
  word,
  page,
  setLoading,
  setProducts,
  setPerpage
) => {
  const options = {
    method: "get",
    url: `${Api}products?word=${word}&page=${page}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  axios(options)
    .then(function (response) {
      setLoading(true);
      console.log(response.data.data.products);
      setProducts(response.data.data.products);
      setPerpage(
        Math.ceil(
          response.data.data.meta.total / response.data.data.meta.per_page
        )
      );
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const GetDataCategories = async (setLoading, setGetCategories) => {
  const options = {
    method: "get",
    url: `${Api}categories`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setLoading(true);
      setGetCategories(response.data.data.categories);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};
export const GetDataPercentage = async (setLoading, setPercentage) => {
  const options = {
    method: "get",
    url: `${Api}app-percentage`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setLoading(true);
      setPercentage(response.data.data.app_percentage);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};
export const GetDataCities = async (setLoading, setGetCaty) => {
  const options = {
    method: "get",
    url: `${Api}cities`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setGetCaty(response.data.data.cities);
      setLoading(true);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};
export const Createaccount = async (
  StateArrayone,
  StateArraytwo,
  fileone,
  fileotwo,
  StateArrayfour,
  StateArrayfive,
  filethree,
  setMessage,
  setLoadingbutton
) => {
  sessionStorage.setItem("emaillate", JSON.stringify(StateArrayone.email));

  var data = new FormData();
  data.append("user_type", 3);
  data.append("device_id", "granted");
  data.append("device_type", "web");
  // screen One
  data.append("name", StateArrayone.name);
  data.append("phone", StateArrayone.phone);
  data.append("email", StateArrayone.email);
  data.append("password", StateArrayone.password);
  data.append("password_confirmation", StateArrayone.confirmpassword);
  // screen Two
  data.append("business_name", StateArraytwo.storename);
  data.append("business_phone", StateArraytwo.storephone);
  data.append("business_email", StateArraytwo.storeemail);
  data.append("business_bio", StateArraytwo.description_store);
  data.append("city_id", StateArraytwo.cities);
  data.append("region_id", StateArraytwo.subcaty);
  data.append("categories[]", StateArraytwo.category);
  data.append("business_delivery", StateArraytwo.business_delivery);
  // screen Three
  data.append("business_licence_image", fileone);
  data.append("identity_image", fileotwo);
  // screen Four
  data.append("bank_account_name", StateArrayfour.nameuserbanck);
  data.append("bank_name", StateArrayfour.namebanck);
  data.append("bank_branch_name", StateArrayfour.branchname);
  data.append("bank_account_num", StateArrayfour.accountnumber);
  data.append("bank_swift_code", StateArrayfour.swiftcode);
  // screen Five
  data.append("tax_registration_num", StateArrayfive.registrationnumber);
  if(filethree !== null){
    data.append("tax_registration_num_image", filethree);
  }

  const options = {
    method: "POST",
    url: `${Api}register`,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data: data,
  };

  await axios(options)
    .then(function (response) {
      window.location.pathname = `/sellercode`;
      sessionStorage.setItem(
        "verfiy_token",
        JSON.stringify(response.data.data.verfiy_token)
      );

      setLoadingbutton(false);
    })
    .catch(function (error) {
      setLoadingbutton(false);
      setMessage(error.response.data.message);
    });
};
export const AddProudect = async (
  state,
  type,
  sizes,
  colors,
  Images,
  setMessage,
  setLoadingbutton
) => {
  var data = new FormData();
  data.append("title", state.title);
  data.append("price", state.price);
  data.append("desc", state.description);
  data.append("category_id", type);

  for (let i = 0; i < sizes.length; i++) {
    data.append("sizes[]", sizes[i].value);
  }
  for (let i = 0; i < colors.length; i++) {
    data.append("colors[]", colors[i].value);
  }
  for (let i = 0; i < Images.length; i++) {
    data.append("media[]", Images[i]);
  }

  const options = {
    method: "post",
    url: `${Api}products`,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate}`,
    },
    data,
  };
  await axios(options)
    .then(function (response) {
      window.location.pathname = `/proudectseller/${1}`;
      setLoadingbutton(false);
    })
    .catch(function (error) {
      setMessage(error.response.data.message);
      setLoadingbutton(false);
    });
};
export const updateProduct = async (
  id,
  state,
  type,
  sizes,
  NewSize,
  colors,
  NewColor,
  Images,
  setMessage,
  setLoadingbutton
) => {
  var data = new FormData();
  data.append("title", state.title);
  data.append("price", state.price);
  data.append("desc", state.description);
  data.append("category_id", type);

  if (NewSize.length === 0) {
    for (let i = 0; i < sizes.length; i++) {
      data.append("sizes[]", sizes[i]);
    }
  } else {
    for (let i = 0; i < NewSize.length; i++) {
      data.append("sizes[]", NewSize[i]);
    }
  }

  if (NewColor.length === 0) {
    for (let i = 0; i < colors.length; i++) {
      data.append("colors[]", colors[i]);
    }
  } else {
    for (let i = 0; i < NewColor.length; i++) {
      data.append("colors[]", NewColor[i]);
    }
  }

  for (let i = 0; i < Images.length; i++) {
    data.append("media[]", Images[i]);
  }

  const options = {
    method: "post",
    url: `${Api}update-product/${id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate}`,
    },
    data,
  };
  await axios(options)
    .then(function (response) {
      window.location.pathname = `/proudectseller/${1}`;
      setLoadingbutton(false);
    })
    .catch(function (error) {
      setLoadingbutton(false);
      setMessage(error.response.data.message);
    });
};
export const GetDataColor = async (setLoading, setColor) => {
  const options = {
    method: "get",
    url: `${Api}colors`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setLoading(true);
      setColor(response.data.data.colors);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};
export const GetDataSize = async (setLoading, setSize) => {
  const options = {
    method: "get",
    url: `${Api}sizes`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setLoading(true);
      setSize(response.data.data.sizes);
    })
    .catch(function (error) {
      console.log(error);
      setLoading(true);
    });
};
export const GetMyProductsAll = async (
  pageCount,
  setLoading,
  setProducts,
  setPerpage,
  sortdata
) => {
  const options = {
    method: "get",
    url: `${Api}products?page=${pageCount}&
    ${sortdata === "" ? "" : `category_id=${sortdata}`}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
      Authorization: `Bearer ${TokenLate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setLoading(true);
      setPerpage(
        Math.ceil(
          response.data.data.meta.total / response.data.data.meta.per_page
        )
      );
      setProducts(response.data.data.products);
    })
    .catch(function (error) {
      setLoading(true);
      console.log("handle error");
      console.log(error.response.data);
    });
};
export const GetMyProduct = async (Id, setData, setLoading) => {
  const options = {
    method: "get",
    url: `${Api}products/${Id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
      Authorization: `Bearer ${TokenLate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setLoading(true);
      setData(response.data.data.product);
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const RemoveProduct = async (id) => {
  const options = {
    method: "delete",
    url: `${Api}products/${id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  axios(options)
    .then(function (response) {
      window.location.reload();
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const GetMyRates = async (Id, setData, setLoading, setPerpage) => {
  const options = {
    method: "get",
    url: `${Api}rates?provider_id=${Id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
      Authorization: `Bearer ${TokenLate}`,
    },
  };
  await axios(options)
    .then(function (response) {
      setLoading(true);
      setData(response.data.data);
      setPerpage(
        Math.ceil(
          response.data.data.meta.total / response.data.data.meta.per_page
        )
      );
    })
    .catch(function (error) {
      setLoading(true);
    });
};
export const UpdateProfileSeller = async (
  values,
  category,
  cities,
  subcaty,
  fileone,
  fileotwo,
  filethree,
  setMessage,
  setLoadingbutton
) => {
  var data = new FormData();
  data.append("user_type", 3);
  data.append("device_id", "granted");
  data.append("device_type", "web");
  data.append("name", values.name);
  data.append("phone", values.phone);
  data.append("email", values.email);

  data.append("business_name", values.storename);
  data.append("business_phone", values.storephone);
  data.append("business_email", values.storeemail);
  data.append("business_bio", values.description_store);
  data.append("business_delivery", values.business_delivery);

  data.append("bank_name", values.namebanck);
  data.append("bank_branch_name", values.branchname);
  data.append("bank_account_name", values.nameuserbanck);
  data.append("bank_account_num", values.accountnumber);
  data.append("bank_swift_code", values.swiftcode);
  data.append("tax_registration_num", values.registrationnumber);

  data.append("city_id", cities);
  data.append("region_id", subcaty);
  data.append("categories[]", category);

  if (fileone !== null) {
    data.append("business_licence_image", fileone);
  }
  if (fileotwo !== null) {
    data.append("identity_image", fileotwo);
  }
  if (filethree !== null) {
    data.append("tax_registration_num_image", filethree);
  }

  const options = {
    method: "POST",
    url: `${Api}update-profile`,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
      Authorization: `Bearer ${TokenLate}`,
    },
    data: data,
  };

  await axios(options)
    .then(function (response) {
      window.location.pathname = `/storedata`;

      setLoadingbutton(false);
    })
    .catch(function (error) {
      console.log(error);
      setMessage(error.response.data.message);

      setLoadingbutton(false);
    });
};
export const RemoveImageProduct = async (id) => {
  const options = {
    method: "delete",
    url: `${Api}media/${id}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
  };
  axios(options)
    .then(function (response) {
      // window.location.reload();
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const GetcartIcon = async (setLoading, setCart) => {
  const options = {
    method: "get",
    url: `${Api}cart`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TokenLate}`,
    },
  };
  axios(options)
    .then(function (response) {
      const Arraycart = response.data.data.cart.items;
      const NewArrayCart = [];
      for (let index = 0; index < Arraycart.length; index++) {
        NewArrayCart.push(Arraycart[index].product.id);
      }

      setCart(NewArrayCart);
      setLoading(true);
    })
    .catch(function (error) {
      setLoading(true);
    });
};

export const ChangeLang = async (Data) => {
  var data = new FormData();
  data.append("lang", Data);

  const options = {
    method: "post",
    url: `${Api}change-lang`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${TokenLate}`,
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data,
  };
  axios(options)
    .then(function (response) {
      localStorage.setItem("languagelate", JSON.stringify(Data));
      window.location.reload();
    })
    .catch(function (error) {});
};

export const ReSendCode = async (setMessage, setModalOpen) => {
  var data = new FormData();
  data.append("email_phone", JSON.parse(sessionStorage.getItem("emaillate")));
  data.append("forget_pass", 0);

  const options = {
    method: "POST",
    url: `${Api}send-code`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": `${languagelate === null ? "ar" : languagelate}`,
    },
    data: data,
  };
  axios(options)
    .then(function (response) {
      sessionStorage.setItem(
        "verfiy_token",
        JSON.stringify(response.data.data.verfiy_token)
      );
      setModalOpen(true);
    })
    .catch(function (error) {
      setMessage(error.response.data.message);
      setModalOpen(true);
    });
};
