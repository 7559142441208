import React, { useContext, useEffect, useState } from 'react'
import { InputCategory, InputColor, InputDes, InputImages, InputName, InputPrice, InputSize } from './inputs/inputesadd';
import { GetDataCategories, AddProudect } from '../../../api/actions.js';
import { Authcontext } from '../../../store/context';

function FormAdd(props) {
    const { getCategories ,Numberpercentage} = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    const [state, setState] = useState({
        title: "",
        price: "",
        description: "",
    });
    const [validation, setvalidation] = useState("hide");
    const [selectedsize, setSelectedsize] = useState([]);


    const [imageone, setImageone] = useState(null);
    const [imagetwo, setImagetwo] = useState(null);
    const [imagethree, setImagethree] = useState(null);
    const [imagefour, setImagefour] = useState(null);
    const [imagefive, setImagefive] = useState(null);
    const [imagesix, setImagesix] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [type, setType] = useState("");
    const [newprice, setNewprice] = useState("");
    const [message, setMessage] = useState("");
    const [loadingbutton, setLoadingbutton] = useState(false);

    const [selectedcolor, setSelectedcolor] = useState([]);

    const handleChange = (e) => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value,
        });

    };

    const handleSubmit = (e) => {
        e.preventDefault();

        var mammals = new Array(imagesix, imageone, imagetwo, imagethree, imagefour, imagefive);
        console.log(mammals);
        var animalString = [];
        for (var i = 0; i < mammals.length; i++) {
            animalString.push(mammals[i]);
        }
        function removeChars(element, index, array) {
            return (element !== null);
        }
        var ImageSelect = animalString.filter(removeChars);
        setSelectedImages(ImageSelect);

        console.log(selectedcolor);

        if (state.title === "" ||
            state.price === "" ||
            state.description === "" ||
            selectedsize.length === 0 ||
            selectedcolor.length === 0) {
            setvalidation(false);
        } else {
            setvalidation(true);
            setLoadingbutton(true)
            AddProudect(state, type, selectedsize, selectedcolor, ImageSelect, setMessage, setLoadingbutton)
        }
    }


    return (
        <div className="proudect__form">
            <form>

                <InputImages
                    imageone={imageone} setImageone={setImageone}
                    imagetwo={imagetwo} setImagetwo={setImagetwo}
                    imagethree={imagethree} setImagethree={setImagethree}
                    imagesix={imagesix} setImagesix={setImagesix}
                    imagefour={imagefour} setImagefour={setImagefour}
                    imagefive={imagefive} setImagefive={setImagefive}
                    selectedImages={selectedImages} language={language} validation={validation} />


                <InputName handleChange={handleChange} language={language}
                    validation={validation} value={state.title} />

                <InputCategory type={type} setType={setType} Data={getCategories}
                    validation={validation} language={language} />

                <InputPrice handleChange={handleChange} language={language}
                    validation={validation} value={state.price}  Newprice={newprice} setNewprice={setNewprice}
                    Numberpercentage={Numberpercentage}/>
                <InputColor language={language} selectedcolor={selectedcolor} setSelectedcolor={setSelectedcolor}
                    validation={validation} />

                <InputSize language={language}
                    validation={validation} selectedsize={selectedsize} setSelectedsize={setSelectedsize} />

                <InputDes handleChange={handleChange} language={language}
                    validation={validation} value={state.description} />

                <span className="errorfiled">{message}</span>

                <div className="end_form">
                    {loadingbutton === false ?
                        <button className="btn btn-save" type="button" onClick={handleSubmit}>
                            {language === "en" ? "Save" : "حفظ"}
                        </button>
                        :
                        <button className="btn btn-save button-disabled" type="button">
                            {language === "en" ? "Loading" : "جار التحميل"}
                            <span className="spinner"></span>
                        </button>}
                    <button className="btn btn-cancel" type="button"
                        data-bs-dismiss="modal" aria-label="Close">
                        {language === "en" ? "Cancel" : "إلغاء"}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default FormAdd;