import React, { useContext } from 'react';
import ImageCall from "../../../images/icon/icon_call.png";
import Iconsms from "../../../images/icon/Icon_sms.png";
import Iconlocation from "../../../images/icon/icon_location.png";
import IconStar from "../../../images/icon/Icon_star.png";
import { Authcontext } from '../../../store/context';

function Details(props) {
    const { Name, Phone, Email, City, rate, clientscount } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    return (
        <div className='storedetails__details'>
            <h6>{Name}</h6>
            <div className="details-row">

                <div className="items-data">
                    <span className="item">
                        <span className="icon">
                            <img src={ImageCall} alt="ImageCall" />
                        </span>
                        <span className="data">{Phone}</span>
                    </span>
                    <span className="item">
                        <span className="icon">
                            <img src={Iconsms} alt="ImageCall" />
                        </span>
                        <span className="data">{Email}</span>
                    </span>
                    <span className="item">
                        <span className="icon">
                            <img src={Iconlocation} alt="ImageCall" />
                        </span>
                        <span className="data">{City}</span>
                    </span>
                </div>

                <div className="items-data-center">
                    <span className="text">
                        {language === "ar" ? "تقييم البائع" : "Seller Review"}
                    </span>

                    <span className="star">
                        <span className="text">{rate}</span>
                        <img src={IconStar} alt="IconStar" />
                    </span>
                </div>

                <div className="items-data-center">
                    <span className="text">
                        {language === "ar" ? "العملاء" : "Customers"}

                    </span>

                    <span className="star">{clientscount}</span>
                </div>
            </div>
        </div>
    )
}

export default Details;