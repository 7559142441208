import React, { useEffect, useContext, useState } from 'react'
import { Formik } from 'formik';
import * as Yup from "yup";
import { GetDataCategories, GetDataCities } from '../../../../../api/actions';
import { Authcontext } from '../../../../../store/context';
import { InputText, InputPhonecountry, InputTextArea, InputCities, InputSelect, InputPrice,InputEmail } from './input';

function Form_Two(props) {
    const { setStutesform, setStateArraytwo, StateArraytwo } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;


    const [getCategories, setGetCategories] = useState([]);
    const [loadingcategories, setLoadingcategories] = useState(false);

    const [getCaty, setGetCaty] = useState([]);
    const [getsubcaty, setGetSubCaty] = useState([]);
    const [loadingcities, setLoadingcities] = useState(false);


    const state = {
        storename: StateArraytwo.storename === undefined ? "" : StateArraytwo.storename,
        storephone: StateArraytwo.storephone === undefined ? "" : StateArraytwo.storephone,
        storeemail: StateArraytwo.storeemail === undefined ? "" : StateArraytwo.storeemail,
        description_store: StateArraytwo.description_store === undefined ? "" : StateArraytwo.description_store,
        cities: StateArraytwo.cities === undefined ? "" : StateArraytwo.cities,
        subcaty: StateArraytwo.subcaty === undefined ? "" : StateArraytwo.subcaty,
        category: StateArraytwo.category === undefined ? "" : StateArraytwo.category,
        business_delivery: StateArraytwo.business_delivery === undefined ? "" : StateArraytwo.business_delivery, 
    };

    useEffect(() => {
        GetDataCategories(setLoadingcategories, setGetCategories)
        GetDataCities(setLoadingcities, setGetCaty)
    }, [loadingcategories]);

    const onSubmit = (values) => {
        //console.log(values);
        //console.log(StateArray);
        setStutesform(3)
        setStateArraytwo(values)
    }

    const handleBackform = (e) => {
        e.preventDefault();
        setStutesform(1)
    }

    const form = (props) => {
        const { handleSubmit, handleBlur, setFieldValue } = props;
        return <form onSubmit={handleSubmit}>
            {/*=== Input Name Of The Store  ===*/}
            <InputText Error={props.errors.storename} valueInput={props.values.storename} Type={"text"}
                Label={language === "en" ? "Store name" : "اسم المتجر"} Name={"storename"} />
            {/*=== Input Phone Of The Store  ===*/}
            <InputPhonecountry Error={props.errors.storephone} valueInput={props.values.storephone} Type={"tel"}
                Label={language === "en" ? "Store Phone" : "رقم جوال المتجر"} Name={"storephone"} />
            {/*=== Input Email Of The Store  ===*/}
                <InputEmail Error={props.errors.storeemail} valueInput={props.values.storeemail}
                Label={language === "en" ? "Store Email" : "البريد الإلكتروني الخاص بالمتجر"} Name={"storeemail"}/>
            {/*=== Input City Of The Store  ===*/}
            {loadingcities === false ? "" :
                <>
                    <InputCities Error={props.errors.cities} valueInput={props.values.cities} Type={"select"}
                        Label={language === "en" ? "The city of the store" : "المدينة التابع لها المتجر"} Name={"cities"}
                        Data={getCaty} handleBlur={handleBlur} setFieldValue={setFieldValue}
                        setGetSubCaty={setGetSubCaty} />

                    <InputSelect Error={props.errors.subcaty} valueInput={props.values.subcaty} Type={"select"}
                        Label={language === "en" ? "District" : "الحي"} Name={"subcaty"}
                        Data={getsubcaty} handleBlur={handleBlur} setFieldValue={setFieldValue} />

                </>
            }
            {loadingcategories === false ? "" :
                <InputSelect Error={props.errors.category} valueInput={props.values.category} Type={"select"}
                    Label={language === "en" ? "What types of products do you sell?" : "ماهى أنواع المنتجات التي تقوم ببيعها ؟"}
                    Name={"category"} Data={getCategories} handleBlur={handleBlur} setFieldValue={setFieldValue} />
            }

            {/*=== Input About Us Of The Store  ===*/}
            <InputTextArea Error={props.errors.description_store} valueInput={props.values.description_store} Type={"text"}
                Label={language === "en" ? "About the store" : "نبذة عن المتجر"} Name={"description_store"} />

            {/*=== Input Business Delivery Of The Store  ===*/}
            <InputPrice Error={props.errors.business_delivery} valueInput={props.values.business_delivery}
                Label={language === "en" ? "Shipping Rate" : "سعر الشحن"} Name={"business_delivery"} Type={"number"}
                Placeholder={language === "en" ? "Enter the shipping rate here" : "اكتب سعر الشحن هنا"}
                Datapricespan={language === "en" ? "s.r" : " ر.س"} 
                ShippingText={language === "en" ? 
                "Please note that the shipping price will include the entire order and not the product separately *"
                 : "يرجى العلم أن سعر الشحن سوف يشمل الطلب بالكامل وليس للمنتج بشكل منفصل"}/>
                
            <div className="form-end">
                <button type="submit" className='btn btn-next'>
                    {language === "en" ? "Next" : "التالي"}
                </button>
                <button type='button' className='btn btn-back' onClick={handleBackform}>
                    {language === "en" ? "Back" : "الرجوع"}
                </button>
            </div>
        </form>
    }


    const schema = () => {
        const schema = Yup.object().shape({
            storename: Yup.string().required("Store Name Is Required"),
            storephone: Yup.string()
                .min(9, 'The Store Phone must be at least 9 Digits !')
                .max(13, 'Store Phone Must Be No More Than 9 !')
                .required("Store Phone Is Required"),
            storeemail: Yup.string().required("Store Email Is Required"),
            description_store: Yup.string().required("Store Description Is Required"),
            cities: Yup.string().required("City Is Required"),
            subcaty: Yup.string().required("District Is Required"),
            category: Yup.string().required("Category Is Required"),
        });
        return schema;
    }

    return (
        <div className='sellersingup__form'>
            <Formik
                initialValues={state}
                onSubmit={onSubmit}
                render={form}
                validationSchema={schema()}
                validateOnChange={false}
                enableReinitialize={true}
                validateOnBlur={false}
            />


        </div>
    )
}



export default Form_Two;