import React, { useContext } from 'react';
import ImageEmpty from '../../../../images/icon/icon_search.png';
import { Authcontext } from '../../../../store/context';

function ProudectsearchEmpty() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div className='proudectsearch__empty'>
        <img src={ImageEmpty} alt="ImageEmpty" />
        <p>
        {language === "en" ? "Use Different Search Words" : "استخدم كلمات بحث مختلفة"}
        </p>
    </div>
  )
}

export default ProudectsearchEmpty;