import React, { useContext } from 'react'
import { Authcontext } from '../../../../store/context';

function ShippingDetails(props) {
    const { Name, Phone, Address } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    return (
        <div className="shippingdetiles">
            <h6 className="title">{language === "ar" ? "عنوان الشحن" : "Shipping Address"}</h6>
            <ul>
                <li>{Name}</li>
                <li>{Phone}</li>
                <li>{Address}</li>
            </ul>
        </div>
    )
}

export default ShippingDetails;