import React, { useContext, useState } from 'react';
import StartYellow from '../../../../images/icon/start_yellow.png';
import IconPluse from '../../../../images/icon/icon_pluse.png';
import IconMinus from '../../../../images/icon/icon_minus.png';
import IconCart from '../../../../images/icon/icon_shopping-color.png';
import IconFavorite from '../../../../images/icon/icon_favorite.png';
import IconFavoriteadd from '../../../../images/icon/icon_heart_color_favorit.png';
import IconStore from '../../../../images/icon/icon_store.png';
import IconEmptySize from '../../../../images/icon/emptysize.png';
import IconAddCart from '../../../../images/icon/icon_addcart.png';
import IconFavourit from '../../../../images/icon/icon_favorite-nn.png';
import { NavLink } from 'react-router-dom';
import { Rating } from '@mui/material';
import swal from 'sweetalert';
import { AddToCart, AddToFavourite } from '../../../../api/actions';
import { Api, TokenLate, languagelate } from '../../../../api/index';
import { Authcontext } from '../../../../store/context';
import axios from 'axios';

function DataProudect(props) {
    const { Data } = props;
    const Id = Data.id;
    // Context Method
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    const wishlist = authcontext.wishlist;
    const setWishlist = authcontext.setWishlist;
    const cart = authcontext.cart;
    const setCart = authcontext.setCart;
    // States Data
    const [numberquantity, setNumberquantity] = useState(1);
    const [numbercolor, setNumbercolor] = useState(0);
    const [numbersize, setNumbersize] = useState(0);
    const colors = [...Data.colors],
        Sizes = [...Data.sizes];


    const AddToCartApi = async (id, numberquantity, numbersize, numbercolor) => {

        var data = new FormData();
        data.append('product_id', id);
        data.append('quantity', numberquantity);
        data.append('size_id', numbersize);
        data.append('color_id', numbercolor);

        const options = {
            method: "post",
            url: `${Api}cart`,
            headers: {
                Accept: "application/json",
                'Content-Type': 'application/json',
                Authorization: `Bearer ${TokenLate}`,
                "Accept-Language": `${languagelate === null ? 'ar' : languagelate}`,
            },
            data
        };
        await axios(options).then(function (response) {})
        .catch(function (error) { console.log(error); });
    };


    const AddProudectToCart = () => {
        // If Size not specified
        if (numbersize === 0) {
            const MassegaErrorSize = language === "en" ? "Size not specified" : "لم يتم تحديد المقاس";
            swal({ text: MassegaErrorSize, icon: IconEmptySize, buttons: false, timer: 2000 });
            // If color is not specified
        } else if (numbercolor === 0) {
            const MassegaErrorcolor = language === "en" ? "The color is not specified" : "لم يتم تحديد اللون";
            swal({ text: MassegaErrorcolor, icon: IconEmptySize, buttons: false, timer: 2000 });
            // If you specify the color and size
        } else {
            const check = cart.every(item => { return item !== Id });
            // If it is not found in the shopping cart

            if (check) {
                setCart([...cart, Id])
                AddToCartApi(Id, numberquantity, numbersize, numbercolor)
                const MassegaAdd = language === "en" ? "The product has been added to the shopping cart" : "تم إضافة المنتج  إلى عربة التسوق";
                swal({ text: MassegaAdd, icon: IconAddCart, buttons: false, timer: 2000 })
            // In the event that the product is in the shopping cart
            } else {
                const MassegaError = language === "en" ? "The product is already in your cart" : "المنتج موجود من قبل في عربة التسوق الخاصة بك";
                swal({ text: MassegaError, icon: IconEmptySize, buttons: false, timer: 2000 })
            }
        }
    }





    const Plusaction = (e) => {
        e.preventDefault();
        setNumberquantity(numberquantity + 1)
    }
    const Downaction = (e) => {
        e.preventDefault();
        if (numberquantity <= 1) {
            setNumberquantity(1)
        } else {
            setNumberquantity(numberquantity - 1)
        }
    }


    const AddToWishlist = (id) => {
        AddToFavourite(Id)
        const MassegaAdd = language === "en" ? "The product has been added to favourites !" :"تمت إضافة المنتج للمفضله";
        setWishlist([...wishlist, Data])
        swal({text: MassegaAdd,icon: IconFavourit, buttons: false,timer: 2000})
    }



    return (
        <ul className='dataproudect'>
            <li className='title'>
                <h4>{Data.title}</h4>

                <span className='right'>
                    <ul>
                        <Rating name="read-only" value={Data.rate} readOnly />
                    </ul>
                    <span className='text'>{language === "ar" ? `(تعليقات ${Data.comment_count})` : `(Comments ${Data.comment_count})`}</span>
                </span>
            </li>

            <span className="price">SR {Data.price}</span>

            <span className='description'>{Data.desc}</span>

            <span className="colors">
                <span className="left">{language === "ar" ? "الألوان المتاحه" : "Available Colors"}</span>
                <ul className="rightcolor">

                    {colors.map(item =>
                        <li key={item.id} onClick={() => setNumbercolor(item.id)}
                            className={item.id === numbercolor ? "active" : ""}
                        >
                            {item.name}
                            <span>-</span>
                        </li>
                    )}
                </ul>
            </span>

            <span className="size">
                <span className="left">{language === "ar" ? "المقاسات المتاحه" : "Available sizes"}</span>
                <ul className="rightsize">

                    {Sizes.map(item =>
                        <li key={item.id} onClick={() => setNumbersize(item.id)}
                            className={item.id === numbersize ? "active" : ""} >
                            {item.name}
                        </li>
                    )}
                </ul>
            </span>

            <div className="conter_culter">
                <div className="conter">
                    <button className='btn-plus' type='button' onClick={Plusaction} >
                        <img src={IconPluse} alt="IconPluse" />
                    </button>
                    <span className='number'>
                        {numberquantity}
                    </span>
                    <button className={numberquantity === 1 ? 'button-disabled btn-plus' : 'btn-plus'} type='button'
                        onClick={Downaction} >
                        <img src={IconMinus} alt="IconMinus" />
                    </button>
                </div>

            </div>

            <div className="cart">


                {TokenLate === null ?
                    <button className="btn btn_cart" type='button'
                        data-bs-toggle="modal" data-bs-target="#modailnotlogin">
                        <img src={IconCart} alt="IconCart" />
                        <span>{language === "ar" ? "أضف إلى السلة" : "Add To Cart"}</span>
                    </button>
                    :
                    <button className="btn btn_cart" type='button' onClick={AddProudectToCart}>
                        <img src={IconCart} alt="IconCart" />
                        <span>{language === "ar" ? "أضف إلى السلة" : "Add To Cart"}</span>
                    </button>
                }



                {TokenLate === null ?
                    <button className="btn  btn-favorite" type='button'
                        data-bs-toggle="modal" data-bs-target="#modailnotlogin">
                        {Data.is_favorite === false ?
                            <img src={IconFavorite} alt="IconFavorite" /> :
                            <img src={IconFavoriteadd} alt="IconFavorite" />
                        }
                    </button>
                    :
                    <button
                        className={"btn  btn-favorite"}
                        type='button' onClick={AddToWishlist} >
                        {Data.is_favorite === false ?
                            <img src={IconFavorite} alt="IconFavorite" /> :
                            <img src={IconFavoriteadd} alt="IconFavorite" />
                        }
                    </button>

                }
            </div>


            <div className="store">
                <NavLink to={`/storedetails/${Data.provider.id}`} className='btn-store'><img src={IconStore} alt="IconStore" /></NavLink>
                <span className='data'>
                    <NavLink to={`/storedetails/${Data.provider.id}`}>{Data.provider.business_name}</NavLink>
                    <NavLink to={`/storedetails/${Data.provider.id}`}
                        className={TokenLate === null ? 'data_sec btn-revewe btn-disabled' : 'data_sec btn-revewe'}>
                        <img src={StartYellow} alt="StartYellow" />
                        <span>({Data.provider.rate_count})</span>
                    </NavLink>
                </span>
            </div>

        </ul>
    )
}

export default DataProudect;