import React, { useContext, useState } from 'react'
import FormThree from './form/formthree';
import FormFour from './form/formfour';
import FormFive from './form/formfive';
import FormTwo from './form/formtwo';
import { Authcontext } from '../../../store/context';
import { UpdateProfileSeller } from '../../../api/actions';

function EditeStoreDataForm(props) {
    const { stutes, setStutes, Data } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const [cities, setCities] = useState(Data.provider.city === null ? "":Data.provider.city.id);
    const [subcaty, setsubcaty] = useState(Data.provider.region === null ? "":Data.provider.region.id);
    const [category, setCategory] = useState(Data.provider.categories.length === 0 ? "":Data.provider.categories[0].id);
    const [message, setMessage] = useState("");

    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        name: Data.name,
        phone: Data.phone,
        email: Data.email,
        storename: Data.provider.business_name,
        storephone: Data.provider.business_phone,
        storeemail: Data.provider.business_email,
        description_store: Data.provider.business_bio,
        business_delivery : Data.provider.business_delivery,
        namebanck: Data.provider.bank_name,
        nameuserbanck: Data.provider.bank_account_name,
        branchname: Data.provider.bank_branch_name,
        accountnumber: Data.provider.bank_account_num,
        swiftcode: Data.provider.bank_swift_code,
        registrationnumber: Data.provider.tax_registration_num
    });

    const [fileone, setFileone] = useState(null);
    const [fileotwo, setFiletwo] = useState(null);
    const [filethree, setFilethree] = useState(null);

    const [loadingbutton, setLoadingbutton] = useState(false);

    const handleChange = (e) => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value,
        });

    };
    const handleSubmit = (e) => {
        setLoadingbutton(true)
        UpdateProfileSeller(state,category,cities,subcaty,fileone,fileotwo,filethree,setMessage,setLoading,setLoadingbutton)
    }
    return (
        <div className='profile__form'>

            {stutes === 3 ?
                <FormThree setFileone={setFileone} 
                fileone={fileone}  DataFileone={Data.provider.business_licence_image}
                setFiletwo={setFiletwo} fileotwo={fileotwo}  DataFiletwo={Data.provider.identity_image}
                    setStutesform={setStutes}
                    Title={language === "en" ? "Review the attached documents" : "مراجعة المستندات المرفقة"} />
                : stutes === 4 ?
                <FormFour handleChange={handleChange} State={state} setStutesform={setStutes}
                    Title={language === "en" ? "Review your bank details" : "مراجعة تفاصيل البنك الخاص بك"} />
                    : stutes === 5 ?
                <FormFive handleChange={handleChange} State={state} setStutesform={setStutes}
                    setFilethree={setFilethree} filethree={filethree} handleSubmit={handleSubmit} message={message}
                    setLoading={setLoading} DataFilethree={Data.provider.tax_registration_num_image}
                    Title={language === "en" ? "Review VAT details" : "مراجعة تفاصيل ضريبة القيمة المضافة"} 
                    loadingbutton={loadingbutton}/>
                    :
                <FormTwo handleChange={handleChange} State={state} setStutesform={setStutes}
                    cities={cities} setCities={setCities} subcaty={subcaty} setsubcaty={setsubcaty}
                    category={category} setCategory={setCategory}
                    Title={language === "en" ? "Modify store data" : "تعديل بيانات المتجر الخاص بك"} />
            }
        </div>
    )
}

export default EditeStoreDataForm