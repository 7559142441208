import React, { useContext, useState } from 'react';
import IconEdit from '../../../../images/icon/icon_edit.png';
import Personal from './personal';

function ProfileContant(props) {
    const {Data}=props;
    return (
        <div className='profile__contant'>
            <Personal Data={Data}/>
        </div>
    )
}

export default ProfileContant;