import React from 'react';
import NavbarTop from '../../components/navbar/navbartop';
import { TokenLate, UserTypelate } from '../../api';
import NavbarBottomSeller from '../../components/navbar/navbarbottom/seller';
import NavbarBottomBuyer from '../../components/navbar/navbarbottom/buyer';
import Model from '../../components/navbar/navbarbottom/modal/models';

function Navbar() {
  return (
    <>
      <NavbarTop sellerloction={true} />
      {UserTypelate === 3 && TokenLate !== null ?
        <NavbarBottomSeller/>
        :
        <NavbarBottomBuyer  />
      }
      <Model />
    </>
  )
}

export default Navbar;