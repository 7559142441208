import React, { useContext } from 'react'
import IconStore from '../../../images/icon/icon-store.png';

function StoreScreen() {
  return (
    <div className='storedetails__screen'>
    <div className="container">

      <div className="storedetails__screen__contaner">
        <div className="imgicon">
          <img src={IconStore} alt="IconStore" />
        </div>
      </div>
    </div>
    </div>
  )
}

export default StoreScreen;
