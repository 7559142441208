import React from 'react'
import { useContext } from 'react';
import { Authcontext } from '../../../../../../store/context';
import ModalHeader from '../../outer/modalheader';
import FormForgetPasswordThree from './form/form';
import FormForgetPasswordTwo from './form/form';

function ModalForgetPasswordThree() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div
      className="modal fade modal-late"
      id="modalforgetpasswordthree"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <ModalHeader Title={language === "en" ? "New Password" : "كلمة المرور الجديدة"}
          Subtitle={""} Id={""} 
          ButtonBack={"modalforgetpasswordtwo"} ButtonClose={"modalforgetpasswordthree"} Modailstart={false}
           />

           <FormForgetPasswordThree/>
        </div>
      </div>
    </div>
  )
}

export default ModalForgetPasswordThree;