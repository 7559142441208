import React, { useContext } from 'react';
import ImageEmpty from '../../../../images/icon/icon_shoppingcart-empty.png';
import { Authcontext } from '../../../../store/context';

function CartEmpty() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div className='cart__empty'>
        <img src={ImageEmpty} alt="ImageEmpty" />
        <p>
          {language === "ar" ? 
          "لم تقم بإضافة أي منتج الى سلة الشراء حتى الان" : "No product has been added to the cart yet"}
        </p>
    </div>
  )
}

export default CartEmpty;