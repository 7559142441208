import React from 'react'
import { useContext } from 'react';
import { Rating } from '@mui/material';
import { Authcontext } from '../../../store/context';

function RatingsBottom(props) {
    const {Data}=props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    return (
        <div className='ratingscontent__bottom'>
            <h3 className="title">{language === "ar" ? "التقييمات" : "Ratings"}

                <span>({Data.rate_count})</span>
            </h3>


            <div className="review_lest">
                <ul className='list'>
                    {Data.rates.map(item =>
                    <li key={item.id}>
                        <span className='left'>
                            <span className="top">
                                <span className='image'>{item.author.substring(0, 1)}</span>
                                <span className="content">
                                    <span className="name">{item.author}</span>
                                    <Rating name="read-only" value={item.rate} readOnly />
                                    <span className="day">{item.date}</span>
                                </span>
                            </span>

                            <span className="bottom">
                                {item.comment}
                            </span>

                        </span>

                        <span className='right'>
                            <img src={item.product_image} alt="" />
                        </span>
                    </li>
                        )}

                </ul>
            </div>
        </div>
    )
}

export default RatingsBottom;