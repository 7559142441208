import React, { useContext } from 'react';
import cover from '../../../images/home/over_cover.png';
import Iconarrow from '../../../images/icon/icon_arrow-right-white.png';

import { NavLink } from 'react-router-dom';
import { Authcontext } from '../../../store/context';

function HomeOvers() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    return (
        <div className='homebuyer__overs'>
            <div className="left">
                <img src={cover} alt="" />
            </div>
            <div className="right">
                    <h2>
                        {language === "ar" ?
                            <>
                                <span>تمتعي بجميع الخصومات</span>
                                <span> والعروض الآن</span>
                            </>
                            :
                            <>
                                <span>Enjoy all discounts</span>
                                <span>And offers now</span>
                            </>
                        }
                    </h2>
                    <p>
                        {language === "ar" ?
                            <>
                                <span>تصاميم متعدده لموسم الربيع , ابتكرى </span>
                                <span>إطلالتك الخاصه مع القطع الرائجه</span>
                            </>
                            :
                            <>
                                <span>Multiple designs for the spring season, get creative</span>
                                <span>Your own look with popular pieces</span>
                            </>
                        }
                    </p>

                <div className="data">
                    <NavLink 
                    to={`/coupons/page:${1}/filtersort:${"all"}/filtercategory:${0}/filtersubcategory:${0}/minValue:${0}/maxValue:${0}`}
                     className="btn">
                        <img src={Iconarrow} alt="Iconarrow" />
                        <span>{language === "ar" ? "رؤية العروض" : "See Offers"}</span>
                    </NavLink>
                </div>
            </div>

        </div>
    )
}

export default HomeOvers