import React, { useContext } from 'react';
import ImageCard from "../../../../images/proudects/item_card.png";
import { Authcontext } from '../../../../store/context';

function DataTable(props) {
    const { Data } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    
    return (
        <div className='datatable'>
            <table className="table toptable">
                <thead>
                    <tr>
                        <th scope="col">
                        {language === "en" ? "Product" :"المنتج"}
                        </th>
                        <th scope="col">
                        {language === "en" ? "Quantity" :"الكمية"}
                        </th>
                        <th scope="col">
                        {language === "en" ? "Size" :"المقاسات"}
                        </th>
                        <th scope="col">
                        {language === "en" ? "color" :"الالوان"}
                        </th>
                        <th scope="col">
                        {language === "en" ? "Price" :"السعر"}
                        </th>
                        <th scope="col">
                        {language === "en" ? "Total" :"المجموع"}
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {Data.map(item =>
                        <tr key={item.id}>
                            <td>

                                <img
                                    src={item.product.media.length === 0 ?
                                        "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg"
                                        : item.product.media[0].image}
                                    alt="ImageCard"
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                            "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg";
                                    }} />
                                <span className="text">{item.product.title}</span>
                            </td>
                            <td>{item.quantity}</td>
                            <td>{item.size.name}</td>
                            <td>{item.color.name}</td>
                            <td>SR {item.product.price}</td>
                            <td>SR {item.total}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default DataTable;