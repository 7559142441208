import React from 'react'
import Iconlocation from '../../../../images/icon/icon_location.png';
import { useContext } from 'react';
import CardList from '../../myaddresses/card/cardlist';
import { Authcontext } from '../../../../store/context';
import { SelectAddresses } from '../../../../api/actions';

function CheckoutAddressCard(props) {
    const { Selectnumber,setSelectnumber ,Id,Item ,setTotal,setOrderid ,setLoadingbutton} = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const SlectAdress=()=>{
        setSelectnumber(Id);
        setLoadingbutton(true)
        SelectAddresses(Id,setTotal,setOrderid,setLoadingbutton);
    }
  return (
    <div className={Selectnumber === Id ? 'checkout__address__card checkout__address__card_active':'checkout__address__card' }
    onClick={SlectAdress}>
        <ul>
            <li className="first">
                <span className="left">
                    <img src={Iconlocation} alt="Iconlocation" />
                    {Item.is_default === true ?
                    <span>{language === "ar" ? "افتراضي" : "Hypothetical"}</span>
                :""}
                </span>
            </li>
            <CardList Name={Item.user_name} Phone={Item.phone} Address={Item.address}/>
        </ul>
    </div>
  )
}

export default CheckoutAddressCard;