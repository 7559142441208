import React, { useContext } from 'react';
import ImageEmpty from '../../../../images/icon/icon_notification_empty.png';
import { Authcontext } from '../../../../store/context';

function NotificationEmpty() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div className='notification__empty'>
        <img src={ImageEmpty} alt="ImageEmpty" />
        <p>
          {language === "ar" ? "لا يوجد اى اشعارات لك حتى الان" : "There are no notifications for you yet"}
        </p>
    </div>
  )
}

export default NotificationEmpty;