import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { Authcontext } from '../../../../../store/context';
import { ReSendCode } from '../../../../../api/actions';

function TimerCode(props) {
  const { initSeconds, setDisabledinput, setMessage, ModalOpen, setModalOpen } = props

  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [seconds, setSeconds] = useState(initSeconds)

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
        setDisabledinput(false)
      } else if (seconds < 1) {
        clearInterval(myInterval)
        setDisabledinput(true)
      }

    }, 1000)
    return () => {
      clearInterval(myInterval)
    }

  })

  const ReSendCodeOtp = () => {
    setModalOpen(!ModalOpen)
    setDisabledinput(false)
    ReSendCode(setMessage,setModalOpen)
  }

  return (
    <>
      <div className='modal-late__wrapper'>
        {seconds === 0 ? (
          <div className="modal-late__resendcode">
            <p>
              {language === "en" ? "Didn't get the code ?" : "لم أحصل على الرمز ؟"}
              <button type='button' onClick={ReSendCodeOtp}>
                {language === "en" ? "Click here to resend." : "انقر هنا لإعادة الإرسال"}
              </button>
            </p>
          </div>
        ) : (
          <span className='time'>
            <span className="number">{seconds < 10 ? `0${seconds}` : seconds}</span>
            <span className="text">{language === "en" ? "Second" : "ثانية"}</span>
          </span>
        )}
      </div>
    </>
  )
}

export default TimerCode


/*
ReactDOM.render(
  <Timer
        initMinute={00}
        initSeconds={30}
    />,
  document.getElementById('root')
)
*/
