import React, { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Authcontext } from '../../../../store/context';
import PoupeRevewe from '../../proudect/content/pouperevewe';

function MyOrderCard(props) {
  const { Title, Color, Size, Quantity, Price, Provider, Imageproudect, Item } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  
  let location = useLocation(),
    textlocation = location.pathname,
    completedrequests = textlocation.includes("completedrequests"),
    checkoutdone = textlocation.includes("checkoutdone");


  return (
    <div className='myorders__card'>
      <div className="left">
        <div className="image">
          <NavLink to={`/proudect/${Item.product.id}`}>
            <img src={Imageproudect} alt="Imageproudect" />
          </NavLink>

        </div>
        <div className="data">
          <h6>{Title}</h6>
          <ul>
            <li>
              <span className='title'>{language === "ar" ? "اللون" : "Color"}</span>
              <span className='data'>{Color}</span>
            </li>
            <li>
              <span className='title'>{language === "ar" ? "المقاس" : "Size"}</span>
              <span className='data'>{Size}</span>
            </li>
            <li>
              <span className='title'>{language === "ar" ? "الكمية" : "Quantity"}</span>
              <span className='data'>{Quantity}</span>
            </li>

          </ul>
          <ul>
            {Item.provider === "" ?
              "" :
              <li>
                <span className='title'>{language === "ar" ? "البائع" : "The Seller"}</span>
                <span className='data'><NavLink to={`/storedetails/${Item.provider.id}`}>{Item.provider.business_name}</NavLink></span>
              </li>
            }
          </ul>
          {completedrequests === true ?
            <ul>
              <li>
                <button type='button' className='btn btnrevewe'
                  data-bs-toggle="modal" data-bs-target={"#exampleModal-" + Item.product.id}>
                  {language === "ar" ? "تقييم المنتج" : "Product evaluation"}
                </button>
              </li>
            </ul>
            : ""}

        </div>
      </div>
      <div className="right">
        <span className="price">{Price}</span>
      </div>
      <PoupeRevewe Id={Item.product.id} />

    </div>
  )
}

export default MyOrderCard;