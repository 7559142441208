import React, { useContext } from 'react'
import Detiles from './detiles';
import { Authcontext } from '../../../store/context';
import DataUser from './datauser';
import DataTable from './datatable';

function ProudectOnlaySellerRow(props) {
  const {Data}=props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (
    
    <div className="proudectonlayseller__row">
    <Detiles ordernumber={Data.serial}  dateorder={Data.date}
    Orderstatus={Data.status_en} Textstatus={Data.status} Id={Data.id}/>

    <DataUser Name={Data.user.name} Phone={Data.user.phone} Image={Data.user.image} 
     Address={Data.shipping_address} 
    Paymentdata={Data.payment_gateway}/>


    <h6>{language === "en" ? "Products" :"المنتجات"}</h6>
    <DataTable Data={Data.orderItemProducts}/>
  </div>

  )
}

export default ProudectOnlaySellerRow;