import React, { useContext, useEffect, useState } from 'react'
import { InputCategory, InputCities, InputDesstore, InputPrice, InputStoreEmail, InputStoreName, InputStorePhone, InputSubCities } from './inputes';
import { Authcontext } from '../../../../store/context';
import { GetDataCities, GetDataCategories } from '../../../../api/actions';
import { useNavigate } from 'react-router';


function FormTwo(props) {
    const { handleChange, State, setStutesform, cities, setCities, subcaty, setsubcaty, category, setCategory, Title } = props;
    const [validation, setvalidation] = useState("hide");
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const [getCategories, setGetCategories] = useState([]);
    const [loadingcategories, setLoadingcategories] = useState(false);

    const [getCaty, setGetCaty] = useState([]);
    const [loadingcities, setLoadingcities] = useState(false);
    let navigate = useNavigate();

    const [getsubcaty, setGetSubCaty] = useState([]);

    useEffect(() => {
        GetDataCategories(setLoadingcategories, setGetCategories)
        GetDataCities(setLoadingcities, setGetCaty)

    }, [loadingcategories]);

    const handleNextform = (e) => {
        e.preventDefault();

        if (State.storename === "" ||
            State.storephone === "" ||
            State.storeemail === "" ||
            State.description_store === "" ||
            State.business_delivery === "" ||
            cities === "" ||
            subcaty === "" ||
            category === "") {
            setvalidation(false);
        } else {
            setvalidation(true);
            setStutesform(3)
        }
    }

    const handleBackform = (e) => {
        e.preventDefault();
        setStutesform(1)
        navigate(`/storedata`);
    }

    return (
        <>
            <div className="title">
                <h6>{Title}</h6>
            </div>

            <InputStoreName validation={validation} value={State.storename} handleChange={handleChange} />
            <InputStorePhone validation={validation} value={State.storephone} handleChange={handleChange} />
            <InputStoreEmail validation={validation} value={State.storeemail} handleChange={handleChange} />
            {loadingcities === false ? "" :
                <InputCities cities={cities} setCities={setCities} Data={getCaty}
                    validation={validation} language={language} setGetSubCaty={setGetSubCaty} />
            }
            <InputSubCities subcaty={subcaty} setsubcaty={setsubcaty} Data={getsubcaty}
                validation={validation} language={language} />

            {loadingcategories === false ? "" :
                <InputCategory category={category} setCategory={setCategory} Data={getCategories}
                    validation={validation} language={language} />
            }
            <InputDesstore language={language} validation={validation}
                value={State.description_store} handleChange={handleChange} />

            <InputPrice handleChange={handleChange} language={language} validation={validation} value={State.business_delivery} 
            Name={"business_delivery"} />
            <div className="form-end">

                <button type='button' className='btn btn-back' onClick={handleBackform}>
                    {language === "en" ? "Back" : "الرجوع"}
                </button>
                <button type='button' className='btn btn-next' onClick={handleNextform}>
                    {language === "en" ? "Next" : "التالي"}
                </button>
            </div>
        </>
    )
}

export default FormTwo;