import React, { useContext } from 'react';
import IconLogo from "../../../images/logo/logo.png";
import { Authcontext } from '../../../store/context';

function ModailNotLogin() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    return (
        <div className="modal fade" id="modailnotlogin" data-bs-backdrop="static"
            data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <img src={IconLogo} alt="IconLogo" />

                        <p>{language === "ar" ? "يرجى  تسجيل الدخول" : "Please login again"}</p>


                        <button
                            className="btn"
                            data-bs-toggle="modal"
                            href="#singinModal"
                            role="button"
                        >
                            {language === "ar" ? "تسجيل الدخول" : "Sign In"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModailNotLogin;