import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import ProudectContent from '../../../components/buyer/proudect/content';
import PoupeRevewe from '../../../components/buyer/proudect/content/pouperevewe';
import CustomerReview from '../../../components/buyer/proudect/review';
import SimilarProducts from '../../../components/buyer/proudect/similarproducts';
import NavList from '../../../components/shared/navlist/index';
import Titlepage from '../../../components/shared/title/index';
import Loading from '../../../layout/loading/loading';
import { Authcontext } from '../../../store/context';
import { GetProduct } from '../../../api/actions';
import { TokenLate, UserIdlate } from '../../../api';

function Proudect() {
  const { id } = useParams();
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (UserIdlate === 3 && TokenLate !== null) {
      window.location.pathname = `/`
    }
  }, []);

  useEffect(() => {
    GetProduct(id, setData, setLoading);
  }, [loading, id]);

  return (
    <>
      {loading === false ?
        <Loading />
        :
        <section className='proudect'>
          <div className="container">
            <div className="proudect__top">
              <NavList Titleproudect={data.title}
                Id={data.id} />
            </div>
            <ProudectContent Data={data} />
            <CustomerReview Data={data.comments} Proudectpage={false}
              Title={language === "ar" ? "مراجعة العملاء" : "Customer Review"} />

            <button className='btn btn-addrevew' type='button'
              data-bs-toggle="modal"
              data-bs-target={"#exampleModal-" + id}>
              {language === "ar" ? "أضف تقييمك" : "Add Your Review"}
            </button>

            <Titlepage Title={language === "ar" ? "منتجات مشابهه" : "Similar Products"} />
            <SimilarProducts Data={data} />
            <PoupeRevewe Id={id} />
          </div>
        </section>
      }
    </>
  )
}

export default Proudect;