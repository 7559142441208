import React, { useContext } from 'react'
import Titlepage from '../../shared/title/index.jsx';
import Slider from 'react-slick';
import { Authcontext } from '../../../store/context.js';

function OffersTop(props) {
  const { Data } = props;
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "15px",
    slidesToShow: 4,
    speed: 500,
    dots: false,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: false
        }
      },
    ]
  };

  return (
    <div className='offers__top'>
      <Titlepage Title={language === "ar" ? "أكواد الخصم" : "Discount codes"} />
      <Slider {...settings}>
        
        {Data.map(item =>
          <div className='offerItem' key={item.id}>
            <span>{item.name}</span>
          </div>

        )}
      </Slider>

    </div>
  )
}

export default OffersTop;


/*
 <Slider {...settings}>
      <div>
        <h3>1</h3>
      </div>
      <div>
        <h3>2</h3>
      </div>
      <div>
        <h3>3</h3>
      </div>
      <div>
        <h3>4</h3>
      </div>
      <div>
        <h3>5</h3>
      </div>
      <div>
        <h3>6</h3>
      </div>
    </Slider>




      {Data.map(item =>
          <li className='col-md-6 col-lg-3' key={item.id}>
            <span>{item.name}</span>
          </li>
        )}
*/