import React, { useContext, useState } from 'react'
import { InputFile } from './inputes';
import { Authcontext } from '../../../store/context';


function FormThree(props) {
    const { Data, setStutes } = props;

    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const handleNextform = (e) => {
        e.preventDefault();
        setStutes(4)
    }



    const handleBackform = (e) => {
        e.preventDefault();
        setStutes(2)
    }

    return (
        <>
            <div className="title">
                <h6>{language === "en" ? "Document" : "المستندات"}</h6>
            </div>
            <InputFile file={Data.provider.business_licence_image} 
            Title={language === "ar" ? "الرخصة التجارية" : "Trade License"}
                language={language} Id={1}/>


            <InputFile  file={Data.provider.identity_image}  
            Title={language === "ar" ? "بطاقة الهوية" : "Identity card"}
                language={language} Id={2}/>


            <div className="form-end">

                <button type='button' className='btn btn-back' onClick={handleBackform}>
                    {language === "en" ? "Back" : "الرجوع"}
                </button>

                <button type='button' className='btn btn-next' onClick={handleNextform}>
                    {language === "en" ? "Next" : "التالي"}
                </button>
            </div>



        </>
    )
}

export default FormThree;