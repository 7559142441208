import React, { useContext, useEffect, useState } from 'react'
import ProudectsAll from '../../../components/buyer/proudects/allproudect/index.jsx';
import Paginate from '../../../components/shared/paginate/index.jsx';
import ProudectsearchEmpty from '../../../components/buyer/proudectsearch/empty/index.jsx';
import { useNavigate, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import IconImage from '../../../images/icon/icon_arrow_left_small.png';
import { ProudectFilterSearch } from '../../../api/actions.js';
import Loading from '../../../layout/loading/loading.jsx';
import { Authcontext } from '../../../store/context.js';
import { TokenLate, UserIdlate } from '../../../api/index.js';


function ProudectSearch() {
  const { search, page } = useParams();
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  let navigate = useNavigate();
  const pagenumber = +page;
  const [pageCount, setpageCount] = useState(pagenumber);
  const [perpage, setPerpage] = useState(0);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    ProudectFilterSearch(search, page, setLoading, setProducts, setPerpage)

  }, [loading, pageCount]);


  useEffect(() => {
    if (UserIdlate === 3 && TokenLate !== null) {
      window.location.pathname = `/`
    }
  }, []);

  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    setpageCount(number)
    navigate(`/proudectsearch/${search}/${number}`)
  };

  console.log(products);
  return (
    <>
      {loading === false ? (
        <Loading />
      ) : (
        <section className='proudects proudectsearch'>
          <div className="container">


            <div className='proudects__top'>
              <div className='navlist'>
                <NavLink to={"/"} >{language === "ar" ? "الصفحه الرئيسيه" : "Home Page"}</NavLink>
                <img src={IconImage} alt="IconImage" />
                <NavLink to={`/proudectsearch/${search}/${1}`} >{search}</NavLink>
              </div>



            </div>



            <div className="proudects__all">

              {products.length === 0 ?
                <ProudectsearchEmpty />
                :<>
                <div className="row">
                  <div className="col-12">
                    <ProudectsAll Products={products} />
                  </div>
                </div>
              <div className="row">
                <div className="col-9">
                  <Paginate setpageCount={setpageCount} perpage={perpage}
                    handlePageClick={handlePageClick} pagenumber={pagenumber} />
                </div>
                <div className="col-3">
                </div>
              </div>
                </>
              }
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default ProudectSearch;
