import React, { useContext, useState } from 'react';
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { SendDataContactus } from '../../../api/actions';
import { Authcontext } from '../../../store/context';

function FormContactus() {
    const state = {
         name: "", 
         email: "",
         phone: "",
         message: "",
    };
    const [messageerror, setMessageerror] = useState("");
    const [messagesuccessfully, setMessagesuccessfully] = useState("");
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;


    const onSubmit = (values) => {
        SendDataContactus(values,setMessageerror,setMessagesuccessfully)
    }

    const form = (props) => {
        return <form onSubmit={props.handleSubmit}>
            <div className="form">
                <div>
                    <label className="form-label">
                        {language === "ar" ? "الاسم" : "Name"} 
                    </label>
                    <Field type="text" component="input"
                        className={props.errors.name ? "form-control is-invalid" : "form-control"}
                        placeholder={language === "ar" ? "الاسم" : "name"} 
                        name="name" />
                    <ErrorMessage name="name" component="span" className='errorfiled' />
                </div>

                <div>
                    <label className="form-label">
                        {language === "ar" ? "رقم الجوال" : "Mobile Number"}
                    </label>
                    <Field type="number" component="input"
                        className={props.errors.phone ? "form-control is-invalid" : "form-control"}
                        placeholder={"54 556 1870"}
                        name="phone" />
                    <ErrorMessage name="phone" component="span" className='errorfiled' />
                </div>

                <div>
                    <label className="form-label">
                        {language === "ar" ? "البريد إلكتروني" : "Email"}
                    </label>
                    <Field type="email" component="input"
                        className={props.errors.email ? "form-control is-invalid" : "form-control"}
                        placeholder={language === "en" ? "E-mail" : "البريد الإلكتروني"}
                        name="email" />
                    <ErrorMessage name="email" component="span" className='errorfiled' />
                </div>

                <div>
                    <label className="form-label">
                        {language === "ar" ? "الرسالة" : "The Message"}
                    </label>
                    <Field type="textarea" component="textarea"
                        className={props.errors.message ? "form-control is-invalid" : "form-control"}
                        placeholder={language === "ar" ? "الرسالة" : "The Message"}
                         name="message" />
                    <ErrorMessage name="message" component="span" className='errorfiled' />
                </div>
                <div className="mb-1">
                    {messagesuccessfully === ""? "":
                     <span className='successfully'>
                        {language === "ar" ? "تم إرسال الرسالة بنجاح" : "message sent successfully"}
                     </span>
                }
                    {messageerror === "" ? "" :
                     <span className='errorfiled'>{messageerror}</span>
                    }
                </div>

                <div className='btn-senddata'>
                        <button className='btn btn-send' type="submit">
                        {language === "ar" ? "إرسال" : "Send"}
                        </button>
                </div>
            </div>
        </form>
    }

    const schema = () => {
        const schema = Yup.object().shape({
            name: Yup.string().required("Name Is Required"),
            email: Yup.string().required("Email Is Required"),
            phone: Yup.string().required("The phone number Is Required")
            .min(9, 'The phone number must be at least 9 Digits!')
            .max(10, 'The phone number must be no more than 10 digits long!'),
            message: Yup.string().required("Message Is Required"),
        });

        return schema;
    }
    return (
        <>
            <Formik
                initialValues={state}
                onSubmit={onSubmit}
                render={form}
                validationSchema={schema()}
                validateOnChange={false}
                validateOnBlur={false}
            />
        </>
    )
}

export default FormContactus;
