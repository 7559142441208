import React, { useContext } from 'react';
import Imagesucee from'../../../images/icon/icon_success_green.png';
import { NavLink, useNavigate } from 'react-router-dom';
import { Authcontext } from '../../../store/context';

function ModalSuccess() {
    const authcontext = useContext(Authcontext);
    const cart = authcontext.cart;
    const setCart = authcontext.setCart;
    const language = authcontext.language;
    let navigate = useNavigate();

    const Checkoutgo=()=>{
     // setCart()
      window.location.pathname = `/`;
    }
  return (
    <div
      className="modal fade modal-success show"
      id="modalsuccess"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
            <div className="image">
            <img src={Imagesucee} alt="" />
            </div>
            <p>
            {language === "ar" ? "تم الدفع بنجاح يمكنك الآن متابعة طلبك في الطلبات الحالية" : 
            "Payment completed successfully, now proceed with your order in Existing Orders"}
            </p>

            <button  className='btn btn-go-home' type='button' onClick={Checkoutgo}>
            {language === "ar" ? "متابعة التسوق":"Continue shopping"}
            </button>
        </div>
      </div>
    </div>
  )
}

export default ModalSuccess;