import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { Authcontext } from "../../../../store/context";
import { Api, TokenLate, languagelate } from "../../../../api";
import swal from "sweetalert";
import axios from "axios";

function List(props) {
  const { Orderstatus, Item } = props;
  let navigate = useNavigate();
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;


  const [status, setStatus] = useState(Orderstatus === "Canceled" ||
    Orderstatus === "Refunded" ? `canceled` :
    Orderstatus === "Shipped" ? "shipped" :
      Orderstatus === "Delivered" ? "delivered" :
        Orderstatus === "Created" ? "created" : "in_progress");

  const ChingestatusOrderItem = async (Id, value) => {
    setStatus(value)
    var data = new FormData();
    data.append('status', value);

    const options = {
      method: "post",
      url: `${Api}order-items/${Id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "Retry-After": 3600,
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${TokenLate}`,
        "Accept-Language": `${languagelate === null ? 'ar' : languagelate}`,
      },
      data
    };
    await axios(options)
      .then(function (response) {
        swal({ text: response.data.message, icon: "success", buttons: false, timer: 3000 });
      })
      .catch(function (error) {
        swal({ text: error.response.data.message, icon: "info", buttons: false, timer: 3000 })
      });

  }



  const Chingestatus = (value) => {

    if (value === "canceled") {
      swal(language === "en" ? "Do you want to confirm the cancellation of this order ?" : "هل تريد تأكيد إلغاء هذا الطلب ؟",
        {
          buttons: {
            cancel: language === "en" ? "Cancel" : "إلغاء",
            catch: { text: language === "en" ? "Confirm" : "تأكيد", value: "canceled", },
          },
        })
        .then((value) => {
          switch (value) {
            case "canceled":
              ChingestatusOrderItem(Item.id, value);
              break;
            default:
              return false;
          }
        });
    } else { ChingestatusOrderItem(Item.id, value) }
  }
  return (
    <ul className="list row">
      <li className="col">
        <NavLink to={`/requestsseller/${Item.id}`}>
          {Item.serial}
        </NavLink>
      </li>
      <li className="col">{Item.user.name}</li>
      <li className="col">{Item.date}</li>
      <li className="col">{Item.payment_status}</li>
      <li className="col">{Item.total}</li>

      <li className="col">


        <select value={status} className={`${status} orderstatus`} onChange={e => Chingestatus(e.target.value)}>

          <option value="created" disabled={status === "created" ? false : true}>
            {language === "ar" ? "تم الأنشاء" : "Created"}
          </option>

          <option value="in_progress"
            disabled={status === "created" || status === "in_progress" ? false : true}>
            {language === "ar" ? "قيد التنفيذ" : "Underway"}
          </option>

          <option value="shipped"
            disabled={status === "shipped" || status === "in_progress" ? false : true}>
            {language === "ar" ? "تم الشحن" : "Charged"}
          </option>

          <option value="delivered"
            disabled={status === "shipped" || status === "delivered" ? false : true}>
            {language === "ar" ? "تم التسليم" : "Delivered"}
          </option>

          <option value="canceled" disabled={status === "delivered" ? true : false}>
            {language === "ar" ? "تم الإلغاء" : "Canceled"}</option>
        </select>
      </li>
    </ul>
  );
}

export default List;
