import React, { useEffect, useState } from "react";
import MyOrdersEmpty from "../../../components/buyer/myorders/empty";
import Loading from "../../../layout/loading/loading";
import MyOrdersNavlist from "../../../components/buyer/myorders/navlist";
import MyOrdersRowList from "../../../components/buyer/myorders/rowlist";
import { GetCurrentOrders } from "../../../api/actions";
import ProfileListbuyer from "../../../layout/profile/list/buyer";
import { TokenLate, UserIdlate } from "../../../api";
import Paginate from "../../../components/shared/paginate";
import { useNavigate, useParams } from "react-router";

function CurrentRequests() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const { page } = useParams();
  const pagenumber = +page;
  const [pageCount, setpageCount] = useState(pagenumber);
  const [perpage, setPerpage] = useState(0);


  useEffect(() => {
    if (UserIdlate === 3 && TokenLate !== null) {
      window.location.pathname = `/`
    }
  }, []);

  useEffect(() => {
    GetCurrentOrders(pageCount, "current", setData, setLoading, setPerpage);
  }, [loading, pageCount]);



  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    setpageCount(number);
    navigate(`/currentrequests/${number}`);
  };

  return (
    <>
      {loading === false ? (
        <Loading />
      ) : (
        <section className="profile">
          <div className="container">
            <div className="profile__data">
              <ProfileListbuyer />
              <div className="myorders__contant">
                <MyOrdersNavlist />

                {data.length === 0 ? (
                  <MyOrdersEmpty />
                ) : (

                  <div className='myorders__rowlist'>
                    <MyOrdersRowList Data={data} />


                    <div className="row">
                      <div className="col-9">
                        <Paginate perpage={perpage} handlePageClick={handlePageClick} pagenumber={pagenumber} />
                      </div>
                      <div className="col-3"></div>
                    </div>
                    
                    </div>
                )}


              </div>

            </div>


          </div>
        </section>
      )}
    </>
  );
}

export default CurrentRequests;
