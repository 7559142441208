import React, { useContext, useEffect, useState } from 'react'
import { Authcontext } from '../../../store/context';
import { InputControl, InputPrice, InputStoreName, InputStorePhone, InputTextarea } from './inputes';
import { useNavigate } from 'react-router';



function FormTwo(props) {
    const { Data ,setStutes} = props;
    
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    let navigate = useNavigate();
console.log(Data);
    const handleNextform = (e) => {
        e.preventDefault();
        setStutes(3)
    }



    const handleBackform = (e) => {
        e.preventDefault();
        setStutes(2)
        navigate(`/profile`);
    }


    return (<>
            <div className="title">
                <h6>{language === "en" ? "Your store data" : "بيانات المتجر الخاص بك"}</h6>
            </div>

            <InputControl value={Data.provider.business_name} 
            Title={language === "en" ? "Store name" : "اسم المتجر"}/>

            <InputControl value={Data.provider.business_phone} 
            Title={language === "en" ? "Store mobile number" : "رقم جوال المتجر"}/>
            
            <InputControl value={Data.provider.business_email} 
            Title={language === "en" ? "Store Email" : "البريد الإلكتروني الخاص بالمتجر"}/>
            
            <InputControl value={Data.provider.city === null ? "":Data.provider.city.name} 
            Title={language === "en" ? "The city of the store" : "المدينة التابع لها المتجر"}/>


            <InputControl value={Data.provider.region === null ? "":Data.provider.region.name} 
            Title={language === "en" ? "District" : "الحي"}/>
            
            <InputControl value={Data.provider.categories.length === 0 ? "":Data.provider.categories[0].name} 
            Title={language === "en" ? "What types of products do you sell?" : "ماهى أنواع المنتجات التي تقوم ببيعها ؟"}/>

            <InputTextarea  value={Data.provider.business_bio} 
            Title={language === "en" ? "About the store" : "نبذة عن المتجر"}/>
            
            <InputPrice  valueInput={Data.provider.business_delivery}
                Label={language === "en" ? "Shipping Rate" : "سعر الشحن"} Name={"business_delivery"} Type={"number"}
                Placeholder={language === "en" ? "Enter the shipping rate here" : "اكتب سعر الشحن هنا"}
                Datapricespan={language === "en" ? "s.r" : " ر.س"} 
                ShippingText={language === "en" ? 
                "Please note that the shipping price will include the entire order and not the product separately *"
                 : "يرجى العلم أن سعر الشحن سوف يشمل الطلب بالكامل وليس للمنتج بشكل منفصل"}/>
            
            <div className="form-end">

                <button type='button' className='btn btn-back' onClick={handleBackform}>
                    {language === "en" ? "Back" : "الرجوع"}
                </button>

                <button type='button' className='btn btn-next' onClick={handleNextform}>
                    {language === "en" ? "Next" : "التالي"}
                </button>
            </div>
            </>
    )
}

export default FormTwo;