import React, { useState } from 'react'
import { useContext } from 'react';
import IconClose from "../../../../images/icon/icon_close.png";
import LoctionIfram from './loctionifram';
import FormMap from './formmap';
import { Authcontext } from '../../../../store/context';
import { AddAddresses } from '../../../../api/actions';

function ModalAddress(props) {
    const { placeid, Address,clickedLatLng } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    const centerrequest = authcontext.centerrequest;
    const [notify, setNotify] = useState(false);
    const [addressdetails,setAddressdetails]=useState("");
    const [phone,setPhone]=useState("");
    const [firstname,setFirstname]=useState("");
    const [familyname,setFamilyname]=useState("");
    const [message,setMessage]=useState("");
    

    const Clicksupmet = () => {
      AddAddresses(firstname,familyname,addressdetails,phone,centerrequest,notify,setMessage)
    }
  
  return (
    <div className="modal fade modalmapaddress" id="modalmaptwo"
     tabIndex="-1" aria-labelledby="modalmapLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalmapLabel">
              {language === "ar" ? "تفاصيل العنوان الجديد" : "New address details"}
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='btn-close=mpdalmaptwo'>
              <img src={IconClose} alt="IconClose" />
            </button>
          </div>
          <div className="modal-body">
           
        <div className='modalform'>
            <LoctionIfram clickedLatLng={clickedLatLng} placeid={placeid}/>
            
            <FormMap Address={Address} notify={notify} setNotify={setNotify}
            addressdetails={addressdetails} setAddressdetails={setAddressdetails}
            phone={phone} setPhone={setPhone} firstname={firstname}
            setFirstname={setFirstname} familyname={familyname} setFamilyname={setFamilyname}/>

        </div>

        <div className="mb-1 mt-4">
                            {message === "" ? "" :
                                <span className='errorfiled'>{message}</span>
                            }
                        </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-save" onClick={Clicksupmet}>
              {language === "ar" ? "حفظ العنوان" : "Save the address"}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalAddress;