import React, { useContext, useState } from 'react';
import IconSearch from "../../../../images/icon/icon_search.png";
import { Authcontext } from '../../../../store/context';
import { ProudectSearch } from '../../../../api/actions.js';

function SearchBar() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const onKeyUp = (e) => {
    if (e.key === "Enter") {
   ProudectSearch(searchTerm)
    }
  }
  const handleSypmet = () => {
    if(searchTerm === ""){
    }else{
      ProudectSearch(searchTerm)
    }
  };

  return (
    <div className="input-group">
      <div className="form-outline">
        <input type="search" className="form-control" 
        placeholder={language === "ar" ? "عن ماذا تبحث؟" : "What are you looking for?"}
        onChange={handleChange} onKeyPress={onKeyUp}/>
      </div>
      <button type="button" className="btn" onClick={handleSypmet}>
        <img src={IconSearch} alt="IconSearch" />
      </button>
    </div>
  )
}

export default SearchBar;