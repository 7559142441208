import React, { useContext } from 'react'
import CartContant from '../../../components/buyer/cart/index';
import CartEmpty from '../../../components/buyer/cart/empty';
import Titlepage from '../../../components/shared/title';
import { useEffect } from 'react';
import { useState } from 'react';
import Loading from '../../../layout/loading/loading.jsx';
import { Authcontext } from '../../../store/context';
import { GetDataCart } from '../../../api/actions';
import { TokenLate, UserIdlate } from '../../../api';

function Cart() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const [data, setData] = useState([]);
  const [itemsdata, setItemsdata] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (UserIdlate === 3 && TokenLate !== null) {
      window.location.pathname = `/`
    }
  }, []);

  useEffect(() => {
    GetDataCart(setData, setItemsdata, setLoading)
  }, [loading]);


  return (
    <>
      {loading === false ? (
        <Loading />
      ) : (
        <section className='cart'>
          <div className="container">
            <Titlepage Title={language === "ar" ? "سلة الشراء" : "Shopping Cart"} />

            {itemsdata.length === 0 ? <CartEmpty /> :
              <CartContant Data={data} setData={setData} setItemsdata={setItemsdata} />
            }

          </div>
        </section>
      )}
    </>
  )
}

export default Cart;
