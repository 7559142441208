import React, { useContext } from 'react'
import { Formik } from 'formik';
import * as Yup from "yup";
import { Authcontext } from '../../../../../store/context';
import { InputText ,InputAccountnumber } from './input';

function Form_Four(props) {
    const { setStutesform ,setStateArraytfour ,StateArrayfour} = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const state = {
        nameuserbanck: StateArrayfour.nameuserbanck === undefined ? "" : StateArrayfour.nameuserbanck,
        namebanck: StateArrayfour.namebanck === undefined ? "" : StateArrayfour.namebanck,
        branchname: StateArrayfour.branchname === undefined ? "" : StateArrayfour.branchname,
        accountnumber: StateArrayfour.accountnumber === undefined ? "" : StateArrayfour.accountnumber,
        swiftcode: StateArrayfour.swiftcode === undefined ? "" : StateArrayfour.swiftcode,
    };


    const onSubmit = (values) => {
        setStutesform(5)
        setStateArraytfour(values)
    }

    const handleBackform = (e) => {
        e.preventDefault();
        setStutesform(3)
    }

    const form = (props) => {
        const { handleSubmit } = props;
        return <form onSubmit={handleSubmit}>
            {/*=== Input Bank name  ===*/}

            <InputText Error={props.errors.nameuserbanck} valueInput={props.values.nameuserbanck} Type={"text"}
                Label={language === "en" ? "Beneficiary Name" : "اسم المستفيد"} Name={"nameuserbanck"} />
            {/*=== Input Bank name  ===*/}

            <InputText Error={props.errors.namebanck} valueInput={props.values.namebanck} Type={"text"}
                Label={language === "en" ? "Bank name" : "اسم البنك"} Name={"namebanck"} />
            {/*=== Input Branch Bank   ===*/}

            <InputText Error={props.errors.branchname} valueInput={props.values.branchname} Type={"text"}
                Label={language === "en" ? "Branch Name" : "اسم الفرع"} Name={"branchname"} />

            <InputAccountnumber Error={props.errors.accountnumber} valueInput={props.values.accountnumber} Type={"text"}
                Label={language === "en" ? "Account Number" : "رقم الحساب"} Name={"accountnumber"} props={props}/>

            <InputText Error={""} valueInput={props.values.swiftcode} Type={"number"}
                Label={language === "en" ? "Swift code" : "رمز السويفت"} Name={"swiftcode"} />

            <div className="form-end">
                <button type="submit" className='btn btn-next'>
                    {language === "en" ? "Next" : "التالي"}
                </button>
                <button type='button' className='btn btn-back' onClick={handleBackform}>
                    {language === "en" ? "Back" : "الرجوع"}
                </button>
            </div>
        </form>
    }


    const schema = () => {
        const schema = Yup.object().shape({

            nameuserbanck: Yup.string().required(<>
                {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
            </>),

            namebanck: Yup.string().required(<>
                {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
            </>),

            branchname: Yup.string().required(<>
                {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
            </>),

            accountnumber: Yup.string()
                .min(14, <>{language === "en" ? "The account number must be at least 14 digits long" : "يجب ألا يقل رقم الحساب عن 14 رقمًا"}
                </>).required(<>
                {language === "en" ? "This Field Must Be Entered" : "يجب إدخال هذا الحقل"}
            </>),
            

        });
        return schema;
    }

    return (
        <div className='sellersingup__form'>

            <div className="title">
                <h6>{language === "en" ? "Your bank details" : "تفاصيل البنك الخاص بك"}</h6>
            </div>
            <Formik
                initialValues={state}
                onSubmit={onSubmit}
                render={form}
                validationSchema={schema()}
                validateOnChange={false}
                enableReinitialize={true}
                validateOnBlur={false}
            />


        </div>
    )
}



export default Form_Four;