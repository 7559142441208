import React, { useContext, useEffect, useState } from 'react'
import { GetDataFilter } from '../../../api/actions.js';
import { Authcontext } from '../../../store/context.js';
import Categories from './categories.jsx';
import Price from './price.jsx';
import ImageCloseFilter from '../../../images/icon/closefilter.png';
import { useNavigate, useParams } from 'react-router';

function ProudectsFilter(props) {
    const {togglefilter ,setTogglefilter,setLoading} = props;

    let navigate = useNavigate();

    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const [loadingfilter, setLoadingfilter] = useState(false);
    const [getCategories, setGetCategories] = useState([]);

    const [price, setprice] = useState([]);

    const [minValue, set_minValue] = useState([]);
    const [maxValue, set_maxValue] = useState([]);


    useEffect(() => {
        GetDataFilter(setprice, setLoadingfilter, set_minValue, set_maxValue, setGetCategories)
    }, [loadingfilter]);

    const CloseFilter = () => {
        setTogglefilter("hidefillter");
      };
      const removeData = () => {
        setTogglefilter("hidefillter");
        navigate(`/products/page:${1}/filtersort:${"all"}/filtercategory:${0}/filtersubcategory:${0}/minValue:${0}/maxValue:${0}`);
        setLoading(false)
      };

    return (<>

        {loadingfilter === false ? (
            ""
        ) : (

            <section className={`col-12 col-lg-3 fillter ${togglefilter}`}>
                <button className="btn btn_close_filter" type="button" onClick={CloseFilter}>
                    <img src={ImageCloseFilter} alt="ImageCloseFilter" />
                </button>
                <Categories getCategories={getCategories} setLoading={setLoading}/>

                <Price Price={price} maxValue={maxValue} minValue={minValue} 
                setTogglefilter={setTogglefilter} set_minValue={set_minValue} 
                set_maxValue={set_maxValue} setLoading={setLoading}/>
               
                <button className="btn btn_removeall" type="button" onClick={removeData}>
                    {language === "en" ? "Delete All" : "حذف الكل"}
                </button>

            </section>
        )}
    </>

    )
}

export default ProudectsFilter;