import React, { useContext, useState } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import { Authcontext } from "../../../store/context";
import { useNavigate, useParams } from "react-router";


function Price(props) {
  const { Price, maxValue,minValue ,setTogglefilter,set_minValue ,set_maxValue ,setLoading} = props;

  let { filtersort, filtercategory, filtersubcategory, filterminvalue, filtermaxvalue } = useParams(),
        navigate = useNavigate();
  
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;


  const minValuefilter = +filterminvalue.slice(filterminvalue.indexOf(":") + 1, filterminvalue.length);
  const maxValuefilter = +filtermaxvalue.slice(filtermaxvalue.indexOf(":") + 1, filtermaxvalue.length);
  const categoryid = +filtercategory.slice(filtercategory.indexOf(":") + 1, filtercategory.length);
  const subcategoryid = +filtersubcategory.slice(filtersubcategory.indexOf(":") + 1, filtersubcategory.length);
  const sortdata = filtersort.slice(filtersort.indexOf(":") + 1, filtersort.length);

  const handleInput = async (e) => {
   set_minValue(e.minValue);
   set_maxValue(e.maxValue);
  };


  const onClick = () => {
    setTogglefilter("hidefillter");
    navigate(`/products/page:${1}/filtersort:${sortdata}/filtercategory:${categoryid}/filtersubcategory:${subcategoryid}/minValue:${minValue}/maxValue:${maxValue}`);
    setLoading(false)
  }

  return (
    <div className='price'>

      <h2 className='fillter_title'>{language === "ar" ? "السعر" : "Price"}</h2>
      <div>
        <MultiRangeSlider min={parseInt(Price.min_price)} max={parseInt(Price.max_price) +200}
          step={5} ruler={false} label={false} preventWheel={false}
          minValue={minValuefilter === 0 ? minValue :minValuefilter} 
          maxValue={maxValuefilter === 0 ? maxValue : maxValuefilter}
          onInput={(e) => {handleInput(e)}} />

        <div className="textinp">
          <span>{maxValue} sr</span>
          <span>{minValue} sr</span>
        </div>
        <div className="buttons-price">

          <button type="button" className="btn btn_search_price " onClick={onClick}>

            {language === "en" ? "Search" : "بحث"}
          </button>
        </div>

      </div>
    </div>
  )
}

export default Price;
