import React from 'react'
import { useLocation } from 'react-router';
import IconSuccessCheckout from '../../../../images/icon/icon_success_green.png';

function NavList() {
    const Underline = "";
    let location = useLocation(),
        textlocation = location.pathname,
        addressloction = textlocation.includes("checkoutaddress"),
        paymentloction = textlocation.includes("checkoutpayment"),
        checkoutdone = textlocation.includes("checkoutdone"),
        paymentvisa = textlocation.includes("paymentvisa");

    return (
        <div className='checkout__navlist'>
            <ul
                className={'bulltes'}>
                <li className='line active'>
                    <span className="left circal_image">
                        <img src={IconSuccessCheckout} alt="IconSuccessCheckout" />
                    </span>

                    <span className="lineprogrise"></span>

                    {addressloction === true ?
                        <span className="circal right">2</span>
                        :
                        <span className="right circal_image">
                            <img src={IconSuccessCheckout} alt="IconSuccessCheckout" />
                        </span>
                    }


                </li>

                <li className={checkoutdone === true ? 'line active' :
                    paymentvisa === true ? 'line active' : 'line'}>

                    <span className="lineprogrise"></span>

                    <span className="circal right end">3</span>

                </li>
            </ul>
        </div>
    )
}

export default NavList;


{/*

 <div className='checkout__navlist'>
            <ul
                className={'bulltes'}>
                <li className='line active'>
                    <span className="left circal_image">
                        <img src={IconSuccessCheckout} alt="IconSuccessCheckout" />
                    </span>

                    <span className="lineprogrise"></span>

                    {addressloction === true ?
                        <span className="circal right">2</span>
                        :
                        <span className="right circal_image">
                            <img src={IconSuccessCheckout} alt="IconSuccessCheckout" />
                        </span>
                    }


                </li>

                <li className={addressloction === true ?
                    'line' :
                    'line active'}>

                    <span className="lineprogrise"></span>

                    {addressloction === true ?
                        <span className="circal right">3</span>
                        :paymentloction === true?
                        <span className="circal right">3</span>
                        :
                        <span className="right circal_image">
                            <img src={IconSuccessCheckout} alt="IconSuccessCheckout" />
                        </span>
                    }


                </li>
                <li className={paymentvisa === true ? 'line active' : 'line'}>

                    <span className="lineprogrise"></span>

                    <span className="circal right end">4</span>

                </li>
            </ul>
        </div>
*/}