import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import ProfileContant from '../../components/buyer/profile/contant/index.jsx';
import Loading from '../loading/loading.jsx';
import { GetDataProfile } from '../../api/actions.js';
import ProfileListSeller from './list/seller/index.jsx';
import ProfileListbuyer from './list/buyer/index.jsx';
import { TokenLate, UserTypelate } from '../../api/index.js';
import NavListProfile from '../../components/buyer/profile/navlist/index.jsx';

function Profile() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [stutes, setStutes] = useState(1);

    useEffect(() => {
        if(TokenLate === null  ){
          window.location.pathname = `/`
        }
      }, []);


    useEffect(() => {
        GetDataProfile(setData, setLoading)
    }, [loading]);

    return (

        <>
            {loading === false ?
                <Loading />
                :
                <section className='profile'>
                    <div className="container">
                        <div className="profile__data">

                            {UserTypelate === 3 && TokenLate !== null ?
                                <>
                                    <ProfileListSeller Id={data.id} />
                                    <div className='profile__data__rowcoumn'>
                                        <NavListProfile stutes={stutes} setStutes={setStutes} />
                                        <ProfileContant Data={data} />
                                    </div>
                                </>
                                :
                                <>
                                    <ProfileListbuyer Id={data.id} />
                                    <ProfileContant Data={data} />
                                </>
                            }
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default Profile;