import React, { useContext } from 'react'
import { Authcontext } from '../../../../store/context';

function MyOrdersRowListTitle(props) {
    const{Day,OrderNumber}=props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
  <div className="title">
  <div className="right">
      <span className="top">
          <span className="title">
          {language === "ar" ? " رقم الطلبية" : "Order Number"}
          </span>
          <span className="data">{OrderNumber}</span>
      </span>
      <span className="bottom">
          <span className="title">
          {language === "ar" ? "تم الطلب يوم" : "Requested A Day"}
          </span>
          <span className="data">{Day}</span>
      </span>
  </div>

{/*
  <div className="left">
      <button className="btn btn-back">
          <img src={IconBack} alt="IconBack" />
      </button>
  </div>
    */}
</div>
  )
}

export default MyOrdersRowListTitle;