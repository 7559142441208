import React, { useContext } from 'react';
import ImageProfile from "../../../../../images/icon/icon_profile-shape.png";
import IconStore from "../../../../../images/icon/icon_group_store.png";
import IconStorewhite from "../../../../../images/icon/icon_group_storewhite.png";
import Icondec from "../../../../../images/icon/icon_group_dec.png";
import Icondecwhite from "../../../../../images/icon/icon_group_decwhite.png";
import Iconbanck from "../../../../../images/icon/icon_group_banck.png";
import Iconbanckwhite from "../../../../../images/icon/icon_group_banckwhite.png";
import Iconshape from "../../../../../images/icon/icon_discount-shape.png";
import Iconshapewhite from "../../../../../images/icon/icon_discount-shapewhite.png";
import { Authcontext } from '../../../../../store/context';



function NavListSingup(props) {
    const { stutesform } = props;
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
    return (
        <ul className='navlistsingup'>
            <li className={stutesform > 1 ? "activethree active" : 'active'}>
                <span className='btn-add'>
                    <img src={ImageProfile} alt="ImageProfile" />
                    <span className="text">{language === "ar" ? "البيانات الشخصية" : "Personal data"}</span>
                </span>
            </li>

            <li className={stutesform === 2 ? "activetwo active" :
                stutesform > 2 ? "activetfour active" : ""}>

                <span className='btn-add'>
                    <img 
                    src={stutesform >= 2 ? IconStorewhite : IconStore } 
                    alt="ImageProfile" />
                    <span className="text">{language === "ar" ? "بيانات المتجر" : "Store data"}</span>
                </span>

            </li>

            <li className={stutesform === 3 ? "activetwo active" :
                stutesform > 3 ? "activetfour active" : ""}>

                <span className='btn-add'>
                    <img 
                    src={stutesform >= 3 ? Icondecwhite : Icondec } 
                    alt="ImageProfile" />
                    <span className="text">{language === "ar" ? "المستندات" : "Documents"}</span>
                </span>
            </li>
            <li className={stutesform === 4 ? "activetwo active" :
                stutesform > 4 ? "activetfour active" : ""}>

                <span className='btn-add'>
                    <img 
                    src={stutesform >= 4 ? Iconbanckwhite : Iconbanck } 
                     alt="ImageProfile" />
                    <span className="text">{language === "ar" ? "البنك" : "The Bank"}</span>
                </span>
            </li>

            <li className={stutesform === 5 ? "activetwo active" : ""}>

                <span className='btn-add'>
                    <img 
                    src={stutesform === 5 ? Iconshapewhite : Iconshape } 
                    alt="ImageProfile" />
                    <span className="text">{language === "en" ? "Tax Registration" : "التسجيل الضريبي"}</span>
                </span>
            </li>
        </ul>
    )
}

export default NavListSingup;