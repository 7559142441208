import React, { useContext, useState } from 'react'
import { Authcontext } from '../../../../../store/context';
import { InputFile } from './input.jsx';


function Form_Three(props) {
    const { setFileone ,fileone,setFiletwo ,fileotwo,setStutesform } = props;
    const [validation, setvalidation] = useState("hide");
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const handleNextform = (e) => {
        e.preventDefault();
        if (fileone === null ||
            fileotwo === null ) {
            setvalidation(false);
        } else {
            setvalidation(true);
            setStutesform(4)
        }
    }


    const handleBackform = (e) => {
        e.preventDefault();
        setStutesform(2)
    }

    return (
        <div className='sellersingup__form'>
            <div className="title">
                <h6>{language === "en" ? "Document verification" : "التحقق من المستندات"}</h6>
            </div>
            <InputFile language={language} setFile={setFileone} file={fileone} validation={validation}
            Title={language === "ar" ? "تحميل الرخصة التجارية" : "Download the trade license"} Id={1}/>


            <InputFile language={language} setFile={setFiletwo} file={fileotwo} validation={validation}
            Title={language === "ar" ? "تحميل بطاقة الهوية" : "Download the identity card"} Id={2}/>



            <div className="form-end">

                <button type='button' className='btn btn-next' onClick={handleNextform}>
                    {language === "en" ? "Next" : "التالي"}
                </button>

                <button type='button' className='btn btn-back' onClick={handleBackform}>
                    {language === "en" ? "Back" : "الرجوع"}
                </button>
            </div>
        </div>
    )
}

export default Form_Three;