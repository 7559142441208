import React, { useContext } from 'react';
import ImageEmpty from '../../../../images/icon/icon_favorite__empty.png';
import { Authcontext } from '../../../../store/context';

function FavoriteEmpty() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div className='favorite__empty'>
        <img src={ImageEmpty} alt="ImageEmpty" />
        <p>
          {language === "ar" ? "لم تقم بإضافة شيء الى المفضله حتى الان" : "You haven't added anything to favorites yet"}
        </p>
    </div>
  )
}

export default FavoriteEmpty;