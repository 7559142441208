import React, { useContext, useState } from 'react'
import IconArrowDown from '../../../images/icon/icon_arrow-down.png';
import MyOrderCard from '../myorders/card';
import { useParams } from 'react-router';
import { Authcontext } from '../../../store/context';
import { Setpayment, SetpaymentInstallment } from '../../../api/actions';
import { UserIdlate } from '../../../api';

function OrderData(props) {
    const { Price
        , Priceshipping,
        data
        , Totleprice
        , OrderItems } = props;
    const { id } = useParams();
    console.log(data);

    const authcontext = useContext(Authcontext);
    const language = authcontext.language;

    const [loadingbutton, setLoadingbutton] = useState(false);
    const [loadingbuttonInstallment, setLoadingbuttonInstallment] = useState(false);


    const SenDataPayment = () => {
        setLoadingbutton(true)
        Setpayment(Totleprice, id, UserIdlate, setLoadingbutton);
    }

    const SenDataPaymentInstallment = () => {
        setLoadingbuttonInstallment(true)
        SetpaymentInstallment(Totleprice, id, UserIdlate, setLoadingbuttonInstallment);
    }


    const AllorderItemProduct = []


    for (let index = 0; index < OrderItems.length; index++) {
        AllorderItemProduct.push(...OrderItems[index].orderItemProducts)
    }

    return (
        <div className='orderdata'>
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">

                    <div className="orderdata__enddata collapsed" id="headingTwo"
                        data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                        aria-expanded="false" aria-controls="collapseTwo">
                        <span className="left">
                            <span className="top">{language === "ar" ? "المنتجات" : "Products"}</span>
                            <span className="bottom">
                                {language === "ar" ? `(${AllorderItemProduct.length} منتجات )` : `(${AllorderItemProduct.length} products)`}
                            </span>
                        </span>
                        <span className="right">
                            <img src={IconArrowDown} alt="IconArrowDown" />
                            <span className="bottom">{Totleprice}</span>
                        </span>

                    </div>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            {AllorderItemProduct.map(item =>
                                <MyOrderCard
                                    Title={item.product.title}
                                    Color={item.color.name}
                                    Imageproudect={item.product.media.length === 0 ?
                                        "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg"
                                        : item.product.media[0].image}
                                    Size={item.size.name} Quantity={item.quantity}
                                    Price={`SR ${item.price}`} Item={item} key={item.id} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="orderdata__topdata">
                <h6 className="title">{language === "ar" ? "ملخص الطلبية" : "Order Summary"}</h6>

                <ul>
                    <li className='list'>
                        <span className="left">
                            {language === "ar" ? "المجموع الفرعي" : "Subtotal"}
                            <span>{language === "ar" ? `(${AllorderItemProduct.length} منتجات )` : `(${AllorderItemProduct.length}products)`}</span>
                        </span>
                        <span className="right">
                            {`SR ${Price}`}
                        </span>
                    </li>
                    <li className='list'>
                        <span className="left">
                            {language === "ar" ? "قيمة الخصم" : "Discount Value"}
                        </span>
                        <span className="right">
                            {`SR ${data.discount}`}
                        </span>
                    </li>

                    <li className='list'>
                        <span className="left">
                            {language === "ar" ? "الشحن" : "Shipping"}
                        </span>
                        <span className="right">
                            {`SR ${Priceshipping}`}
                        </span>
                    </li>

                    <li className='line'></li>

                    <li className="totle">
                        <span className="left">
                            {language === "ar" ? "المجموع " : "Total"}
                            <span className='outer'>
                                {language === "ar" ? `(يشمل ضريبة القيمة المضافة)` : `(includes value-added tax)`}
                            </span>
                        </span>
                        <span className="right">
                            {`SR ${Totleprice}`}
                        </span>

                    </li>
                </ul>

            </div>
            <span className="orderdata-detilesorder">
                {language === "ar" ? "طرق الدفع" : "Payment Methods"}
            </span>
            <div className="orderdata__end">
                {loadingbuttonInstallment === false ?
                    <button type='button' className='btn btn-send' onClick={SenDataPaymentInstallment}>
                        <span>{language === "ar" ? "تقسيط" : "Installment"}</span>
                    </button>
                    :
                    <button type='button' className='btn btn-send button-disabled' >
                        {language === "en" ? "Loading" : "جار التحميل"}
                        <span className="spinner"></span>
                    </button>}



                {loadingbutton === false ?
                    <button type='button' className='btn btn-send' onClick={SenDataPayment}>
                        <span>{language === "ar" ? "كاش" : "Cash"}</span>
                    </button>
                    :
                    <button type='button' className='btn btn-send button-disabled' >
                        {language === "en" ? "Loading" : "جار التحميل"}
                        <span className="spinner"></span>
                    </button>}


            </div>
        </div>
    )
}

export default OrderData;