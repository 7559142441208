import React, { useContext } from 'react';
import ImageEmpty from '../../../../images/icon/icon_shoppingcart-empty.png';
import { Authcontext } from '../../../../store/context';

function ProudectsEmpty() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div className='proudects__empty'>
        <img src={ImageEmpty} alt="ImageEmpty" />
        <p>
          {language === "ar" ? "لا توجد منتجات": 
          "There are no products"}
        </p>
    </div>
  )
}

export default ProudectsEmpty;