import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import CustomerReview from '../../../components/buyer/proudect/review/index';
import CardProudect from '../../../components/shared/cardproudect';
import Titlepage from '../../../components/shared/title';
import Details from '../../../components/buyer/storedetails/details.jsx';
import StoreScreen from '../../../components/buyer/storedetails/storescreen';
import Loading from '../../../layout/loading/loading';
import { Authcontext } from '../../../store/context';
import { GetStoreDetails } from '../../../api/actions';
import { TokenLate, UserIdlate } from '../../../api';

function StoreDetails() {
    const { id } = useParams();
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;


    useEffect(() => {
        if(UserIdlate === 3 && TokenLate !== null  ){
          window.location.pathname = `/`
        }
      }, []);
  

    const [comments, setComments] = useState([]);
    const [name, setName] = useState("");
    const [phone, setphone] = useState("");
    const [email, setemail] = useState("");
    const [city, setcity] = useState("");
    const [rate, setrate] = useState("");
    const [clientscount, setClientscount] = useState("");
    

    const [products, setproducts] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        GetStoreDetails(id, setName, setphone, setemail, setcity, setrate, setComments, setproducts,setClientscount, setLoading);
    }, [loading, id]);
    return (
        <>
            {loading === false ?
                <Loading />
                :
                <section className='storedetails'>
                    <StoreScreen />

                    <div className="container">
                        <Details Name={name} Phone={phone} Email={email}
                            City={city} rate={rate} clientscount={clientscount}
                            />
                        <br />
                        <CustomerReview Data={comments} Proudectpage={false}
                            Title={language === "ar" ? "التقييمات" : "Ratings"} />
                        <br />
                        <Titlepage Title={language === "ar" ? "منتجات المتجر" : "Store products"} />
                        <br />
                        <div className='proudect__similar'>
                            <div className="row">
                                {products.map(item =>
                                    <div className="col-6 col-lg-3" key={item.id}>
                                        <CardProudect

                                        Image={item.media.length === 0 ?
                                        "https://www.aaronfaber.com/wp-content/uploads/2017/03/product-placeholder-wp.jpg"
                                        : item.media[0].image}

                                        
                                        Price={`SR ${item.price}`}
                                            Data={item.title} Favoritecard={false} Id={item.id} Vailable={item.in_stock} Item={item} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default StoreDetails;