import { CChart } from '@coreui/react-chartjs'
import React from 'react'
import ChartData from './chart';

function HomeChart(props) {
  const { Datachart, Labels, Year, Titleone, Titletwo } = props;
  const Newarray = [];

  for (let index = 0; index < Datachart.length; index++) {
    Newarray.push(Datachart[index])
  }
  return (
    <div className='homeseller__chart'>
      <div className="top">
        <span className="year">{Year}</span>
        <span className="right">
          <span className="topdata">{Titleone}</span>
          <span className="bottomdata">{Titletwo}</span>

        </span>
      </div>
      <div className="bottom">
      <ChartData Labels={Labels} Newarray={Datachart} />
      </div>
    </div>
  )
}

export default HomeChart