import React, { useRef, useState } from 'react'

function CoverProudect(props) {
    const { ItemImage, Index } = props;
    const imgDiv=useRef();
    const handleMouseMove=e=>{
        const {left,top,width,height}=e.target.getBoundingClientRect();
        const x = (e.pageX - left) / width * 100;
        const y = (e.pageY - top) / height * 100;
        imgDiv.current.style.backgroundPosition=`${x}% ${y}%`;
        imgDiv.current.style.backgroundSize=`auto`;
    }
    const handleMouseLeave=()=>{
        imgDiv.current.style.backgroundSize=`cover`;
        imgDiv.current.style.backgroundPosition=`center`;
    }

    return (
        <div className='coverproudect' style={{
            backgroundImage: `url("${ItemImage[Index].image}")`
        }}
        onMouseMove={handleMouseMove} ref={imgDiv} 
        onMouseLeave={()=>handleMouseLeave()}>
        </div>
    )
}

export default CoverProudect;