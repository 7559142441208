import React, { useContext } from 'react';
import ImageFail from'../../../images/icon/emptysize.png';
import { NavLink } from 'react-router-dom';
import { Authcontext } from '../../../store/context';

function ModalFail() {
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;
  return (
    <div
      className="modal fade modal-success show modal-success-error"
      id="modalsuccess"
      aria-hidden="true"
      aria-labelledby="exampleModalToggleLabel2"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
            <div className="image">
            <img src={ImageFail} alt="" />
            </div>
            <p>
            {language === "ar" ? "تم رفض طريقة الدفع " : "Payment method declined"}
            </p>

            <NavLink to={`/cart`}  className='btn btn-go-home'>
            {language === "ar" ? "حاول مرة أخرى":"Try Again"}
            </NavLink>
        </div>
      </div>
    </div>
  )
}

export default ModalFail;