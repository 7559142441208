import React, { useContext } from 'react';
import IconSort from '../../../images/icon/icon_sort.png'
import { Authcontext } from '../../../store/context';
import { useNavigate, useParams } from 'react-router';

function SortIcon(props) {
  const { setLoading } = props;
  let navigate = useNavigate();

  const {filtersort ,filtercategory,filtersubcategory,filterminvalue , filtermaxvalue} = useParams();

  const minValue = +filterminvalue.slice(filterminvalue.indexOf(":") + 1, filterminvalue.length);
  const maxValue =  +filtermaxvalue.slice(filtermaxvalue.indexOf(":") + 1, filtermaxvalue.length);
  const categoryid = filtercategory.slice(filtercategory.indexOf(":") + 1, filtercategory.length);
  const subcategoryid = filtersubcategory.slice(filtersubcategory.indexOf(":") + 1, filtersubcategory.length);
  const sortdata = filtersort.slice(filtersort.indexOf(":") + 1, filtersort.length);

  
  const authcontext = useContext(Authcontext);
  const language = authcontext.language;

  const Handelsubmit = (data) => {
    navigate(`/products/page:${1}/filtersort:${data}/filtercategory:${categoryid}/filtersubcategory:${subcategoryid}/minValue:${minValue}/maxValue:${maxValue}`);
    setLoading(false)
  }
  return (
    <div className='sort'>
      <a className="btn btn-sort" href="#" role="button"
        id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
        <span>{language === "ar" ? "ترتيب" : "Ranking"}</span>
        <img src={IconSort} alt="IconSort" />
      </a>

      <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <li className={sortdata === "lowest-price" ?"dropdown-item active":"dropdown-item" }
        onClick={() => Handelsubmit("lowest-price")}>
          {language === "en" ? "From lowest to highest price" : "من الأقل الى الأعلى سعرا"}
        </li>
        <li className={sortdata === "highest-price" ?"dropdown-item active":"dropdown-item" } 
        onClick={() => Handelsubmit("highest-price")}>
          {language === "en" ? "From highest to lowest price" : "من الأعلى الى الأقل سعرا"}
        </li>
      </ul>
    </div>
  )
}

export default SortIcon;