import React, { useContext } from 'react';
import ImageEmpty from '../../../../images/icon/icon_notification_empty.png';
import { Authcontext } from '../../../../store/context';

function RatingsEmpty() {
    const authcontext = useContext(Authcontext);
    const language = authcontext.language;
  return (
    <div className='ratingscontent__empty'>
        <img src={ImageEmpty} alt="ImageEmpty" />
        <p>
          {language === "ar" ? "لا يوجد تقييمات لك حتى الان" : "There are no ratings for you yet"}
        </p>
    </div>
  )
}

export default RatingsEmpty;